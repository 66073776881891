import { message } from 'antd';
import * as subSegmentRepo from '../Repository/SubSegmentRepository';
import * as actionCreators from './ActionCreators/SubsegmentActionCreator';
import { setApiStatus } from './ApiStatusActions';
import { setNotification } from './ActionCreators/ConnectActions';

export function fetchSubSegments(segmentId, searchTerm, page, pageSize, dispatchApiStatus = true) {
  return async dispatch => {
    try {
      if(dispatchApiStatus)dispatch(setApiStatus({ apiName: 'fetchSubSegmentApiStatus', status: 'INPROGRESS' }));
      const response = await subSegmentRepo.fetchSubSegments(segmentId, searchTerm, page, pageSize);
      dispatch(actionCreators.setSubsegmentList(segmentId, response.data));
      if(dispatchApiStatus)dispatch(setApiStatus({ apiName: 'fetchSubSegmentApiStatus', status: 'COMPLETED' }));
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'fetchSubSegmentApiStatus', status: 'FAILED' }));
    }
  };
}

export function _fetchSubSegmentsList(segmentId, searchTerm, page, pageSize) {
  return async () => {
    try {
      const response = await subSegmentRepo.fetchSubSegments(segmentId, searchTerm, page, pageSize);
      return response.data;
    } catch  {
      return [];
    }
  };
}

export function setSubsegmentCreateModalStatus(visible) {
  return dispatch => {
    dispatch(actionCreators.setCreateSubsegmentModalVisble(visible));
  };
}
export function setSubsegmentAddModalStatus(visible, additionalInfo) {
  return dispatch => {
    dispatch(actionCreators.setAddSubsegmentModalVisble(visible, additionalInfo));
  };
}
export function setSubsegmentDeleteModalStatus(visible) {
  return dispatch => {
    dispatch(actionCreators.setDeleteSubsegmentModalVisble(visible));
  };
}
export function setSelectedSubsegment(data) {
  return dispatch => {
    dispatch(actionCreators.setSelectedSubsegmentData(data));
  };
}

export function setSubsegmentPaginationData(pageNumber, size) {
  return dispatch => {
    dispatch(actionCreators.setSubsegmentPaginationData(pageNumber, size));
  };
}

export function createSubSegment(segmentId, data, dispatchActions= false) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'createSubsegmentApiStatus', status: 'INPROGRESS' }));
      const response = await subSegmentRepo.createSubSegment(segmentId, data);
      message.success('Sub-Segment has been created');
      dispatch(setApiStatus({ apiName: 'createSubsegmentApiStatus', status: 'COMPLETED' }));
      if(dispatchActions) dispatch(actionCreators.setCreatedSubSegmentId(response.data.Id));
    } catch (e) {
      if (e.response.status === 409) {
        dispatch(setNotification('ERROR', `Sub-Segment name already exists.`));
      } else message.error('Something went wrong');
      dispatch(setApiStatus({ apiName: 'createSubsegmentApiStatus', status: 'FAILED' }));
      throw e;
    }
  };
}

export function updateSubSegment(segmentId, subsegmentId, payload) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'updateSubsegmentApiStatus', status: 'INPROGRESS' }));
      await subSegmentRepo.updateSubSegment(segmentId, subsegmentId, payload);
      message.success('Sub-Segment has been updated');
      dispatch(setApiStatus({ apiName: 'updateSubsegmentApiStatus', status: 'COMPLETED' }));
    } catch (e) {
      if (e.response.status === 409) {
        dispatch(setNotification('ERROR', `Sub-Segment name already exists.`));
      }
      dispatch(setApiStatus({ apiName: 'updateSubsegmentApiStatus', status: 'FAILED' }));
      throw e;
    }
  };
}

export function setCreatedSubSegmentId(id) {
return actionCreators.setCreatedSubSegmentId(id);
}
