import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { AutoRejectIcon, ShortlistedIcon, CandidateRejectedIcon } from '../../../Icons/AryaIcons';
import AddIconV2 from '../../../Icons/SegmentCandidateAddIconV2';
import DeleteIconV2 from '../../../Icons/SegmentCandidateDeleteIconV2';
import styles from './NewCandidateShortlistReject.module.scss';
import { isCandidateStateChangeAllowed } from '../../../Utils/CandidateListUtils';
import DeleteCandidateModal from '../../DeleteCandidateModal/DeleteCandidateModal';
import eventTypes from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import CandidateTransfer from '../../CandidateTransfer/CandidateTransfer';

function NewCandidateShortlistReject(props) {
  const {
    isPaidJobServiceEnabled,
    activeTab,
    featureToggleList,
    onCandidateStatusChange,
    candidateContext = 'job',
    candidateStatusUpdate,
    candidate,
    size,
    onCandidateReject,
    jobId,
    candidateId,
    isAddVisible = true,
    isDeleteVisible = true,
    className,
    shortlistedIcon,
    rejectedIcon,
    iconStyle = {},
    candidateTransferIconClassName,
    candidateCardTertiaryContent,
    showCandidateQualificationSummary,
    isScoreVisible,
    iconHeight,
    version,
    setAddSubsegmentModalStatus,
    showExpandedCandidateCard = true,
    selectedSubSegmentId,
  } = props;

  const [isCandidateStatusChangeAllowed, setIsCandidateStatusChangeAllowed] = React.useState(false);
  const [isDeleteModalVisible, setDeleteModalVisiblity] = React.useState(false);
  const [isCandidateTransferModalVisible, setCandidateTransferModalVisibility] = React.useState(false);
  const [selctedOption, setSelectedOption] = React.useState(1);

  const isFeedbackRequired = candidateContext === 'job';

  const getCandidateRejectedStatusUpdate = _candidate => {
    return candidateStatusUpdate?.[_candidate.Id]?.Rejected;
  };

  const getCandidateShortlistedStatusUpdate = _candidate => {
    return candidateStatusUpdate?.[_candidate.Id]?.Shortlisted;
  };
  const candidateRejectedStatusUpdate = getCandidateRejectedStatusUpdate(candidate);
  const candidateShortlistedStatusUpdate = getCandidateShortlistedStatusUpdate(candidate);

  const getIsShortListRjectAllowed = () => {
    return _.get(featureToggleList, ['ShortlistReject', 'IsAllowed'], true);
  };

  const changeCandidateShortlistedStatus = e => {
    e.stopPropagation();
    if (isCandidateStateChangeAllowed(candidate)) {
      const isShortListRjectAllowed = getIsShortListRjectAllowed();
      let status = isPaidJobServiceEnabled ? candidate.PublishStatus : candidate.Status;
      if (candidateContext === 'segment') {
        setAddSubsegmentModalStatus(true, { singleCandidateAdd: true, candidateId: candidate.Id });
        return;
      }
      if (candidateContext === 'segment' && status === 'Shortlisted') {
        status = activeTab === 'rejected' ? 'Rejected' : status;
      }
      const modifiedStatus = status === 'Shortlisted' ? 'Sourced' : 'Shortlisted';
      if (!isCandidateStatusChangeAllowed && isShortListRjectAllowed)
        onCandidateStatusChange({
          status: modifiedStatus,
          candidate,
        });
    }
  };

  const handleCandidateRejection = newCandidateStatus => {
    if (isFeedbackRequired) onCandidateReject(candidate, size);
    else
      onCandidateStatusChange(
        {
          status: newCandidateStatus,
          candidate,
          selctedOption,
          selectedSubsegmentId: selectedSubSegmentId,
        },
        size
      );
  };

  const changeCandidateRejectedStatus = e => {
    e.stopPropagation();
    if (isCandidateStateChangeAllowed(candidate)) {
      const isShortListRjectAllowed = getIsShortListRjectAllowed();
      const newCandidateStatus = (() => {
        let currentStatus;
        if (isPaidJobServiceEnabled) {
          currentStatus = candidate.PublishStatus;
        } else {
          currentStatus = candidate.Status;
        }
        return currentStatus === 'Rejected' ? 'Sourced' : 'Rejected';
      })();

      if (!isCandidateStatusChangeAllowed && isShortListRjectAllowed) {
        if (candidateContext === 'segment' && candidate.Status?.toLowerCase() === 'shortlisted')
          setDeleteModalVisiblity(true);
        else handleCandidateRejection(newCandidateStatus);
      }
    }
  };

  const onDeleteModalOk = () => {
    setDeleteModalVisiblity(false);
    handleCandidateRejection('Rejected');
  };

  const onDeleteModalcancel = () => {
    setDeleteModalVisiblity(false);
  };

  React.useEffect(() => {
    const shouldAllowStatusChange =
      candidateShortlistedStatusUpdate === 'INPROGRESS' || candidateRejectedStatusUpdate === 'INPROGRESS';
    setIsCandidateStatusChangeAllowed(shouldAllowStatusChange);
  }, [candidateShortlistedStatusUpdate, candidateRejectedStatusUpdate]);

  const getCandidateStatus = () => {
    if (isPaidJobServiceEnabled) {
      return {
        isShortlisted: candidate.PublishStatus === 'Shortlisted',
        isRejected: candidate.PublishStatus === 'Rejected',
      };
    }
    return {
      isShortlisted: candidate.Status === 'Shortlisted',
      isRejected: candidate.Status === 'Rejected',
      isAutoRejected:
        candidate?.Status?.toLowerCase() === 'rejected' && candidate?.RecommendedMode?.toLowerCase() === 'auto',
    };
  };
  const { isShortlisted, isRejected, isAutoRejected } = getCandidateStatus();

  const getRejectIcon = () => {
    if (candidateRejectedStatusUpdate?.toUpperCase() === 'INPROGRESS') return <Icon type="loading" />;
    if (candidateRejectedStatusUpdate?.toUpperCase() !== 'INPROGRESS' && isAutoRejected && candidateContext === 'job')
      return <AutoRejectIcon style={{ fontSize: '20px' }} />;
    if (candidateRejectedStatusUpdate?.toUpperCase() !== 'INPROGRESS' && candidateContext === 'segment')
      return <DeleteIconV2 fillColor="#FFFFFF" iconHeight={iconHeight} />;
    if (isRejected) return rejectedIcon ?? <CandidateRejectedIcon style={iconStyle} />;
    return <Icon type="dislike" style={iconStyle} />;
  };

  const onClickTransferIcon = () => {
    setCandidateTransferModalVisibility(true);
  };

  const status = candidate?.Status;
  const isCandidateTransferVisible =
    candidateContext === 'segment' &&
    status &&
    (status.toLowerCase() === 'sourced' || status.toLowerCase() === 'shortlisted');

  const showTertiaryContent =
    !isScoreVisible && !showCandidateQualificationSummary && showExpandedCandidateCard && candidateCardTertiaryContent;

  const segmentAddIcon =
    candidateShortlistedStatusUpdate === 'INPROGRESS' ? (
      <Icon type="loading" style={iconStyle} />
    ) : (
      <AddIconV2 fillColor="#83878C" iconHeight={iconHeight} style={iconStyle} />
    );

  const addIcon =
    candidateContext === 'job' ? (
      <Icon type={candidateShortlistedStatusUpdate === 'INPROGRESS' ? 'loading' : 'like'} style={iconStyle} />
    ) : (
      segmentAddIcon
    );

  const shortlistedIconV2 = candidateContext === 'job' ? <ShortlistedIcon /> : <AddIconV2 />;
  const shortlistRejectContent = (
    <div
      className={classNames(styles.candidateShortlistContainer, className)}
      onClick={e => e.stopPropagation()}
      role="presentation"
    >
      {isAddVisible ? (
        <div
          role="button"
          tabIndex={0}
          onClick={changeCandidateShortlistedStatus}
          onKeyPress={changeCandidateShortlistedStatus}
          className={styles.shorlistIconDiv}
          sk-event-name={
            isShortlisted
              ? getEventNameByCandidateStatus(eventTypes.candidate.candidateShortlist.unshortlistCandidate, candidate)
              : getEventNameByCandidateStatus(eventTypes.candidate.candidateShortlist.shortlistCandidate, candidate)
          }
        >
          {isShortlisted ? shortlistedIcon ?? shortlistedIconV2 : addIcon}
        </div>
      ) : null}
      {isDeleteVisible ? (
        <div
          role="button"
          tabIndex={0}
          onKeyPress={changeCandidateRejectedStatus}
          onClick={changeCandidateRejectedStatus}
          sk-event-name={
            isRejected
              ? getEventNameByCandidateStatus(eventTypes.candidate.candidateReject.unrejectCandidate, candidate)
              : getEventNameByCandidateStatus(eventTypes.candidate.candidateReject.rejectCandidate, candidate)
          }
          className={styles.rejectIconDiv}
        >
          {getRejectIcon()}
        </div>
      ) : null}
      {isCandidateTransferVisible ? (
        <div className={styles.candidateTransferIconDiv}>
          <CandidateTransfer
            isCandidateTransferModalVisible={isCandidateTransferModalVisible}
            jobId={jobId}
            setCandidateTransferModalVisibility={setCandidateTransferModalVisibility}
            onClickTransferIcon={onClickTransferIcon}
            candidateIds={[candidateId]}
            invalidCandidateCallbackMessage="Please select a candidate"
            transferType="single"
            candidateTransferIconClassName={candidateTransferIconClassName}
            version={version}
            candidateContext={candidateContext}
            selectedSubSegmentId={selectedSubSegmentId}
          />
        </div>
      ) : null}
      {isDeleteModalVisible ? (
        <DeleteCandidateModal
          isVisible={isDeleteModalVisible}
          onCancel={onDeleteModalcancel}
          onOk={onDeleteModalOk}
          activeTab={activeTab}
          selctedOption={selctedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : null}
    </div>
  );
  return !showTertiaryContent ? (
    shortlistRejectContent
  ) : (
    <div className={styles.shortlistContainerWithActionWidgets}>
      {shortlistRejectContent}
      {showTertiaryContent ? candidateCardTertiaryContent : null}
    </div>
  );
}

export default NewCandidateShortlistReject;
