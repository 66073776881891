import React, { useEffect } from 'react';
import { Button } from 'antd';
import { isPulseUser } from '../../../Utils/ConfigUtils';

function ComposeEmailAndMessageButton(props) {
  const {
    setComposeModalVisibility,
    activeTab,
    isDisabled,
    composeEmailType,
    setDefaultEmailTemplateValues,
    setCurrentReplyingToEmail,
    shouldChangeMailValues,
    setSubjectDisability,
    skEventName,
  } = props;

  let composeButtonName = '';
  if (activeTab === 'email') composeButtonName = 'Compose Email';
  else if (activeTab === 'workflow') composeButtonName = 'Initiate Workflow';
  else composeButtonName = 'Compose Text';

  const isPulse = isPulseUser();

  useEffect(() => {
    if (composeEmailType) {
      setComposeModalVisibility(true);
    }
  }, [composeEmailType, setComposeModalVisibility]);

  const onClickComposeButton = async () => {
    setComposeModalVisibility(true);
    if (shouldChangeMailValues && activeTab === 'email') {
      await setSubjectDisability(false);
      setCurrentReplyingToEmail([]);
      setDefaultEmailTemplateValues();
    }
  };
  return activeTab === 'message' && isPulse ? null : (
    <div>
      <Button
        type="primary"
        shape="round"
        disabled={isDisabled}
        onClick={onClickComposeButton}
        sk-event-name={skEventName}
      >
        {composeButtonName}
      </Button>
    </div>
  );
}

export default ComposeEmailAndMessageButton;
