import React from 'react';
import { Icon } from 'antd';
import classNames from 'classnames';
import MultiLocationContext from '../MultiLocationContext';
import styles from './OutputLocations.module.scss';
import { MultiLocationEditIcon } from '../../../Icons/AryaIcons';
import { EllipsedText } from '../../../Utils/TextUtils';
import { getRegionTooltip } from '../../../Utils/RegionUtils/RegionUtils';

const OutputLocations = props => {
  const { isRemoteJobChecked, isAdvanceSearch, form, displayPrimaryLocation } = props;
  const { locations, onEditMultiLocation, deleteMultiLocation, isFirstLocation, getPrimaryLocationId, regions } =
    React.useContext(MultiLocationContext);

  const navigateLocationContext = Id => {
    const editLocation = locations.filter(location => location.Id === Id);
    if (editLocation.every(x => x.Region !== null && x.Region !== undefined))
      form.setFieldsValue({
        LocationContext: 'Region',
      });
    else {
      form.setFieldsValue({
        LocationContext: 'Location',
      });
    }
  };

  const onEdit = Id => {
    navigateLocationContext(Id);
    onEditMultiLocation(Id);
  };
  const onDelete = Id => {
    deleteMultiLocation(Id);
  };

  const updatedLocations = locations.slice(0, -1) ?? [];
  const values = updatedLocations.filter(
    location => location?.Location || location?.Region || location?.State || location?.isVisible || location?.isActive
  );

  return values.map(value => {
    const displayedLocation = value?.Location || value?.Region?.RegionName || value?.State;
    const disable = isRemoteJobChecked;
    const isPrimaryLocation = value?.IsPrimaryLocation;
    const disableCloseIcon =
      (isFirstLocation && !isAdvanceSearch) || (getPrimaryLocationId(value) && !isAdvanceSearch) || disable;

    return (
      <>
        {' '}
        <div className={classNames(styles.locationBox, { [styles.disableLocationBox]: disable })}>
          <div className={styles.locationContent}>
            <div className={styles.primaryLocation}>
              {displayPrimaryLocation && isPrimaryLocation ? 'Primary Location : ' : ''}
            </div>
            {value.Label ? (
              <EllipsedText text={value.Label} maxTextLength={30} />
            ) : (
              <span>
                {value?.CountryCode}
                {displayedLocation ? ',' : null}
                {value?.Region?.RegionName ? (
                  getRegionTooltip({ regions, regionId: value.Region.RegionId })
                ) : (
                  <EllipsedText
                    text={`
                  ${displayedLocation ? `${displayedLocation}` : ''} `}
                    maxTextLength={30}
                  />
                )}
              </span>
            )}
            {value?.Distance ? `, ${value.Distance} mil` : ''}
          </div>{' '}
          <div
            className={classNames(styles.editIcon, { [styles.disableEditIcon]: disable })}
            onClick={() => {
              if (!disable) onEdit(value.Id);
            }}
          >
            <MultiLocationEditIcon />
          </div>
          <div
            className={classNames(
              styles.closeIcon,
              { [styles.disableCloseSymbol]: getPrimaryLocationId(value) && !isAdvanceSearch },
              { [styles.disableCloseIcon]: disableCloseIcon }
            )}
            onClick={() => {
              if (!disableCloseIcon) onDelete(value.Id);
            }}
          >
            <Icon
              type="close"
              theme="outlined"
              className={classNames(styles.closeIcon, {
                [styles.disableCloseSymbol]: getPrimaryLocationId(value) && !isAdvanceSearch,
              })}
            />
          </div>
        </div>
      </>
    );
  });
};

export default OutputLocations;
