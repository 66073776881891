import React from 'react';
import { passwordValidator as validatePasswordErrorMessage, emailValidator } from './Validators';
import isBooleanString from './BooleanStringUtility';

function validateZipCode(
  searchZipCode,
  clearZipValidationError,
  zipCodeOptions,
  countryCode,
  zipCode,
  isZipCodeInvalid
) {
  if (isZipCodeInvalid && (!zipCode || zipCodeOptions.find(zipcodeOption => zipcodeOption === zipCode)))
    clearZipValidationError();

  if (zipCode && zipCode.trim().length && !zipCodeOptions.find(zipcodeOption => zipcodeOption === zipCode))
    searchZipCode({
      postalCode: zipCode,
      countryCode,
    });
}

function validateEmptyField(value, callback, errorMessage) {
  const fieldValue = value && value.trim();
  if (fieldValue) {
    callback();
  } else {
    callback(errorMessage);
  }
}

function validateStreetAddress(rule, value, callback) {
  if (value && value.length > 60) {
    callback('Address should not exceed 60 characters');
  }
  if (!value || !value.trim().length) {
    callback('Please enter street address');
  }
  callback();
}

function validatePhone(rule, value, callback) {
  const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const phone = value;
  if (!phone || !phone.trim().length || regex.test(phone)) {
    callback();
    // Valid international phone number
  } else {
    callback('Invalid number. Please start with country code.');
  }
}

function validateEmail(rule, value, callback) {
  if (!value || !value.trim().length || emailValidator(value)) {
    callback();
  } else {
    callback('Please input valid Email');
  }
}

function validateMinExperience(form, value, callback, isMinExpRequired) {
  const maxExp = form.getFieldValue('MaxExperience');
  if (value && !Number.isInteger(value)) callback("Exp can't be in decimals");
  if (maxExp !== undefined && maxExp !== '' && maxExp !== null) {
    if (isMinExpRequired && (value === undefined || value === '' || value === null)) {
      callback('Min Exp. is required');
    } else if (value > 50) {
      callback("Min Exp. can't be more than 50 years");
    } else if (value > maxExp) {
      callback("Min Exp. can't be greater than Max Exp.");
    } else {
      callback();
    }
  } else if (value > 50) {
    callback("Min Exp. can't be more than 50 years");
  } else {
    callback();
  }
  form.validateFields(['MaxExperience']);
}

function validateMaxExperience(form, value, callback, isMaxExpRequired) {
  const minExp = form.getFieldValue('MinExperience');
  if (value && !Number.isInteger(value)) callback("Exp can't be in decimals");
  if (minExp !== undefined && minExp !== '' && value !== undefined && value !== '' && value !== null) {
    if (value > 50) {
      callback("Max Exp. can't be more than 50 years");
    } else if (value < minExp) {
      callback("Max Exp. can't be less than Min Exp.");
    } else {
      callback();
    }
  } else if (
    minExp !== undefined &&
    minExp !== '' &&
    minExp !== null &&
    (value === '' || value === null || value === undefined) &&
    isMaxExpRequired
  ) {
    callback('Max Exp. is required.');
  } else if (value !== undefined && value > 50) {
    callback("Max Exp. can't be more than 50 years");
  } else {
    callback();
  }
  form.validateFields(['MinExperience']);
}

function validatePassword(rule, value, callback) {
  if (!value) {
    callback('Password cannot be blank.');
  }
  const callbackMessage = validatePasswordErrorMessage(value);
  if (callbackMessage !== '') {
    callback(callbackMessage);
  } else callback();
}

const validateBooleanString = (rule, booleanString, callback, form) => {
  const modifiedBooleanString = booleanString
    ? booleanString
        .replace(
          /[\u0022|\u02BA|\u02DD|\u02EE|\u02F6|\u05F2|\u05F4|\u1CD3|\u201C|\u201D|\u201F|\u2033|\u2036|\u3003|\uFF02]/g,
          '\u0022'
        )
        .replace(/\n+/g, ' ')
        .replace(/''/g, '"') // replacing two single quotes with one double quote
    : '';
  if (!booleanString) callback();
  else if (isBooleanString(modifiedBooleanString)) {
    form.setFieldsValue({
      [rule.field]: modifiedBooleanString,
    });
    callback();
  } else {
    callback(['Please give input in this format:', <br />, '("Keyword1 Keyword2" OR Keyword3) AND (Keyword4)']);
  }
};

const validateDistance = (rule, value, callback, isDisabled, isEmptyDistanceAllowed, shouldHideMilesValidation) => {
  if (isDisabled) {
    callback();
    return true;
  }
  if (!value && !shouldHideMilesValidation) {
    callback('Please input Miles');
    return false;
  }
  if (value && parseInt(value, 10) !== value) {
    callback(
      'This entry can only contain numbers without decimals. And must not include alphabets and special characters.'
    );
    return false;
  }
  if (parseInt(value, 10) > 999) {
    callback('Mile cannot be greater than 999, please opt for remote job');
    return false;
  }
  if ((parseInt(value, 10) >= 5 && parseInt(value, 10) <= 999) || (isEmptyDistanceAllowed && value !== 0 && !value)) {
    callback();
    return true;
  }
  if (shouldHideMilesValidation) {
    callback();
    return true;
  }
  callback('Mile must be greater than 5 !');
  return false;
};

export const validatePhoneIgnoringHyphensAndBraces = (rule, value, callback) => {
  const regex = /^\+(?:[0-9-()[\]\s] ?){6,14}[0-9)\]]$/;
  const phone = value;
  const isValidPhone = !phone?.trim().length || regex.test(phone.trim());
  if (isValidPhone) {
    callback();
  } else {
    callback('Invalid number. Please start with country code.');
  }
};

export const validateTagInput = (rule, value, callback, setErrorVisiblity) => {
  const isValidTag = !value?.trim().length || value.trim()?.length <= 50;
  if (isValidTag) {
    callback();
    setErrorVisiblity(false);
  } else {
    setErrorVisiblity(true);
    callback('A Tag cannot be more than 50 characters');
  }
};
function validateCandidateTransfer(
  value,
  callback,
  candidateIds,
  isEnableCustomSelect,
  customSelectNumber,
  invalidCandidateCallbackMessage
) {
  if (!candidateIds?.length && !(isEnableCustomSelect && customSelectNumber)) {
    callback(invalidCandidateCallbackMessage);
  }
  callback();
}

export {
  validateZipCode,
  validateEmptyField,
  validateStreetAddress,
  validatePhone,
  validateEmail,
  validateMinExperience,
  validateMaxExperience,
  validatePassword,
  validateBooleanString,
  validateDistance,
  validateCandidateTransfer,
};
