import cleanSet from 'clean-set';
import { createSelector } from 'reselect';
import {
  SET_JOB_CANDIDATE_TAB_FILTER,
  SET_JOB_CANDIDATE_TAB_PAGINATION,
  SET_JOB_CANDIDATE_TAB_ACTIVE_TAB,
  SET_JOB_CANDIDATE_TAB_ACTIVE_SOURCE_NAME,
  SET_MANUAL_SEARCH_ACTIVE_SOURCE,
  SET_JOB_CANDIDATE_TAB_SELECTED_CANDIDATES,
  SET_JOB_CANDIDATE_TAB_SELECTED_ACTIVE_CANDIDATES_COUNT,
  SET_JOB_CANDIDATE_TAB_SELECTED_BULK_ACTIVITY,
  RESET_JOB_CANDIDATE_TAB_STATE,
  RESET_JOB_CANDIDATE_TAB_BULK_SELECTION,
  SET_JOB_CANDIDATE_TAB_FILTER_QUERIES,
  SET_CANDIDATE_COLLAPSED_FILTERS,
  SET_JOB_CANDIDATE_TAB_SELECTED_CUSTOM_BULK_ACTIVITY,
  SET_JOB_CANDIDATE_TAB_CUSTOM_SELECTED_CANDIDATES,
  SET_CANDIDATES_RECOMMENDATION_STATUS,
  SET_SEGMENT_BULK_RECOMMEND_ERROR_MESSAGE,
  SET_JOB_CANDIDATE_TAB_FROM,
} from '../Actions/JobCandidatesTabActions';
import appConfig from '../Config/Config';

const emptyObject = {};

export const initialFilter = {
  Industries: [],
  ShortlistedBy: [],
  Movers: [],
  Ratings: [],
  Sources: [],
  IsDiverse: null,
  Portals: [],
  EmailStats: [],
  SortBy: 'Ranking', // default value for sortby
  SearchQuery: '',
  Genders: [],
  IsVeteran: null,
  IsMinority: null,
  IsFavorite: null,
  ScoutingAgentIds: [],
};

export const defaultFilter = {
  Sourced: initialFilter,
  Shortlisted: initialFilter,
  Connected: initialFilter,
  Rejected: initialFilter,
  Engaged: initialFilter,
  Applied: initialFilter,
  Favourite: initialFilter,
  Contacted: initialFilter,
  Failed: initialFilter,
};

const defaultState = {
  activeSourceName: 'AryaRecommended',
  searchedSources: [],
  filter: defaultFilter,
  currPage: 1,
  from: 0,
  pageSize: appConfig.defaultSelectedPageSize,
  BulkCandidatesSelection: {
    Recommended: { selectedCandidates: [], selectedActiveCandidateCount: 0 },
    NonRecommended: { selectedCandidates: [], selectedActiveCandidateCount: 0, BulkActivity: {} },
  },
  BulkCustomCandidatesSelection: {
    NonRecommended: { BulkCustomActivity: {} },
  },
  BulkRecommendationStatus: {},
};

function JobCandidatesTabReducer(state = defaultState, action = {}) {
  let newState = null;
  const { type, payload } = action;

  switch (type) {
    case RESET_JOB_CANDIDATE_TAB_STATE:
      return defaultState;

    case SET_JOB_CANDIDATE_TAB_FILTER:
      return {
        ...state,
        filter: payload.filter,
      };

    case SET_JOB_CANDIDATE_TAB_PAGINATION:
      return {
        ...state,
        pageSize: payload.pageSize ?? state.pageSize,
        currPage: payload.currPage ?? state.currPage,
      };

    case SET_JOB_CANDIDATE_TAB_FROM:
      return {
        ...state,
        from: payload ?? state.from,
      };
    case SET_JOB_CANDIDATE_TAB_ACTIVE_TAB:
      return { ...state, activeTab: payload.activeTab };

    case SET_JOB_CANDIDATE_TAB_ACTIVE_SOURCE_NAME:
      return { ...state, activeSourceName: payload.activeSourceName };

    case SET_MANUAL_SEARCH_ACTIVE_SOURCE:
      return { ...state, manualSearchActiveSource: payload.manualSearchActiveSource };

    case SET_JOB_CANDIDATE_TAB_SELECTED_CANDIDATES: {
      const { candidateType, selectedCandidates } = payload;
      newState = cleanSet(state, ['BulkCandidatesSelection', candidateType, 'selectedCandidates'], selectedCandidates);
      return newState;
    }

    case SET_JOB_CANDIDATE_TAB_SELECTED_ACTIVE_CANDIDATES_COUNT: {
      const { candidateType, selectedActiveCandidateCount } = payload;
      newState = cleanSet(
        state,
        ['BulkCandidatesSelection', candidateType, 'selectedActiveCandidateCount'],
        selectedActiveCandidateCount
      );
      return newState;
    }

    case SET_JOB_CANDIDATE_TAB_SELECTED_BULK_ACTIVITY: {
      const { candidateType, bulkActivitySelection } = payload;
      newState = cleanSet(state, ['BulkCandidatesSelection', candidateType, 'BulkActivity'], bulkActivitySelection);
      return newState;
    }

    case SET_JOB_CANDIDATE_TAB_SELECTED_CUSTOM_BULK_ACTIVITY: {
      const { candidateType, bulkCustomActivitySelection } = payload;
      newState = cleanSet(
        state,
        ['BulkCustomCandidatesSelection', candidateType, 'BulkCustomActivity'],
        bulkCustomActivitySelection
      );
      return newState;
    }

    case SET_JOB_CANDIDATE_TAB_FILTER_QUERIES: {
      const { filterQueries, sourceName } = payload;
      newState = cleanSet(state, ['FilterQueriesBySource', sourceName], filterQueries);
      return newState;
    }

    case RESET_JOB_CANDIDATE_TAB_BULK_SELECTION:
      return {
        ...state,
        BulkCandidatesSelection: defaultState.BulkCandidatesSelection,
        BulkCustomCandidatesSelection: defaultState.BulkCustomCandidatesSelection,
      };

    case SET_CANDIDATE_COLLAPSED_FILTERS: {
      const { jobId, filters } = payload;
      newState = cleanSet(state, [jobId, 'CandidateCollapsedFilters'], filters);
      return newState;
    }

    case SET_JOB_CANDIDATE_TAB_CUSTOM_SELECTED_CANDIDATES: {
      const { candidateType, selectedCustomCandidates } = payload;
      newState = cleanSet(
        state,
        ['BulkCandidatesSelection', candidateType, 'selectedCustomCandidates'],
        selectedCustomCandidates
      );
      return newState;
    }

    case SET_CANDIDATES_RECOMMENDATION_STATUS: {
      const { jobId, data } = payload;
      newState = cleanSet(state, ['BulkRecommendationStatus', jobId], data);
      return newState;
    }

    case SET_SEGMENT_BULK_RECOMMEND_ERROR_MESSAGE: {
      const { jobId, errorMessage } = payload;
      newState = cleanSet(state, ['BulkRecommendationStatus', jobId], { errorMessage });
      return newState;
    }

    default:
      return state;
  }
}

function getCandidateFilter(state) {
  return state.JobCandidatesTabReducer.filter;
}

function getCurrentPage(state) {
  return state.JobCandidatesTabReducer.currPage;
}

function getPageSize(state) {
  return state.JobCandidatesTabReducer.pageSize;
}

function getActiveTab(state) {
  return state.JobCandidatesTabReducer.activeTab;
}

function getActiveSourceName(state) {
  return state.JobCandidatesTabReducer.activeSourceName;
}

function getManualSearchActiveSource(state) {
  return state.JobCandidatesTabReducer.manualSearchActiveSource;
}

function getSelectedCandidates(state, candidateType) {
  return state.JobCandidatesTabReducer.BulkCandidatesSelection[candidateType].selectedCandidates;
}

const getSelectedCandidateIds = createSelector(
  [state => state.JobCandidatesTabReducer.BulkCandidatesSelection, (state, candidateType) => candidateType],
  (bulkCandidatesSelection, candidateType) =>
    bulkCandidatesSelection[candidateType].selectedCandidates.map(candidate => candidate.CandidateId)
);

function getSelectedActiveCandidatesCount(state, candidateType) {
  return state.JobCandidatesTabReducer.BulkCandidatesSelection[candidateType].selectedActiveCandidateCount;
}

function getSelectedBulkActivity(state, candidateType) {
  return state.JobCandidatesTabReducer.BulkCandidatesSelection[candidateType].BulkActivity;
}

function getFilterQueries(state, sourceName) {
  return state.JobCandidatesTabReducer.FilterQueriesBySource?.[sourceName];
}

function getAllFilterQueries(state) {
  return state.JobCandidatesTabReducer.FilterQueriesBySource ?? emptyObject;
}
function getJobCandidateTabFrom(state) {
  return state.JobCandidatesTabReducer.from;
}

export const getCandidateCollapsedFilters = (state, jobId) => {
  return state.JobCandidatesTabReducer[jobId]?.CandidateCollapsedFilters || [];
};

function getSelectedCustomBulkActivity(state, candidateType) {
  return state.JobCandidatesTabReducer.BulkCustomCandidatesSelection[candidateType].BulkCustomActivity;
}

function getCandidatesRecommendationStatus(state, { jobId }) {
  return state.JobCandidatesTabReducer.BulkRecommendationStatus[jobId];
}

export {
  JobCandidatesTabReducer,
  getCandidateFilter,
  getCurrentPage,
  getPageSize,
  getActiveTab,
  getActiveSourceName,
  getManualSearchActiveSource,
  getSelectedCandidates,
  getSelectedActiveCandidatesCount,
  getSelectedBulkActivity,
  getFilterQueries,
  getAllFilterQueries,
  getSelectedCandidateIds,
  getSelectedCustomBulkActivity,
  getCandidatesRecommendationStatus,
  getJobCandidateTabFrom,
};
