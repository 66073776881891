import React from 'react';
import _ from 'lodash';
import CandidatePastExperience from './CandidatePastExperience';
import CandidateSkillsSection from './CandidateSkillsSection';
import CandidateEducationSection from './CandidateEducationSection';
import styles from './CandidateQualificationSummary.module.scss';
import CandidateCardContext from '../../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';

function CandidateQualificationSummary() {
  const { candidate, keywordsToHighlight, activeTab, activeSourceName, mustHavesKeywords } =
    React.useContext(CandidateCardContext);

  const skillsMaxLinesToBeDisplayed = 2;
  const maxLinesToBeDisplayed = 1;
  const candidateExperiences = candidate.Experiences;
  const candidateEducation = candidate.Educations;
  const candidateSkills = candidate.Skills;
  const skillsRef = React.useRef(null);
  const educationRef = React.useRef(null);
  const experienceRef = React.useRef(null);

  const candidateKeywords =
    activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || [];
  const combinedKeywordsToHighlight = _.uniq([...(keywordsToHighlight || []), ...(candidateKeywords || [])]);

  const candidateSummaryLabel = (
    <div className={styles.summaryLabelGroup}>
      {candidateExperiences?.length ? (
        <div className={styles.summaryLabel} ref={experienceRef}>
          Experience:
        </div>
      ) : null}
      {candidateSkills?.length ? (
        <div className={styles.summaryLabel} ref={skillsRef}>
          Skills/Keywords:
        </div>
      ) : null}
      {candidateEducation?.length ? (
        <div className={styles.summaryLabel} ref={educationRef}>
          Education:
        </div>
      ) : null}
    </div>
  );

  const candidateSummaryValues = (
    <div className={styles.summaryValueGroup}>
      {candidateExperiences?.length ? (
        <CandidatePastExperience
          candidateExperiences={candidateExperiences}
          combinedKeywordsToHighlight={combinedKeywordsToHighlight}
          mustHavesKeywords={mustHavesKeywords}
          candidate={candidate}
          maxLinesToBeDisplayed={maxLinesToBeDisplayed}
          experienceRef={experienceRef}
          keywordHighlightKey={candidate.Id}
        />
      ) : null}
      {candidateSkills?.length ? (
        <CandidateSkillsSection
          skills={candidateSkills}
          keywordsToHighlight={keywordsToHighlight}
          mustHavesKeywords={mustHavesKeywords}
          candidate={candidate}
          combinedKeywordsToHighlight={combinedKeywordsToHighlight}
          maxLinesToBeDisplayed={skillsMaxLinesToBeDisplayed}
          skillsRef={skillsRef}
          keywordHighlightKey={candidate.Id}
          candidateExperiences={candidateExperiences}
          candidateEducation={candidateEducation}
        />
      ) : null}
      {candidateEducation?.length ? (
        <CandidateEducationSection
          candidateEducation={candidateEducation}
          maxLinesToBeDisplayed={maxLinesToBeDisplayed}
          educationRef={educationRef}
        />
      ) : null}
    </div>
  );

  return (
    <div className={styles.candidateQualificationSummary}>
      <div className={styles.candidatSummaryField}>
        {candidateSummaryLabel}
        {candidateSummaryValues}
      </div>
    </div>
  );
}

export default CandidateQualificationSummary;
