import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import CandidateRejectReasonsV2 from '../CandidateRejectReasons/CandidateRejectReasonsV2/CandidateRejectReasonsV2';
import FormCheckboxComponent from '../FormCheckboxComponent/FormCheckboxComponent';
import {
  getDefaultCandidateStatusReasons,
  generateStatusReasonsPayload,
  clearRejectReasonValues,
  getCurrentRejectReasonFormValues,
  getUpdatedCandidateStatusReasons,
  v2RejectReasonKeysToFormFieldsMapper,
  getIsRejectSimilarCandidatesAllowed,
  missingMandatorySkills,
} from '../../Utils/CandidateRejectReasonsUtils';
import './CandidateRejectCard.scss';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { DislikeIcon, InvertedUserBulbIcon } from '../../Icons/AryaIcons';
import eventTypes, { modules } from '../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import { getManualSearchCriteria } from '../../Reducers/ManualSearchReducer';
import * as manualSearchActions from '../../Actions/ManualSearchActions';

const dislikeIconProps = {
  theme: 'outlined',
  className: 'candidate-dislike-icon',
};

const getCandidateStatusReason = props => {
  const { isPaidJobServiceEnabled, candidate } = props;
  if (isPaidJobServiceEnabled) {
    return candidate.PublishStatusReasons || [];
  }
  return candidate.StatusReasons;
};

class CandidateRejectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { candidateStatusReasons: {} };
  }

  static getDerivedStateFromProps(props, state) {
    const { isDefaultFormValuesSet } = state;
    if (!isDefaultFormValuesSet) {
      const selectedCandidateStatusReasons = getCandidateStatusReason(props);
      const defaultCandidateStatusReasons = getDefaultCandidateStatusReasons(selectedCandidateStatusReasons);
      return {
        isDefaultFormValuesSet: true,
        candidateStatusReasons: defaultCandidateStatusReasons,
      };
    }
    return null;
  }

  onClearRejectReason = rejectReasonKey => {
    const { candidateStatusReasons } = this.state;
    const { form } = this.props;
    clearRejectReasonValues(rejectReasonKey, form);
    const updatedCandidateStatusReasons = getUpdatedCandidateStatusReasons(rejectReasonKey, candidateStatusReasons);
    this.setState({ candidateStatusReasons: updatedCandidateStatusReasons });
  };

  onApplyRejectReason = rejectReasonKey => {
    const { candidateStatusReasons } = this.state;
    const { form, ignoreSimilar } = this.props;
    if (rejectReasonKey === 'OPT_OUT' && ignoreSimilar) {
      form.setFieldsValue({ includeSimilar: false });
    }
    const formValues = form.getFieldsValue();
    const currentRejectReasonFormValues = getCurrentRejectReasonFormValues(rejectReasonKey, formValues);
    const updatedCandidateStatusReasons = {
      ...candidateStatusReasons,
      ...currentRejectReasonFormValues,
    };
    this.setState({ candidateStatusReasons: updatedCandidateStatusReasons });
    if (
      rejectReasonKey === missingMandatorySkills &&
      !_.isEqual(currentRejectReasonFormValues[missingMandatorySkills], formValues[missingMandatorySkills])
    ) {
      form.setFieldsValue({ [missingMandatorySkills]: currentRejectReasonFormValues[missingMandatorySkills] });
    }
  };

  onResetRejectReason = rejectReasonKey => {
    const { form } = this.props;
    form.resetFields(v2RejectReasonKeysToFormFieldsMapper[rejectReasonKey]);
  };

  getCandidateRejectStatus = () => {
    const { isPaidJobServiceEnabled, candidate } = this.props;
    if (isPaidJobServiceEnabled) {
      return candidate.PublishStatus === 'Rejected';
    }
    return _.get(candidate, 'Status', '') === 'Rejected';
  };

  getIsConfirmRejectDisabled = () => {
    const { candidate } = this.props;
    const { Status, StatusReasons } = candidate;
    return Status === 'Rejected' && StatusReasons?.find(reason => reason.Name?.toLowerCase() === 'opt_out');
  };

  onCandidateRejectConfirm = e => {
    e.stopPropagation();

    const { onCandidateStatusChange, candidate, size, form, onCandidateRejectClick, featureToggleList } = this.props;
    const { candidateStatusReasons } = this.state;
    const isRejected = this.getCandidateRejectStatus();
    const formValues = form.getFieldsValue();
    const { includeSimilar, mustHaves } = formValues;
    const rejectReasons = generateStatusReasonsPayload(candidateStatusReasons);
    const candidateFilter = {
      status: isRejected ? 'Sourced' : 'Rejected',
      candidate,
      includeSimilar,
      rejectReasons,
      mustHaves,
    };
    const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
    if (!this.getIsConfirmRejectDisabled()) {
      onCandidateStatusChange(candidateFilter, size);
      if (isAdvancedSearchV2Enabled && onCandidateRejectClick) onCandidateRejectClick();
      logSmartKarrotEvent(
        getEventNameByCandidateStatus(eventTypes.candidate.candidateReject.confirmRejectCandidate, candidate),
        { Module: modules.candidate.candidateActions }
      );
    }
  };

  render() {
    const { candidateStatusReasons } = this.state;
    const {
      onCandidateReject,
      candidate,
      ignoreSimilar,
      size,
      isCandidateListRejectCard,
      form,
      suggestions,
      jobId,
      onAddingMissingMandatorySkills,
      openInNewTab,
      manualSearchForm,
      isConnectTab,
    } = this.props;
    const isRejected = this.getCandidateRejectStatus();
    const isConfirmRejectDisabled = this.getIsConfirmRejectDisabled();
    const rejectIconStyle = { fontSize: '24px' };
    if (isConfirmRejectDisabled) {
      rejectIconStyle.color = 'rgba(7,16,26,0.2)';
      rejectIconStyle.cursor = 'not-allowed';
    }
    const {
      Title: TITLE_MISMATCH,
      Skills: MISSING_MANDATORY_SKILLS,
      Company: COMPANY_MISMATCH,
      Derived,
      Industry: INDUSTRY_MISMATCH,
      Id: candidateId,
      Name,
      ConnectionStatus,
    } = candidate;
    const isRejectSimilarCandidatesButtonEnabled = getIsRejectSimilarCandidatesAllowed(candidateStatusReasons);
    return (
      <div
        className={`candidate-reject-card ${size} ${isCandidateListRejectCard ? 'candidate-card' : ''}`}
        onClick={e => e.stopPropagation()}
        role="presentation"
        onKeyPress={e => e.stopPropagation()}
      >
        <div className={`candidate-reject-card-reject-reasons ${size}`}>
          <div className="candidate-reject-card-intel-icon-wrapper">
            <InvertedUserBulbIcon />
            <div className="candidate-reject-card-icon-text-wrapper">
              <div className="refine-text">Refine the result</div>
              <div className="give-reasons-text">Give reasons to reject {Name}?</div>
            </div>
          </div>

          <div className="candidate-reject-reasons-wrapper">
            <div className="select-reject-reasons-text">Select from the reject reasons</div>
            <div className="reject-reasons-wrapper">
              <CandidateRejectReasonsV2
                form={form}
                candidateStatusReasons={candidateStatusReasons}
                suggestions={suggestions}
                negativeValues={{
                  TITLE_MISMATCH,
                  MISSING_MANDATORY_SKILLS,
                  COMPANY_MISMATCH,
                  JOB_FUNCTION_MISMATCH: Derived?.JobFunction,
                  INDUSTRY_MISMATCH,
                }}
                candidateConnectionStatus={ConnectionStatus}
                jobId={jobId}
                candidateId={candidateId}
                onApplyRejectReason={this.onApplyRejectReason}
                onClearRejectReason={this.onClearRejectReason}
                onResetRejectReason={this.onResetRejectReason}
                size={size}
                onAddingMissingMandatorySkills={onAddingMissingMandatorySkills}
                openInNewTab={openInNewTab}
                manualSearchForm={manualSearchForm}
                isConnectTab={isConnectTab}
              />
            </div>
            {ignoreSimilar ? (
              <div
                className={`candidate-reject-card-text reject-similar-candidate ${!isRejectSimilarCandidatesButtonEnabled ? 'disabled' : null
                  }`}
              >
                <FormCheckboxComponent
                  disabled={!isRejectSimilarCandidatesButtonEnabled}
                  form={form}
                  className="candidate-reject-card-text"
                  fieldDecoratorValue="includeSimilar"
                  displayText={`${isRejected ? 'Include' : 'Reject'} similar candidates`}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="candidate-reject-card-reject">
          <div
            className="candidate-reject-cancel"
            role="button"
            tabIndex={0}
            onKeyPress={() => onCandidateReject(candidate, size)}
            onClick={e => {
              e.stopPropagation();
              onCandidateReject(candidate, size);
            }}
          >
            Cancel
          </div>
          <div
            className={`candidate-reject-confirm ${isConfirmRejectDisabled ? 'confirm-reject-disable' : ''}`}
            role="button"
            tabIndex={0}
            onClick={this.onCandidateRejectConfirm}
            onKeyPress={this.onCandidateRejectConfirm}
          >
            Confirm
            <DislikeIcon style={{ fontSize: '32px' }} />
          </div>
        </div>
      </div>
    );
  }
}

CandidateRejectCard.prototypes = {
  candidate: PropTypes.shape({}).isRequired,
};

CandidateRejectCard.defaultProps = {
  candidate: {},
};

const mapStateToProps = (state, props) => ({
  unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  updateManualSearchCriteria: manualSearchActions.updateManualSearchCriteria,
  saveManualSearchCriteria: manualSearchActions.saveManualSearchCriteria,
  setManualSearchFormValues: manualSearchActions.setManualSearchFormValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CandidateRejectCard));

export { CandidateRejectCard as CandidateRejectCardWithoutForm };
