import React from 'react';
import { Modal, Alert, Radio } from 'antd';
import styles from './DeleteCandidateModal.module.scss';

function DeleteCandidateModal(props) {
  const { isVisible, onCancel, onOk, selctedOption, setSelectedOption, activeTab } = props;
  const message = `Deleting candidate will remove him/her from the on-going drip campaign. Continue to delete?`;
  const includedTabMessage = `Deleting this candidate will move them to removed bucket, and remove them from all ongoing drip campaign.`;

  const onChange = e => {
    setSelectedOption(e.target.value);
  };
  const showSubsegmentOptions = activeTab === 'subsegment';
  return (
    <div>
      <Modal
        visible={isVisible}
        title="Delete Candidate"
        onCancel={onCancel}
        onOk={onOk}
        okButtonProps={{ type: 'primary', size: 'medium', shape: 'round' }}
        cancelButtonProps={{ size: 'medium', shape: 'round' }}
        okText="Yes"
        zIndex={2002}
      >
        <div className={styles.alertMessage}>
          <Alert message={activeTab === 'subsegment' ? message : includedTabMessage} type="warning" showIcon />
          {showSubsegmentOptions && (
            <Radio.Group onChange={onChange} style={{ marginLeft: 28 }} value={selctedOption}>
              <Radio value={1}>From Sub-Segment</Radio>
              <Radio value={2}>Both Segment and Sub-Segment</Radio>
            </Radio.Group>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default DeleteCandidateModal;
