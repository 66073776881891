/* eslint-disable no-console */

import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import _ from 'lodash';
import Config from '../Config/Config';

function setConfig(region, poolId, clientId) {
  Amplify.configure({
    Auth: {
      region,
      userPoolId: poolId,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: clientId,
    },
  });
}

const mycareersClient = axios.create({
  baseURL: Config.mycareers.url,
});
mycareersClient.defaults.headers.common['x-client-id'] = Config.mycareers.clientId;

const apiV1Client = axios.create({
  baseURL: Config.apiV1Url,
});
const apiV2Client = axios.create({
  baseURL: Config.apiV2Url,
});
const apiV3Client = axios.create({
  baseURL: Config.apiV3Url,
});
const apiV4Client = axios.create({
  baseURL: Config.apiV4Url,
});
const apiV5Client = axios.create({
  baseURL: Config.apiV5Url,
});
const connectV1Client = axios.create({
  baseURL: Config.urls.aryaConnect,
});
const payV1Client = axios.create({
  baseURL: Config.urls.aryaPay,
});
payV1Client.defaults.headers.common['x-preferred-currency'] = 'USD';
const aryaNotifyV1Client = axios.create({
  baseURL: Config.urls.aryaNotify,
});
const smartJobV1Client = axios.create({
  baseURL: Config.urls.smartjob,
});
const atlasApiV1Client = axios.create({
  baseURL: Config.urls.atlasApiUrl,
});
const ipStackClient = axios.create({
  baseURL: Config.ipstack.url,
});

const advanceSearchActivityClient = axios.create({
  baseURL: Config.advanceSearchActivity.Api,
});

function setContentTypeIfPatchRequest(config) {
  const { headers } = config;
  if (config.method === 'patch') {
    headers['Content-Type'] = 'application/json-patch+json';
  }
}

function axiosInterceptor(config) {
  setContentTypeIfPatchRequest(config);
  const managementUser = localStorage.getItem('ManagementUser');
  const impersonate = localStorage.getItem('Impersonate');

  return Auth.currentSession()
    .then(session => {
      const { jwtToken: accessToken } = session.getIdToken();
      const { headers } = config;
      headers.Authorization = `Bearer ${accessToken}`;
      headers['x-app-id'] = Config.appId;
      headers['x-arya-client'] = 'arya-webapp';
      if (managementUser && impersonate) {
        headers['x-impersonate'] = impersonate;
      }
      return Promise.resolve(config);
    })
    .catch(err => {
      console.error(err);
      return Promise.resolve(config);
    });
}

function reponseSuccessInterceptor(response) {
  return response;
}

function responseErrorInterceptor(error) {
  const statusCode = _.get(error, ['response', 'status']);
  const isNetworkError = error.toJSON && error.toJSON().message === 'Network Error';
  const requestUrl = _.get(error, ['config', 'url'], '').toLowerCase();
  const isTitleSynonymsRequestError = requestUrl === 'titlesynonyms';
  const isDistributionStatsRequestError = requestUrl === 'candidates/_distributionstats';
  const isMasterDataRequestError = requestUrl === 'masterdata';
  const isTitlesRequestError = requestUrl === 'jobs/title/_search';
  const isSkillsRequestError = requestUrl === 'jobs/title/skills/_search';
  const isTryNowRequestError = requestUrl === 'candidates/_trynow';

  if (
    !Number.isInteger(statusCode) &&
    isNetworkError &&
    !isTitleSynonymsRequestError &&
    !isDistributionStatsRequestError &&
    !isMasterDataRequestError &&
    !isTitlesRequestError &&
    !isSkillsRequestError &&
    !isTryNowRequestError
  ) {
    console.error('Gotcha!', error);
  }

  return Promise.reject(error);
}

apiV1Client.interceptors.request.use(axiosInterceptor);
apiV3Client.interceptors.request.use(axiosInterceptor);
apiV4Client.interceptors.request.use(axiosInterceptor);
apiV2Client.interceptors.request.use(axiosInterceptor);
apiV5Client.interceptors.request.use(axiosInterceptor);
connectV1Client.interceptors.request.use(axiosInterceptor);
payV1Client.interceptors.request.use(axiosInterceptor);
aryaNotifyV1Client.interceptors.request.use(axiosInterceptor);

apiV1Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
apiV3Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
apiV4Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
apiV2Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
apiV5Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
connectV1Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
payV1Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
aryaNotifyV1Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);
smartJobV1Client.interceptors.response.use(reponseSuccessInterceptor, responseErrorInterceptor);

export {
  setConfig,
  apiV1Client,
  apiV2Client,
  apiV3Client,
  apiV4Client,
  apiV5Client,
  connectV1Client,
  payV1Client,
  aryaNotifyV1Client,
  smartJobV1Client,
  atlasApiV1Client,
  ipStackClient,
  responseErrorInterceptor,
  advanceSearchActivityClient,
  axiosInterceptor,
  mycareersClient,
};
