import React from 'react';
import { Icon } from 'antd';
import * as GsmCharsetUtils from '@trt2/gsm-charset-utils';
import { parseTextFromHtml } from '../../Utils/DomParserUtils';
import ConnectMessageStatusOptions from '../../Data/ConnectMessageStatus/ConnectMessageStatusOptions.json';
import MessageDeliveredIcon from '../../Icons/MessageDeliveredIcon';
import MessageSentIcon from '../../Icons/MessageSentIcon';

const { onlyEmoji } = require('emoji-aware');

export function removeUnicodeQuotation(inputValue) {
  return inputValue?.replace(/[‘’`]/g, "'").replace(/[“”]/g, '"').replace(/ /g, ' '); // eslint-disable-line
}

export function messageCount(inputValue, isConsentMessage) {
  if (inputValue) {
    const nonHtmlString = parseTextFromHtml(inputValue);
    const shouldShowConsentMessageCount = nonHtmlString.length > 0 && isConsentMessage;
    const actualCharacterCount = shouldShowConsentMessageCount ? nonHtmlString?.length + 133 : nonHtmlString?.length;
    const charCount = inputValue ? actualCharacterCount : 0;
    let textCount = 0;
    if (isConsentMessage) {
      textCount = charCount === 0 ? 0 : Math.ceil(charCount / 160);
    } else {
      textCount = charCount === 0 ? 0 : GsmCharsetUtils.getCharCount(nonHtmlString)?.msgCount || 0;
    }
    return `${charCount} Characters | ${textCount} Text Message |`;
  }
  return '0 Characters | 0 Text Message |';
}

export function emojiFinder(inputValue) {
  return inputValue ? onlyEmoji(inputValue) : '';
}

export const getMessageDeliveryStatusInformation = () =>
  // messageDeliveryStatusInformation,
  // deliveryStatus,
  // conversationRequestStatusReason
  {
    // if (messageDeliveryStatusInformation) return ConnectMessageStatusOptions[messageDeliveryStatusInformation];
    // if (conversationRequestStatusReason) return ConnectMessageStatusOptions[conversationRequestStatusReason];
    return null;
    // switch (deliveryStatus?.toLowerCase()) {
    //   case 'failed':
    //     return ConnectMessageStatusOptions.UnknownFailure;
    //   case 'pending':
    //     return ConnectMessageStatusOptions.Queued;
    //   default:
    //     return null;
    // }
  };

export const getMessageDeliveryStatusIcon = (deliveryStatus, messageDeliveryStatusInformation) => {
  return deliveryStatus;
  // switch (deliveryStatus?.toLowerCase()) {
  //   case 'delivered':
  //     return <MessageDeliveredIcon />;
  //   case 'failed':
  //     return <Icon type="exclamation-circle" />;
  //   case 'pending':
  //   case 'scheduled':
  //     if (messageDeliveryStatusInformation === 'NetworkSent') return <MessageSentIcon />;
  //     return <Icon type="clock-circle" style={{ color: '#ffcc00', fontSize: '12px' }} />;
  //   case 'skipped':
  //     return 'Skipped';
  //   default:
  //     return <Icon type="clock-circle" style={{ color: '#ffcc00', fontSize: '12px' }} />;
  // }
};
