import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Affix, Skeleton, Empty } from 'antd';
import styles from './GroupList.module.scss';
import '../../../Components/JobList/JobList.scss';
import GroupListItem from '../GroupListItem/GroupListItem';
import { getUsersById } from '../../../Reducers/UserReducer';
import { getScoutingAgentConfig } from '../../../Reducers/ScoutingAgentReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import * as JobActions from '../../../Actions/JobActions';
import * as JobActionCreator from '../../../Actions/ActionCreators/JobActionCreator';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { EmptySegmentsIcon } from '../../../Icons/AryaIcons';
import ScoutingAgentDrawer from '../../ManualSearch/ScoutingAgent/ScoutingAgentDrawer';
import ScoutingAgentModal from '../../ManualSearch/ScoutingAgent/ScoutingAgentModal';

const mapStateToProps = state => ({
  usersById: getUsersById(state),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
});

const mapDispatchToProps = {
  fetchJobList: JobActions.fetchJobList,
  fetchActivationStatus: JobActions.fetchActivationStatus,
  fetchScoutingAgentsByJobIds: ScoutingAgentActions.fetchScoutingAgentsByJobIds,
  clearSegmentList: JobActionCreator.clearSegmentList,
};

function GroupList(props) {
  const {
    usersById,
    form,
    segmentList,
    editSegment,
    segmentDetails,
    createSegment,
    fetchSegmentListApiStatus,
    fetchJobAutomationConfiguration,
    associatedTemplateName,
    onSegmentCreationCallback,
    version,
    featureToggleList,
    fetchActivationStatus,
    fetchScoutingAgentsByJobIds,
    clearSegmentList,
    scoutingAgentConfig,
    history,
  } = props;
  const [selectedSegmentId, setSelectedSegmentId] = React.useState(null);
  React.useEffect(() => {
    if (segmentList?.jobs?.length) {
      const jobIds = segmentList?.jobs?.map(entry => entry.JobId);
      fetchJobAutomationConfiguration({ JobIds: jobIds });
      const isScoutingAgentEnabled = _.get(featureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
      if (isScoutingAgentEnabled && Array.isArray(jobIds)) fetchScoutingAgentsByJobIds(jobIds);
    }
  }, [segmentList]);

  React.useEffect(() => {
    return () => {
      clearSegmentList();
    };
  }, []);

  React.useEffect(() => {
    const jobIds = segmentList?.jobs?.map(entry => entry.JobId);
    fetchActivationStatus(jobIds);
  }, [fetchActivationStatus, segmentList?.jobs?.length]);

  if (fetchSegmentListApiStatus === 'INPROGRESS') return <Skeleton paragraph={{ rows: 10 }} loading active />;

  const getTemplateName = jobId => {
    const isEmailEnabled = associatedTemplateName?.[jobId]?.Email?.IsEnabled;
    if (isEmailEnabled) return associatedTemplateName?.[jobId]?.Email?.TemplateName;
    return associatedTemplateName?.[jobId]?.Workflows?.[0]?.WorkflowDefinitionName;
  };
  return (
    <div id={styles.jobTable}>
      {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && (
        <>
          <ScoutingAgentDrawer history={history} jobId={selectedSegmentId} candidateContext="segments" />
          <ScoutingAgentModal jobId={selectedSegmentId} candidateContext="segment" />
        </>
      )}

      <Affix offsetTop={version !== 'ats' ? 64 : 0}>
        <Row id={styles.tableHeading}>
          <Col xs={24} sm={5} className={styles.listName}>
            SEGMENT NAME
          </Col>
          <Col xs={24} sm={5}>
            ASSOCIATED CAMPAIGNS
          </Col>
          <Col xs={24} sm={5}>
            CREATED BY
          </Col>
          <Col xs={24} sm={4}>
            SHARED WITH
          </Col>
          <Col></Col>
        </Row>
      </Affix>
      {segmentList?.jobs?.length > 0 ? (
        segmentList.jobs.map(item => (
          <GroupListItem
            segmentName={item.JobTitle}
            segmentDescription={item.Description}
            events={getTemplateName(item.JobId)}
            time={item.CreatedDate}
            createdBy={item.CreatedBy}
            usersById={usersById}
            form={form}
            segmentId={item.JobId}
            editSegment={editSegment}
            segmentDetails={segmentDetails}
            createSegment={createSegment}
            segment={item}
            fetchActivationStatus={fetchActivationStatus}
            setSelectedSegmentId={setSelectedSegmentId}
            fetchJobAutomationConfiguration={fetchJobAutomationConfiguration}
            onSegmentCreationCallback={onSegmentCreationCallback}
          />
        ))
      ) : (
        <div className={styles.emptyScreen}>
          <Empty
            image={<EmptySegmentsIcon className={styles.segmentListEmpty} />}
            description={
              <span>
                <span className="job-list-no-job-found"> No Segments found</span>
                <br />
                <span className="job-list-no-job-found-message">All the segments appear here</span>
              </span>
            }
          />
        </div>
      )}
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
export { GroupList };
