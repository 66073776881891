import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Row, Col, Affix, Empty } from 'antd';
import CallHistoryItem from './CallHistoryItem';
import './CallHistory.scss';
import { CallIcon } from '../../Icons/AryaIcons';

function CallHistory({
  callHistory,
  currentUser,
  connectUsersById,
  connectComponent,
  isUnsubscribeCandidateEnabled,
  unsubscribeCandidateButton,
}) {
  if (!callHistory) {
    return null;
  }
  const { Order: list, ByCallId: byId } = callHistory;
  if (!list || !byId) return null;
  return (
    <div className={classNames('call-history', { 'call-history-connect': connectComponent })}>
      <div className="title">Call History</div>
      <Affix>
        <Row gutter={19} className="heading" type="flex" justify="space-between">
          <Col span={5}>STATUS</Col>
          <Col span={5}>CALLED BY</Col>
          <Col span={7}>PHONE NUMBER</Col>
          <Col span={4}>DATE/TIME</Col>
        </Row>
      </Affix>
      {list.length ? (
        <div className="list">
          {list.map(id => (
            <CallHistoryItem
              key={id}
              callDetails={byId[id]}
              createdUser={_.get(connectUsersById, byId[id].CreatedBy, {})}
            />
          ))}
        </div>
      ) : (
        <div>
          <Empty description="No Call History Available" image={<CallIcon className="empty-call-icon" />} />
        </div>
      )}
      {list.length > 0 && !connectComponent && (
        <Affix offsetBottom={0}>
          <span className="unsubscribe-button-candidate-card-call-history">
            {isUnsubscribeCandidateEnabled ? unsubscribeCandidateButton : null}
          </span>
        </Affix>
      )}
    </div>
  );
}

export default CallHistory;
