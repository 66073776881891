import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import classNames from 'classnames';
import JobStatusChangeContainer from '../../Containers/JobStatusChange/JobStatusChangeContainer';
import './JobStatus.scss';

const { Option } = Select;

function JobStatus(props) {
  const {
    jobStatus,
    onJobStatusChange,
    status,
    jobId,
    className = 'job-status-change-filter',
    dropdownClassName = 'job-status-change-filter-dropdown  floating-menu-popup',
    form,
    job,
    userConfig = {},
  } = props;
  const [jobStatusChangeVisible, setJobStatusChangeVisible] = React.useState(false);
  const [nextJobStatus, setNextJobStatus] = React.useState(status.Name);

  const isJobStatusSelectDisabled = userConfig.IsJobStatusChangeFromAryaUIRestricted

  const statusIdList = Object.keys(jobStatus);
  const menuList = statusIdList.map(statusId => (
    <Option key={jobStatus[statusId].Name} value={jobStatus[statusId].Name}>
      {jobStatus[statusId].Name}
    </Option>
  ));
  const handleJobStatusChange = key => {
    const newStatus = jobStatus.find(elem => elem.Name === key);
    const oldStatus = jobStatus.find(elem => elem.Name === status);
    if (newStatus.AryaStatus === 'Closed' && oldStatus.AryaStatus === 'Open') {
      setJobStatusChangeVisible(true);
      setNextJobStatus(key);
    } else {
      const setPreviousJobStatusValue = () => {
        form.setFieldsValue({ JobStatus: status })
      }
      onJobStatusChange(jobId, key, setPreviousJobStatusValue)
    };
  };
  return (
    <>
      <JobStatusChangeContainer
        visible={jobStatusChangeVisible}
        jobId={jobId}
        oldJobStatus={status}
        nextJobStatus={nextJobStatus}
        setJobStatusChangeVisible={setJobStatusChangeVisible}
        onJobStatusChange={onJobStatusChange}
        form={form}
        jobGuid={job?.JobGuid}
      />
      {form.getFieldDecorator('JobStatus', { initialValue: status })(
        <Select
          size="small"
          className={classNames(
            className,
            { 'job-status-disabled-select': isJobStatusSelectDisabled }
          )}
          onChange={handleJobStatusChange}
          dropdownClassName={dropdownClassName}
          disabled={isJobStatusSelectDisabled}
          showArrow={!isJobStatusSelectDisabled}
        >
          {menuList}
        </Select>
      )}
    </>
  );
}

JobStatus.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onJobStatusChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  jobId: PropTypes.number.isRequired,
};

export { JobStatus as JobStatusWithoutForm };
export default Form.create()(JobStatus);
