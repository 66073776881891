import React from 'react';
import _ from 'lodash';
import { Form, Input, Button, Row, Col, Select, Icon, InputNumber } from 'antd';
import styles from './GlobalInsightLocationFilter.module.scss';
import { RotateLeftIcon } from '../../../Icons/AryaIcons';
import { purifySelectedOptions } from '../../../Utils/SelectComponentUtility';

const { Option } = Select;

export default function GlobalInsightLocationFilter(props) {
  const {
    form,
    supplyPayload,
    fetchGlobalInsightSupplyList,
    handleReset,
    handleCountryChange,
    stateChange,
    cityChange,
    filterStates,
    filterCities,
    statesDropDownStatus,
    locationDropDownStatus,
    listCountries,
    listStates,
    locations,
    isGlobalInsightLandingPage,
    prepopulatedJobDetails,
    isCandidateSupply,
    fetchCareerProjectionList,
    isCareerProjection,
    utilities,
    fetchIndustries,
  } = props;

  React.useEffect(() => {
    const industries = _.get(utilities, ['industries'], []);
    if (!industries?.length)
      fetchIndustries({
        SearchTerm: '',
        Size: 200,
      });
  }, []);

  const isJobDetailsAvailable = !!prepopulatedJobDetails;
  let locationOptionsList = null;
  let countryOptionsList = null;
  let statesOptionsList = null;
  if (listCountries) {
    countryOptionsList = listCountries.map(country => (
      <Option value={country.Iso2Code} key={country.Iso2Code}>
        {country.Name}
      </Option>
    ));
  }
  if (listStates) {
    statesOptionsList = listStates.map((state, index) => (
      <Option value={state.state.name} key={`${state.state.name}-${index.toString()}`}>
        {state.state.name}
      </Option>
    ));
  }

  if (locations) {
    locationOptionsList = locations.map((locationOption, index) => {
      let optionValue = locationOption.city ? `${locationOption.city}, ` : '';
      optionValue = locationOption.state?.name ? optionValue + locationOption.state.name : optionValue;
      const indexOfComa = optionValue.indexOf(',');
      if (indexOfComa > 0) {
        optionValue = optionValue.substring(0, indexOfComa);
      }
      return (
        <Option value={locationOption.city} key={`${optionValue}-${index.toString()}`}>
          {optionValue}
        </Option>
      );
    });
  }

  const multipleInputComponent = (labelTitle, fieldDecoratorValue, placeHolder, initialValue) => {
    return (
      <Col span={6}>
        <Form.Item label={labelTitle} colon={false} name="SupplySearch">
          {form.getFieldDecorator(fieldDecoratorValue, {
            initialValue,
          })(
            <Select
              mode="tags"
              key={labelTitle}
              className={styles.selectDropdown}
              placeholder={placeHolder}
              onBlur={() => purifySelectedOptions(form, fieldDecoratorValue)}
              onSelect={() => purifySelectedOptions(form, fieldDecoratorValue)}
            />
          )}
        </Form.Item>
      </Col>
    );
  };

  const multiSelectDropdownComponent = (labelTitle, fieldDecoratorValue, placeHolder, initialValue, options = []) => (
    <Col span={6}>
      <Form.Item label={labelTitle} colon={false} name="SupplySearch">
        {form.getFieldDecorator(fieldDecoratorValue, {
          initialValue,
        })(
          <Select
            mode="tags"
            key={labelTitle}
            className={styles.selectDropdown}
            placeholder={placeHolder}
            onBlur={() => purifySelectedOptions(form, fieldDecoratorValue)}
            onSelect={() => purifySelectedOptions(form, fieldDecoratorValue)}
          >
            {options.map(item => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </Col>
  );

  const buttonsComponent = onSubmit => {
    return (
      <div className="location-filter-button">
        <Form.Item label="&nbsp;" colon={false}>
          <Button id="SearchButton" className="button-search" shape="round" onClick={onSubmit}>
            <Icon type="search" />
            Search
          </Button>
        </Form.Item>
        {isGlobalInsightLandingPage ? null : (
          <Form.Item label="&nbsp;" colon={false}>
            <Button id="resetOver" className="button-search last-button" shape="round" onClick={handleReset}>
              <RotateLeftIcon style={{ height: '15px', width: '16px' }} />
              Start Over
            </Button>
          </Form.Item>
        )}
      </div>
    );
  };

  const countryInput = (handleChange, options) => {
    return (
      <Form.Item label="Country" colon={false}>
        {form.getFieldDecorator('CountryCode', {
          rules: [{ required: true, message: 'Please select country' }],
          initialValue: isJobDetailsAvailable ? prepopulatedJobDetails?.CountryCode : supplyPayload.CountryCode,
        })(
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            key="Country"
            placeholder="Country"
            className={styles.selectDropdown}
            optionFilterProp="children"
            dropdownClassName="country-dropdown"
            onChange={handleChange}
            onClear={handleChange}
          >
            {options}
          </Select>
        )}
      </Form.Item>
    );
  };

  const stateInput = (handleChange, onSearch, options, dropDownStatus) => {
    return (
      <Form.Item label="State/Province" colon={false}>
        {form.getFieldDecorator('State', { initialValue: prepopulatedJobDetails?.State })(
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            key="State"
            placeholder="State/Province"
            className={styles.selectDropdown}
            dropdownClassName="country-dropdown"
            notFoundContent={dropDownStatus}
            onSearch={onSearch}
            onChange={handleChange}
            onClear={handleChange}
            disabled={!form.getFieldValue('CountryCode')}
          >
            {options}
          </Select>
        )}
      </Form.Item>
    );
  };

  const cityInput = (options, handleChange, onSearch, dropDownStatus) => {
    return (
      <Form.Item name="Location" label="City" colon={false}>
        {form.getFieldDecorator('Location', { initialValue: prepopulatedJobDetails?.City })(
          <Select
            showSearch
            allowClear
            key="Location"
            style={{ width: '100%' }}
            placeholder="City"
            className={styles.selectDropdown}
            notFoundContent={dropDownStatus}
            onSearch={onSearch}
            dropdownClassName="Location-dropdown"
            showArrow={false}
            disabled={!form.getFieldValue('State')}
            onChange={handleChange}
            onClear={handleChange}
          >
            {options}
          </Select>
        )}
      </Form.Item>
    );
  };

  const distanceInput = () => {
    return (
      <Col span={3} className={styles.inputDistance}>
        <Form.Item name="Distance" label="Distance" colon={false}>
          {form.getFieldDecorator('Distance', {
            initialValue: 50,
          })(
            <InputNumber
              key="Distance"
              min={0}
              max={1000}
              placeholder="0"
              parser={value => value.replace(/[^0-9]+/g, '')}
              disabled={!form.getFieldValue('Location')}
            />
          )}
        </Form.Item>
        <div className={styles.inputDistanceSuffix}>Miles</div>
      </Col>
    );
  };

  if (isCandidateSupply) {
    return (
      <div className={styles.supplyLocationFilter}>
        <Row gutter={16} style={{ marginTop: '10px' }}>
          <Col span={6}>
            <Form.Item label="Job Title" colon={false} name="SupplySearch">
              {form.getFieldDecorator('Role', {
                initialValue: prepopulatedJobDetails?.Title,
              })(<Input placeholder="Title" />)}
            </Form.Item>
          </Col>
          {multipleInputComponent(
            'Skills/Keywords',
            'Skills',
            'Example: Java, Hibernate',
            prepopulatedJobDetails?.Skills
          )}
          {multiSelectDropdownComponent(
            'Industries',
            'Industries',
            'Example: Banking, Internet',
            prepopulatedJobDetails?.Industries,
            utilities?.industries
          )}
          {multipleInputComponent(
            'Companies',
            'Companies',
            'Example: Microsoft, Twitter',
            prepopulatedJobDetails?.Companies
          )}
        </Row>
        <Row gutter={16} style={{ marginTop: '16px' }}>
          <Col span={5}>{countryInput(handleCountryChange, countryOptionsList)}</Col>
          <Col span={5}>{stateInput(stateChange, filterStates, statesOptionsList, statesDropDownStatus)}</Col>
          <Col span={5}>{cityInput(locationOptionsList, cityChange, filterCities, locationDropDownStatus)}</Col>
          {distanceInput()}
          <Col span={6}>{buttonsComponent(fetchGlobalInsightSupplyList)}</Col>
        </Row>
      </div>
    );
  }

  if (isCareerProjection) {
    return (
      <div className={styles.supplyLocationFilter}>
        <Row gutter={16} style={{ marginTop: '10px' }}>
          <Col span={6}>
            <Form.Item label="Job Title" colon={false} name="SupplySearch">
              {form.getFieldDecorator('Role', {
                rules: [{ required: true, message: 'Please enter job title' }],
                initialValue: prepopulatedJobDetails?.Title,
              })(<Input placeholder="Title" />)}
            </Form.Item>
          </Col>
          <Col span={4}>{countryInput(handleCountryChange, countryOptionsList)}</Col>
          <Col span={4}>{stateInput(stateChange, filterStates, statesOptionsList, statesDropDownStatus)}</Col>
          <Col span={4}>{cityInput(locationOptionsList, cityChange, filterCities, locationDropDownStatus)}</Col>
          <Col span={6}>{buttonsComponent(fetchCareerProjectionList)}</Col>
        </Row>
      </div>
    );
  }

  return null;
}
