import React from 'react';
import { FormSelectComponent, FormRadioGroupComponent, FormInputComponent } from '../../../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../../../Utils/MapperUtils';
import { getColonSplittedArray } from '../../QuickSearchFormUtils';
import CareerBuilderEdgeOptions from '../../../../Data/AdvancedOptions/CareerBuilderEdge/CareerBuilderEdgeOptions.json';
import CareerBuilderEdgeInputs from '../../../../Data/AdvancedOptions/CareerBuilderEdge/CareerBuilderEdgeInputs.json';
import '../AdvancedOptions.scss';

export default function CareerBuilderEdgeAdvancedOptions(props) {
  const {
    DesiredJobTypeOptions,
    CompanySizeCodeOptions,
    WorkStatusOptions,
    WillingToRelocateOptions,
    SecurityClearanceOptions,
  } = CareerBuilderEdgeOptions;

  const {
    DesiredJobTypeParams,
    CompanySizeParams,
    WorkStatusParams,
    SecurityClearanceParams,
    WillingToRelocateParams,
    CandidateNameParams,
    EmployerNameParams,
    EmailParams,
  } = CareerBuilderEdgeInputs;

  const { form, defaultSettings, maskingConfig, diversityForNameText } = props;
  const { Names, Employer, Email, CompanySizeCode, DesiredJobType, WorkStatus, WillingToRelocate, SecurityClearance } =
    defaultSettings;

  const { IsNameMasked: isNameMasked } = maskingConfig;
  const tooltipText = isNameMasked ? diversityForNameText('name', 'Name') : '';
  return (
    <div className="quicksearch-advanced-options careerbuilder-edge-advanced-options">
      <FormInputComponent
        {...CandidateNameParams}
        form={form}
        initialValue={Names}
        tooltipText={tooltipText}
        isNameMasked={isNameMasked}
      />
      <FormSelectComponent {...EmailParams} form={form} initialValue={getColonSplittedArray(Email)} />
      <FormInputComponent {...EmployerNameParams} form={form} initialValue={Employer} />

      <FormSelectComponent
        {...CompanySizeParams}
        form={form}
        initialValue={getColonSplittedArray(CompanySizeCode)}
        inputValues={formValuesMapper(CompanySizeCodeOptions)}
      />
      <FormSelectComponent
        {...DesiredJobTypeParams}
        form={form}
        initialValue={getColonSplittedArray(DesiredJobType)}
        inputValues={formValuesMapper(DesiredJobTypeOptions)}
      />
      <FormSelectComponent
        {...WorkStatusParams}
        form={form}
        initialValue={WorkStatus}
        inputValues={formValuesMapper(WorkStatusOptions)}
      />
      <FormRadioGroupComponent
        {...SecurityClearanceParams}
        form={form}
        initialValue={SecurityClearance || 'not_selected'}
        inputValues={formValuesMapper(SecurityClearanceOptions)}
      />
      <FormRadioGroupComponent
        {...WillingToRelocateParams}
        form={form}
        initialValue={WillingToRelocate || 'not_selected'}
        inputValues={formValuesMapper(WillingToRelocateOptions)}
      />
    </div>
  );
}
