import React from 'react';
import { Skeleton } from 'antd';
import { getCleanedHighlights } from '../../Utils/KeywordHighlightUtil';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import PdfViewer from '../PdfViewer/PdfViewer';
import TextViewer from '../TextViewer/TextViewer';

const emptyArray = [];

function ResumeViewer(props) {
  const {
    resume,
    highlights,
    mustHavesKeywords = emptyArray,
    candidateDetailsStatus,
    candidateListStatus,
    isSocialResumeDownloadAllowed,
    isSocialResumeGenerationEnabled,
  } = props;

  const cleanedHighlights = getCleanedHighlights(highlights);

  const resumeViewer = resumeDetails => {
    switch (resumeDetails.MediaType) {
      case 'application/pdf':
        return (
          <PdfViewer
            highlights={cleanedHighlights}
            mustHavesKeywords={mustHavesKeywords}
            base64PdfContent={`data:application/pdf;base64,${resumeDetails.Content}`}
          />
        );
      case 'text/html':
        return (
          <HtmlViewer
            mustHavesKeywords={mustHavesKeywords}
            highlights={cleanedHighlights}
            htmlContent={resumeDetails.Content}
          />
        );
      case 'text/plain':
        return (
          <TextViewer
            mustHavesKeywords={mustHavesKeywords}
            highlights={cleanedHighlights}
            className="resume-textviewer"
            text={resumeDetails.Content}
          />
        );
      default:
        return null;
    }
  };

  const getResumeInfoText = () => {
    if (isSocialResumeDownloadAllowed) return <div>You can download Arya generated resume</div>;
    if (isSocialResumeGenerationEnabled) return <div>Pull contacts to generate a resume</div>;
    return <div>Resume Not Available</div>;
  };

  const resumeInfoText = getResumeInfoText();

  return (
    <Skeleton
      paragraph={{ rows: 10 }}
      loading={candidateDetailsStatus === 'INPROGRESS' || candidateListStatus === 'INPROGRESS'}
    >
      {resume ? resumeViewer(resume) : resumeInfoText}
    </Skeleton>
  );
}

export default ResumeViewer;
