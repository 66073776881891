import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import ScoutingAgentDrawer from './ScoutingAgentDrawer';
import { ScoutingAgentIcon } from '../../../Icons/AryaIcons';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { getScoutingAgentConfig, getScoutingAgentsofEnabledJobs } from '../../../Reducers/ScoutingAgentReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import './ScoutingAgentInsideIcon.scss';

const mapStateToProps = (state, props) => ({
  activeScoutingAgentCount: getScoutingAgentsofEnabledJobs(state, props.jobId),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
});

const mapDispatchToProps = {
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
  fetchScoutingAgentsByJobIds: ScoutingAgentActions.fetchScoutingAgentsByJobIds,
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
};

const ScoutingAgentInsideIcon = props => {
  const {
    jobId,
    activeScoutingAgentCount,
    fetchJobScoutingAgent,
    setScoutingAgentDrawerVisibility,
    featureToggleList,
    width,
    height,
    fetchScoutingAgentsByJobIds,
    fontSize,
    scoutingAgentConfig,
    history,
    candidateContext = 'jobs',
  } = props;
  const [scoutingIconEnabled, setScoutingIconEnabled] = React.useState(false);

  React.useEffect(() => {
    let isScoutingAgentEnabledJob = false;
    if (activeScoutingAgentCount > 0) {
      isScoutingAgentEnabledJob = true;
    }
    setScoutingIconEnabled(isScoutingAgentEnabledJob);
  }, [activeScoutingAgentCount]);

  React.useEffect(() => {
    if (featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig) fetchScoutingAgentsByJobIds([jobId]);
  }, [featureToggleList.ScoutingAgent.IsEnabled, fetchScoutingAgentsByJobIds, jobId]);

  const defaultIconStyle = {
    width: width ?? 20,
    height: height ?? 20,
    fontSize: fontSize ?? '18px',
  };

  const handleScoutingAgentIconClick = () => {
    if (featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig) fetchJobScoutingAgent(jobId);
    setScoutingAgentDrawerVisibility(true);
  };
  return (
    <>
      <ScoutingAgentDrawer jobId={jobId} history={history} candidateContext={candidateContext} />
      {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && (
        <Tooltip title={scoutingIconEnabled ? 'Scouting Agent is on' : 'Scouting Agent is off'} placement="bottom">
          <div
            className={`scouting-agent-icon ${scoutingIconEnabled && 'active-scouting-agent-icon'}`}
            role="presentation"
            onClick={handleScoutingAgentIconClick}
          >
            {scoutingIconEnabled && <div className={`${'green-circle'}`}></div>}
            <ScoutingAgentIcon
              width={defaultIconStyle.width}
              height={defaultIconStyle.height}
              style={{ fontSize: defaultIconStyle.fontSize }}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoutingAgentInsideIcon));

export { ScoutingAgentInsideIcon as ScoutingAgentInsideIconWithoutStore };
