import React from 'react';
import { Checkbox, Form } from 'antd';
import EmploymentContext from './EmploymentContext';

const PreferredCompanyCheckbox = props => {
  const { isCurrentCompanyInitialValue, isPastCompanyInitialValue } = props;
  const { form, isDisable } = React.useContext(EmploymentContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '14px' }}>
      <Form.Item>
        {form.getFieldDecorator('IsPastCompany', {
          valuePropName: 'checked',
          initialValue: isPastCompanyInitialValue,
        })(<Checkbox disabled={isDisable}>Search in past company</Checkbox>)}
      </Form.Item>
      <Form.Item>
        {form.getFieldDecorator('IsCurrentCompany', {
          valuePropName: 'checked',
          initialValue: isCurrentCompanyInitialValue,
        })(<Checkbox disabled={isDisable}>Search in current company</Checkbox>)}
      </Form.Item>
    </div>
  );
};

export default PreferredCompanyCheckbox;
