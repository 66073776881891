import React from 'react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { BaseNode } from './Base';
import * as WorkflowReportReducer from '../../../Reducers/WorkflowReportReducer';
import * as WorkflowReportAction from '../../../Actions/WorkflowReportActions';

const mapStateToProps = state => {
  return {
    workflowReportEndNodeData: WorkflowReportReducer.getWorkflowReportEndNodesData(state),
    isWorkflowPublished: WorkflowReportReducer.getWorkflowPublishedStatus(state),
    workflowReportFetchApiStatus: WorkflowReportReducer.getWorkflowReportApiStatus(state),
  };
};

const mapDispatchToProps = {
  setWorkflowReportEndNodesData: WorkflowReportAction.setWorkflowReportEndNodesData,
};

const EndNodeTooltip = props => {
  const {
    id,
    isHighlighted,
    workflowReportEndNodeData,
    isCloseIconHidden,
    onCloseIconClick,
    isPreview,
    renderTooltipFlag,
    setWorkflowReportEndNodesData,
    isWorkflowPublished,
    workflowReportFetchApiStatus,
  } = props;
  const [activeInstances, setActiveInstances] = React.useState(0);
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const tooltipText = `${activeInstances} ${activeInstances > 1 ? 'Candidates' : 'Candidate'}`;
  React.useEffect(() => {
    if (id in workflowReportEndNodeData) {
      setActiveInstances(workflowReportEndNodeData[id]);
    }
    if (isWorkflowPublished) setTooltipVisible(true);
  }, [workflowReportEndNodeData]);

  React.useEffect(() => {
    return () => {
      setWorkflowReportEndNodesData({ reinitiate: true });
    };
  }, []);

  React.useEffect(() => {
    if (workflowReportFetchApiStatus) {
      setTooltipVisible(false);
    }
  }, [workflowReportFetchApiStatus]);

  if (isPreview)
    return (
      <Tooltip
        placement="bottom"
        title={tooltipText}
        overlayClassName={`EndNodeTooltip-${id} TooltipNode`}
        visible={renderTooltipFlag && tooltipVisible}
        getTooltipContainer={node => node.parentNode}
      >
        <div></div>
        <BaseNode
          {...props}
          isHighlighted={isHighlighted}
          isCloseIconHidden={isCloseIconHidden}
          onCloseIconClick={onCloseIconClick}
          customClassName="endNode"
        />
      </Tooltip>
    );
  return (
    <BaseNode
      {...props}
      isHighlighted={isHighlighted}
      isCloseIconHidden={isCloseIconHidden}
      onCloseIconClick={onCloseIconClick}
      customClassName="endNode"
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EndNodeTooltip);
export { EndNodeTooltip as EndNodeTooltipWithoutStore };
