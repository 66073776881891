import { apiV3Client } from './BaseRepository';

export function fetchUsers(query = {}, orgId) {
  if (!orgId) {
    return apiV3Client.post('users/_search', {
      UserIds: query.userIds,
      Size: query.size,
      From: query.from,
      SearchTerm: query.searchTerm,
      UserGuids: query.userGuids,
      IsActive: query.isActive,
    });
  }
  return apiV3Client.post(`users/_adminsearch?orgId=${orgId}`, {
    Size: query.size,
    From: query.from,
    SearchTerm: query.searchTerm,
    UserIds: query.userIds,
    IsActive: query.isActive,
  });
}

export function createUnregisteredUser(userInfo) {
  const { FullName, UserGuid, Email, Phone, Company, Street, City, StateCode, PostalCode, CountryCode, Industries } =
    userInfo;
  return apiV3Client.post('users', {
    FullName,
    UserGuid,
    Email,
    Phone,
    Company,
    Street,
    City,
    StateCode,
    PostalCode,
    CountryCode,
    Industries,
  });
}

export function signUp(userInfo) {
  const { Email, Password, CountryCode, FullName, Company, UserSource, PartnerKey, DeviceInfo } = userInfo;
  return apiV3Client.post('users/_signup', {
    Email,
    Password,
    CountryCode,
    FullName,
    Company,
    UserSource,
    PartnerKey,
    DeviceInfo,
  });
}

export function generateMagicLink(userInfo) {
  const { Email } = userInfo;
  return apiV3Client.post(`users/${Email}/_passwordlesslogin`);
}

export function verifyUser(username, verificationCode, email) {
  return apiV3Client.post('users/_verify', {
    Username: username,
    VerificationCode: verificationCode,
    Email: email,
  });
}

export function updateUser(userInfo) {
  return apiV3Client.put('users/_update', {
    OrgName: userInfo.OrgName,
    Street: userInfo.Street,
    City: userInfo.City,
    State: userInfo.State,
    Country: userInfo.Country,
    ZipCode: userInfo.ZipCode,
  });
}

export function patchUpdateUser(userInfo) {
  const updatedUserInfoPayload = Object.keys(userInfo).map(key => ({ op: 'replace', path: key, value: userInfo[key] }));
  return apiV3Client.patch(`users/_update`, updatedUserInfoPayload);
}

export function updatePassword(filter) {
  return apiV3Client.put('users/password', {
    Username: filter.Username,
    Password: filter.Password,
  });
}

export function fetchKnowledgeBaseAuthToken() {
  return apiV3Client.get(`users/_knowledgebasetoken`);
}

export function fetchAdminFeaturetoggles() {
  return apiV3Client.get(`/admin/_features`);
}
