import _ from 'lodash';
import cleanSet from 'clean-set';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { getSourceName } from '../Utils/SourceUtils';
import { getFeatureToggleList } from './FeatureToggleReducer.ts';
import {
  SET_DISTRIBUTION_PORTALS,
  SET_ALL_JOB_DISTRIBUTE_PORTAL_CREDENTIALS,
  SET_JOB_PORTAL_AUTHENTICATE_STATUS,
  SET_JOB_PORTAL_CREDENTIALS,
  DELETE_JOB_PORTAL_CREDENTIALS,
  SET_NOTIFICATION_CONFIG,
  SET_NOTIFICATION_ID_CONFIG,
  SET_NOTIFICATION_TYPE_CONFIG,
  SET_UPDATE_NOTIFICATION_ID_CONFIG_API_STATUS,
  SET_UPDATE_NOTIFICATION_TYPE_CONFIG_API_STATUS,
  SET_ACTIVATE_ALL_NOTIFICATION_TYPE_API_STATUS,
  SET_ALL_ACTIVE_BY_NOTIFICATION_TYPE,
  SET_MASKING_CONFIG,
  SET_ORG_MASKING_CONFIG,
  SET_NAVBAR_RENDER_FLAG,
  SET_CANDIDATE_FILTER_DOWNLOAD_CONFIG,
  SET_CANDIDATE_FILTER_COMMUNICATION_CONFIG,
} from '../Actions/ActionCreators/ConfigActions';

const emptyList = [];
const emptyObject = {};

const initialState = {
  UserConfig: {},
  isOnline: true,
  WhiteLabelInfo: {},
  NotificationConfig: {},
  navbarRenderFlag: true,
};

function isSourceGroupExists(sourcesArray = [], currentSource = {}) {
  return sourcesArray.find(source => source.Group && source.Group === currentSource.Group);
}

function ConfigReducer(state = initialState, action) {
  let newState;
  let authorizedSourceNames;
  let sources;
  let sourceConfigsByName;
  let portalSources; // To display portals in filters & activation dialog
  let vaults;
  let showVaultName = false;
  let newCredentials;
  let sourceName;
  let portalName;
  let groupName;
  let targetSourceConfig;
  let currentPortalCredentials;
  let activeChannelSources; // portals which are configured under active channel
  let source;
  let _source;
  let notificationConfig;
  // let _notificationConfig;
  let notificationSubscription;
  // let _notificationSubscription;
  let notificationConfigByType;
  let notificationConfigTypes;
  let notificationSubscriptionIds;
  let notificationSubscriptionById;
  let notificationSubscriptionId;
  let notificationConfigType;
  let apiStatus;
  let sourcesConfig;

  switch (action.type) {
    case 'SET_CONFIG':
      newState = _.cloneDeep(state);
      newState.UserConfig = action.payload;
      sources = _.cloneDeep(_.get(action, ['payload', 'Sources'], []));
      authorizedSourceNames = [];
      portalSources = [];
      vaults = [];
      sourceConfigsByName = {};
      activeChannelSources = [];
      sourcesConfig = [];

      sources.forEach(sourceConfig => {
        portalName = _.get(sourceConfig, ['Source', 'Portal']);
        groupName = _.get(sourceConfig, ['Source', 'Group']);
        sourceName = portalName || groupName;

        if (sourceName.toLowerCase() === 'monster') {
          if (!sourceConfigsByName[sourceName.toLowerCase()]?.IsAuthorized) {
            sourceConfigsByName[sourceName.toLowerCase()] = sourceConfig;
            sourcesConfig = _.reject(sourcesConfig, { Source: { Portal: 'Monster' } });
            sourcesConfig.push(sourceConfig);
          }
        } else {
          sourceConfigsByName[sourceName.toLowerCase()] = sourceConfig;
          sourcesConfig.push(sourceConfig);
        }

        if (sourceConfig.IsAuthorized && !authorizedSourceNames.includes(sourceName)) {
          authorizedSourceNames.push(sourceName);
          if (groupName && !(authorizedSourceNames || []).includes(groupName)) {
            authorizedSourceNames.push(groupName);
          }
        }
        if (sourceName.toLowerCase() === 'internal' && sourceConfig.Source.Name) {
          source = {
            Portal: sourceName,
            Type: 'Primary',
            Name: sourceConfig.Source.Name,
          };
        } else if (portalName) {
          source = {
            Type: 'Primary',
            Portal: portalName,
          };
        } else if (groupName) {
          source = {
            Type: 'Primary',
            Group: groupName,
          };
        }

        if (portalName && groupName) {
          source.Group = groupName;
          _source = {
            Type: 'Primary',
            Group: groupName,
          };
          if (sourceConfig.Source.Name && !source.Name) source.Name = sourceConfig.Source.Name;
          if (portalName.toLowerCase() !== 'social') activeChannelSources.push(source);
          if (!isSourceGroupExists(portalSources, _source)) portalSources.push(_source);
        } else portalSources.push(source);
        if (source.Name && sourceName.toLowerCase() === 'internal') vaults.push(sourceConfig.Source.Name);
      });

      if (vaults.length > 1) {
        showVaultName = true;
      }
      _.set(newState, ['UserConfig', 'AuthorizedPortals'], _.uniq(authorizedSourceNames));
      _.set(
        newState,
        ['UserConfig', 'Portals'],
        _.uniq(sources.map(sourceConfig => sourceConfig.Source.Group || sourceConfig.Source.Portal))
      );
      portalSources = _.uniqWith(portalSources, _.isEqual);
      _.set(newState, ['UserConfig', 'PortalSources'], portalSources);
      _.set(newState, ['UserConfig', 'Vaults'], vaults);
      _.set(newState, ['UserConfig', 'ShowVaultName'], showVaultName);
      _.set(newState, ['UserConfig', 'SourceConfigsByName'], sourceConfigsByName);
      _.set(newState, ['UserConfig', 'Sources'], sourcesConfig);
      _.set(newState, ['UserConfig', 'ActiveChannelSources'], activeChannelSources);

      newState.UserConfig.IsAryaUser = true;
      return newState;

    case SET_NOTIFICATION_CONFIG:
      newState = _.cloneDeep(state);
      notificationConfig = _.cloneDeep(action.payload ?? []);
      notificationConfigTypes = [];
      notificationConfigByType = {};
      notificationSubscriptionById = {};
      notificationConfig.forEach(config => {
        notificationSubscriptionIds = [];
        (config?.Subscriptions ?? []).forEach(subscription => {
          const id = subscription?.Id;
          notificationSubscriptionIds.push(id);
          _.setWith(notificationSubscriptionById, [id], subscription, Object);
        });
        const type = config?.NotificationType?.toLowerCase();
        notificationConfigTypes.push(type);
        _.setWith(notificationConfigByType, [type], config, Object);
        _.setWith(notificationConfigByType, [type, 'SubscriptionIds'], notificationSubscriptionIds, Object);
      });
      _.setWith(newState, ['NotificationConfig', 'ConfigByType'], notificationConfigByType, Object);
      _.setWith(newState, ['NotificationConfig', 'ConfigTypes'], notificationConfigTypes, Object);
      _.setWith(newState, ['NotificationConfig', 'SubscriptionById'], notificationSubscriptionById, Object);
      return newState;

    case SET_NOTIFICATION_ID_CONFIG:
      newState = _.cloneDeep(state);
      notificationSubscriptionId = action.payload.id;
      notificationSubscription = action.payload.config;
      _.setWith(
        newState,
        ['NotificationConfig', 'SubscriptionById', notificationSubscriptionId, 'IsSubscribed'],
        notificationSubscription?.Subscribe,
        Object
      );
      return newState;

    case SET_NOTIFICATION_TYPE_CONFIG:
      newState = _.cloneDeep(state);
      notificationConfigType = action.payload.type;
      notificationConfig = action.payload.config;
      _.setWith(
        newState,
        ['NotificationConfig', 'ConfigByType', notificationConfigType, 'IsActive'],
        notificationConfig?.Activate,
        Object
      );
      return newState;

    case SET_ALL_ACTIVE_BY_NOTIFICATION_TYPE:
      newState = _.cloneDeep(state);
      notificationConfigType = action.payload.type;
      notificationConfig = action.payload.config;
      notificationSubscriptionIds =
        newState?.NotificationConfig?.ConfigByType?.[notificationConfigType]?.SubscriptionIds ?? [];
      notificationSubscriptionIds.forEach(subscriptionId => {
        _.setWith(
          newState,
          ['NotificationConfig', 'SubscriptionById', subscriptionId, 'IsSubscribed'],
          notificationConfig?.SubscribeAll,
          Object
        );
      });
      return newState;

    case SET_UPDATE_NOTIFICATION_ID_CONFIG_API_STATUS:
      newState = _.cloneDeep(state);
      notificationSubscriptionId = action.payload.id;
      apiStatus = action.payload.status;
      _.setWith(
        newState,
        ['NotificationConfig', 'SubscriptionById', notificationSubscriptionId, 'apiStatus'],
        apiStatus,
        Object
      );
      return newState;

    case SET_UPDATE_NOTIFICATION_TYPE_CONFIG_API_STATUS:
      newState = _.cloneDeep(state);
      notificationConfigType = action.payload.type;
      apiStatus = action.payload.status;
      _.setWith(
        newState,
        ['NotificationConfig', 'ConfigByType', notificationConfigType, 'apiStatus'],
        apiStatus,
        Object
      );
      return newState;

    case SET_ACTIVATE_ALL_NOTIFICATION_TYPE_API_STATUS:
      newState = _.cloneDeep(state);
      notificationConfigType = action.payload.type;
      apiStatus = action.payload.status;
      _.setWith(
        newState,
        ['NotificationConfig', 'ConfigByType', notificationConfigType, 'activateAllApiStatus'],
        apiStatus,
        Object
      );
      return newState;

    case 'SET_SOURCE_AUTHORIZATION':
      newState = _.clone(state);
      sources = _.get(newState, ['UserConfig', 'Sources'], []);
      targetSourceConfig = _.get(action, ['payload', 'sourceDetails'], {});
      sources = sources.map(sourceConfig => {
        source = _.get(sourceConfig, ['Source']);
        const newSourceConfig = _.cloneDeep(sourceConfig);
        sourceName = getSourceName(source);
        if (sourceName.toLowerCase() === getSourceName(targetSourceConfig.Source).toLowerCase()) {
          _.set(newSourceConfig, ['IsAuthorized'], targetSourceConfig.IsAuthorized);
        }
        return newSourceConfig;
      });
      _.set(newState, ['UserConfig', 'Sources'], sources);
      return newState;

    case 'SET_CONNECT_CONFIG':
      newState = _.cloneDeep(state);
      if (!newState.UserConnectConfig) {
        newState.UserConnectConfig = {};
      }
      newState.UserConnectConfig = {
        ...newState.UserConnectConfig,
        ...action.payload,
      };
      return newState;
    case 'SET_CONNECT_CONSENT_CONFIG':
      newState = _.cloneDeep(state);
      newState.UserConnectConfig = {
        ...newState.UserConnectConfig,
        ...action.payload,
      };
      return newState;
    case 'UPDATE_CONFIG':
      newState = _.cloneDeep(state);
      Object.keys(action.payload).forEach(key => {
        newState.UserConfig[key] = _.cloneDeep(action.payload[key]);
      });
      return newState;
    case 'SET_USER_AUTHORIZATION':
      newState = _.cloneDeep(state);
      if (!newState.UserConfig) {
        newState.UserConfig = {};
      }
      newState.UserConfig.IsAryaUser = action.payload.IsAryaUser;
      return newState;
    case 'SET_ORG_ACTIVATED_JOBS_COUNT':
      newState = _.cloneDeep(state);
      if (!newState.UserConfig) {
        newState.UserConfig = {};
      }
      newState.UserConfig.OrgActivatedJobsCount = action.payload.count || 0;
      return newState;
    case 'SET_ALL_PORTAL_CREDENTIALS':
      newState = _.cloneDeep(state);
      if (!newState.PortalSettings) {
        newState.PortalSettings = {};
      }
      action.payload.forEach(credentialDetails => {
        if (!newState.PortalSettings[credentialDetails.Portal]) {
          newState.PortalSettings[credentialDetails.Portal] = {};
        }
        const newCredentialDetails = _.cloneDeep(credentialDetails);
        if (newCredentialDetails.Portal !== 'Internal' && newCredentialDetails.Portal !== 'Social') {
          newCredentialDetails.PortalCredentials = (newCredentialDetails.PortalAuthenticationOptions || []).map(
            credential => {
              if (credential.CountryCode === undefined)
                return {
                  ...credential,
                  CountryCode: 'All',
                };
              return credential;
            }
          );
        }
        newState.PortalSettings[credentialDetails.Portal].PortalCredentials = newCredentialDetails.PortalCredentials;
      });
      return newState;
    case SET_ALL_JOB_DISTRIBUTE_PORTAL_CREDENTIALS:
      newState = _.cloneDeep(state);
      action.payload.forEach(credentialDetails => {
        const newCredentialDetails = _.cloneDeep(credentialDetails);
        newCredentialDetails.PortalCredentials = (newCredentialDetails.PortalAuthenticationOptions || []).map(
          credential => {
            if (credential.CountryCode === undefined)
              return {
                ...credential,
                CountryCode: 'All',
              };
            return credential;
          }
        );
        _.set(
          newState,
          ['JobPortalSettings', [credentialDetails.Portal], 'PortalCredentials'],
          newCredentialDetails.PortalCredentials
        );
      });
      return newState;
    case 'SET_ALL_PORTAL_COUNTRIES':
      newState = _.cloneDeep(state);
      if (!newState.PortalCountries) {
        newState.PortalCountries = {};
      }
      action.payload.forEach(countryDetails => {
        if (!newState.PortalCountries[countryDetails.Portal]) {
          newState.PortalCountries[countryDetails.Portal] = {};
        }
        newState.PortalCountries[countryDetails.Portal].Countries = countryDetails.Countries;
      });
      return newState;
    case 'SET_PORTAL_CREDENTIALS':
      newState = _.cloneDeep(state);
      newCredentials = action.payload.credentials;
      if (newCredentials.CountryCode === undefined) {
        newCredentials = {
          ...newCredentials,
          CountryCode: 'All',
        };
      }
      currentPortalCredentials = newState.PortalSettings[action.payload.portal].PortalCredentials;
      if (!currentPortalCredentials) {
        newState.PortalSettings[action.payload.portal] = { PortalCredentials: [] };
      }
      newState.PortalSettings[action.payload.portal].PortalCredentials.push(newCredentials);
      return newState;
    case SET_JOB_PORTAL_CREDENTIALS:
      newState = _.cloneDeep(state);
      newCredentials = action.payload.credentials;
      if (newCredentials.CountryCode === undefined) {
        newCredentials = {
          ...newCredentials,
          CountryCode: 'All',
        };
      }
      currentPortalCredentials = newState.JobPortalSettings[action.payload.portal].PortalCredentials ?? [];
      newState.JobPortalSettings[action.payload.portal].PortalCredentials = [
        ...currentPortalCredentials,
        newCredentials,
      ];
      return newState;
    case 'SET_WHITE_LABEL_DATA':
      return {
        ...state,
        WhiteLabelInfo: action.payload,
      };
    case 'SET_APP_CONFIGURATION':
      return {
        ...state,
        AppConfiguration: action.payload,
      };
    case 'DELETE_PORTAL_CREDENTIALS':
      newState = _.cloneDeep(state);
      newState.PortalSettings[action.payload.portal].PortalCredentials = newState.PortalSettings[
        action.payload.portal
      ].PortalCredentials.filter(account => account.CountryCode !== action.payload.countryCode);
      return newState;
    case DELETE_JOB_PORTAL_CREDENTIALS:
      newState = _.cloneDeep(state);
      newState.JobPortalSettings[action.payload.portal].PortalCredentials = newState.JobPortalSettings[
        action.payload.portal
      ].PortalCredentials.filter(account => account.CountryCode !== action.payload.countryCode);
      return newState;
    case 'SET_EMAIL_LIMITS_FOR_PORTAL':
      newState = _.cloneDeep(state);
      if (!_.get(newState, 'PortalSettings', false)) {
        newState.PortalSettings = {};
      }
      newState.PortalSettings[action.payload.portal] = {
        EmailLimits: action.payload.emailLimits,
      };
      return newState;
    case 'UPDATE_EMAIL_LIMITS_FOR_PORTAL':
      newState = _.cloneDeep(state);
      Object.keys(action.payload.emailLimits).forEach(emailLimitType => {
        newState.PortalSettings[action.payload.portal].EmailLimits[emailLimitType] =
          action.payload.emailLimits[emailLimitType];
      });
      return newState;
    case 'SET_AUTHENTICATE_API_STATUS': {
      newState = _.cloneDeep(state);
      if (!newState.PortalSettings[action.payload.portal]) {
        newState.PortalSettings[action.payload.portal] = {};
      }
      const currentStatus = newState.PortalSettings[action.payload.portal].AuthenticationApiStatus || {};
      newState.PortalSettings[action.payload.portal].AuthenticationApiStatus = {
        ...currentStatus,
        ...action.payload.status,
      };
      return newState;
    }
    case SET_JOB_PORTAL_AUTHENTICATE_STATUS: {
      const currentStatus = newState?.JobPortalSettings?.[action.payload.portal]?.AuthenticationApiStatus || {};
      const newStatus = { ...currentStatus, ...action.payload.status };
      newState = cleanSet(state, ['JobPortalSettings', action.payload.portal, 'AuthenticationApiStatus'], newStatus);
      return newState;
    }
    case 'UPDATE_CONSENT_CONFIG': {
      newState = cleanSet(state, ['UserConnectConfig', 'IsContactViewAllowed'], action.payload);
      return newState;
    }
    case 'FETCH_CONFIG_API_STATUS': {
      return {
        ...state,
        configApiStatus: action.payload,
      };
    }
    case SET_DISTRIBUTION_PORTALS: {
      return {
        ...state,
        JobDistributionPortals: action.payload,
      };
    }
    case SET_CANDIDATE_FILTER_DOWNLOAD_CONFIG: {
      return {
        ...state,
        candidateDownloadFilterConfig: action.payload,
      };
    }
    case SET_CANDIDATE_FILTER_COMMUNICATION_CONFIG: {
      return {
        ...state,
        candidateCommunicationFilterConfig: action.payload,
      };
    }
    case SET_MASKING_CONFIG: {
      return {
        ...state,
        MaskingConfig: action.payload,
      };
    }
    case SET_ORG_MASKING_CONFIG: {
      return {
        ...state,
        OrgMaskingConfig: action.payload,
      };
    }
    case SET_NAVBAR_RENDER_FLAG: {
      return {
        ...state,
        navbarRenderFlag: action.payload,
      };
    }
    case 'SET_ORG_OPENED_JOBS_COUNT':
      newState = _.cloneDeep(state);
      if (!newState.UserConfig) {
        newState.UserConfig = {};
      }
      newState.UserConfig.OrgOpenedJobsCount = action.payload.count || 0;
      return newState;
    default:
      return state;
  }
}
ConfigReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getConfig(state) {
  return state?.ConfigReducer?.UserConfig ?? emptyObject;
}

const getConfiguredSources = createSelector(getConfig, getFeatureToggleList, (config, featureToggleList) => {
  if (featureToggleList.RevealPortalsUnderGroup.IsEnabled) {
    return (config.PortalSources ?? []).concat(config?.ActiveChannelSources ?? []);
  }
  return config.PortalSources ?? [];
});

function getNotificationConfig(state) {
  return state?.ConfigReducer?.NotificationConfig ?? emptyObject;
}

function getJobDistributionPortals(state) {
  return state?.ConfigReducer?.JobDistributionPortals ?? emptyList;
}
function getcandidateDownloadFilterConfig(state) {
  return state?.ConfigReducer?.candidateDownloadFilterConfig;
}
function getcandidateCommunicationFilterConfig(state) {
  return state?.ConfigReducer?.candidateCommunicationFilterConfig;
}
function getConnectConfig(state) {
  return state.ConfigReducer.UserConnectConfig || emptyObject;
}

function getPortalsConfig(state) {
  return state.ConfigReducer.PortalSettings ?? emptyObject;
}

function getJobPortalsConfig(state) {
  return state.ConfigReducer.JobPortalSettings;
}

function getPortalCountries(state) {
  return state.ConfigReducer.PortalCountries;
}

function getVaults(state) {
  return _.get(state, ['ConfigReducer', 'UserConfig', 'Vaults'], emptyList);
}

function getFetchUserConfigApiStatus(state) {
  return _.get(state, ['ConfigReducer', 'configApiStatus']);
}

function getWhiteLabelInformation(state) {
  return _.get(state, ['ConfigReducer', 'WhiteLabelInfo'], emptyObject);
}

function getAppConfiguration(state) {
  return state?.ConfigReducer?.AppConfiguration || emptyObject;
}

function getMaskingConfig(state) {
  return state?.ConfigReducer?.MaskingConfig;
}

function getOrgMaskingConfig(state) {
  return state?.ConfigReducer?.OrgMaskingConfig;
}

function getNavbarRenderFlag(state) {
  return _.get(state, ['ConfigReducer', 'navbarRenderFlag'], true);
}

export {
  ConfigReducer,
  getConfig,
  getPortalsConfig,
  getJobPortalsConfig,
  getConnectConfig,
  getPortalCountries,
  getVaults,
  getFetchUserConfigApiStatus,
  getWhiteLabelInformation,
  getAppConfiguration,
  getJobDistributionPortals,
  getNotificationConfig,
  getMaskingConfig,
  getOrgMaskingConfig,
  getNavbarRenderFlag,
  getcandidateDownloadFilterConfig,
  getcandidateCommunicationFilterConfig,
  getConfiguredSources,
};
