import React from 'react';
import { Button, Tooltip } from 'antd';
import { getTooltipContentForDisabledLocation } from '../../../Utils/MultiLocationUtils';
import MultiLocationContext from '../MultiLocationContext';

const EnhancedAddLocationButton = props => {
  const { isRemoteJobChecked, locationContext, isFirstELement, currentLocation } = props;
  const {
    isLocationSelectionLimitReached,
    addLocationEnhanced,
    isCountryWideSearchEnabledCountry,
    isRegionDisabled,
    milesInputValidationError,
  } = React.useContext(MultiLocationContext);
  const isDisable =
    isLocationSelectionLimitReached ||
    isRemoteJobChecked ||
    isCountryWideSearchEnabledCountry ||
    (isRegionDisabled && locationContext === 'Region') ||
    milesInputValidationError;

  const isAddLocationDisabled =
    isLocationSelectionLimitReached ||
    isDisable ||
    (locationContext === 'Region' ? !currentLocation.Region : !currentLocation.Location);

  const onAddLocation = () => {
    !isAddLocationDisabled && addLocationEnhanced();
  };

  return isAddLocationDisabled ? (
    <Tooltip
      title={getTooltipContentForDisabledLocation({
        isLocationSelectionLimitReached,
        isCountryWideSearchEnabledCountry,
      })}
    >
      <Button
        type="link"
        onClick={onAddLocation}
        style={{
          cursor: isAddLocationDisabled ? 'not-allowed' : '',
          color: isAddLocationDisabled ? '#51575e' : '',
        }}
      >
        Add Location
      </Button>
    </Tooltip>
  ) : (
    <Button
      type="link"
      onClick={onAddLocation}
      style={{ cursor: isRemoteJobChecked ? 'not-allowed' : '', color: isRemoteJobChecked ? '#51575e' : '' }}
      disabled={milesInputValidationError}
    >
      {isFirstELement ? 'Add Location' : 'Add More Location'}
    </Button>
  );
};

export default EnhancedAddLocationButton;
