import _ from 'lodash';
import {
  fetchUsers as fetchUsersFromRepo,
  createUnregisteredUser as createUnregisteredUserFromRepo,
  updateUser,
  updatePassword,
  generateMagicLink as generateMagicLinkFromRepo,
  fetchAdminFeaturetoggles,
} from '../Repository/UserRepository';
import * as ConnectUserRepository from '../Repository/ConnectUserRepository';
import * as jobRepository from '../Repository/JobRepository';
import {
  setUpdateUserDetailsApiStatus,
  setUpdatedUserDetails,
  setUserDetailsApiStatus,
  LINK_SENT_SUCCESSFULLY_TEXT,
} from './ActionCreators/UserActionCreator';
import { setNotification } from './ActionCreators/ConnectActions';
import { getCurrentUser } from '../Reducers/UserSessionReducer';

function fetchConnectUsers(query) {
  return dispatch => {
    ConnectUserRepository.fetchConnectUsers(query)
      .then(response => {
        dispatch({
          type: 'SET_CONNECT_USERS',
          payload: response.data.Users,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchUsers(filter) {
  return async dispatch => {
    return fetchUsersFromRepo(filter)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_USERS',
          payload,
        });
        dispatch({
          type: 'SET_USERS_BY_GUID',
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchUsersForDropdownLists(filter, orgId = null) {
  return dispatch => {
    dispatch(setUserDetailsApiStatus('PENDING'));
    fetchUsersFromRepo(
      {
        from: (filter?.page - 1) * 10,
        size: 10,
        searchTerm: filter?.searchTerm,
        isActive: filter?.isActive,
        userIds: filter?.userIds,
      },
      orgId
    ).then(response => {
      dispatch({
        type: 'SET_USERS',
        payload: response.data,
      });
      dispatch({
        type: 'SET_USERS_LIST_FOR_DROPDOWNS',
        payload: {
          users: response.data.Users,
          searchTerm: filter?.searchTerm || '',
          page: filter?.page,
          listFor: filter?.listFor,
          orgId,
        },
      });
      if (!filter?.searchTerm)
        dispatch({
          type: 'SET_TOTAL_USERS_COUNT',
          payload: { count: response.data.Total },
        });
      dispatch({
        type: 'SET_MAX_COUNT',
        payload: response.data.Total,
      });
      dispatch(setUserDetailsApiStatus('SUCCESS'));
    });
  };
}

function setCurrentUserTryNowFlag(isTryNowUser) {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    const currentUserGuid = currentUser?.sub;
    if (currentUserGuid) {
      dispatch({
        type: 'SET_CURRENT_USER_TRYNOW_FLAG',
        payload: {
          isTryNowUser,
          currentUserGuid,
        },
      });
    }
  };
}

function fetchUsersForShareTemplate(page, searchTerm, orgId = null) {
  return dispatch => {
    ConnectUserRepository.fetchConnectUsers(
      {
        from: page - 1,
        size: 1000,
        searchTerm,
      },
      orgId
    ).then(response => {
      dispatch({
        type: 'SET_GUID_USERS',
        payload: response.data,
      });
      dispatch({
        type: 'SET_GUID_USERS_LIST_FOR_DROPDOWNS',
        payload: {
          users: response.data.Users,
          searchTerm: searchTerm || '',
          page,
          orgId,
        },
      });
      if (!searchTerm)
        dispatch({
          type: 'SET_CONNECT_MAX_COUNT',
          payload: response.data.Total,
        });
    });
  };
}

function clearDropdownLists(listFor = 'ShareJob') {
  return dispatch => {
    dispatch({
      type: 'RESET_USERS_LIST_FOR_DROPDOWNS',
      payload: {
        type: listFor,
      },
    });
  };
}

function createUnregisteredUser(userInfo) {
  return dispatch => {
    dispatch({ type: 'CREATE_UNREGISTERED_USER_API_STATUS', payload: 'INPROGRESS' });
    createUnregisteredUserFromRepo(userInfo)
      .then(() => {
        dispatch({ type: 'CREATE_UNREGISTERED_USER_API_STATUS', payload: 'SUCCESS' });
      })
      .catch(() => {
        dispatch({ type: 'CREATE_UNREGISTERED_USER_API_STATUS', payload: 'FAILED' });
      });
  };
}

function generateMagicLink(userInfo) {
  return dispatch => {
    dispatch({ type: 'GENERATE_MAGIC_LINK_API_STATUS', payload: 'INPROGRESS' });
    generateMagicLinkFromRepo(userInfo)
      .then(() => {
        dispatch(setNotification('SUCCESS', LINK_SENT_SUCCESSFULLY_TEXT));
        dispatch({ type: 'GENERATE_MAGIC_LINK_API_STATUS', payload: 'SUCCESS' });
      })
      .catch(errorResponse => {
        let error = 'Oops, something just went wrong';
        let statusType = 'FAILED';
        const { Email } = userInfo;
        if (_.get(errorResponse, ['response', 'data', 'Error', 'Code'], null) === 'USER_NOT_FOUND') {
          error = `user with email ${Email} doesn't exists in our system, Please SignUp`;
          statusType = 'USER_NOT_FOUND';
        }
        dispatch({ type: 'GENERATE_MAGIC_LINK_API_STATUS', payload: statusType });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: error,
          },
        });
      });
  };
}

function clearGenerateMagicLinkApiStatus() {
  return dispatch => {
    dispatch({
      type: 'GENERATE_MAGIC_LINK_API_STATUS',
      payload: null,
    });
  };
}

function updateUserPassword(filter) {
  return dispatch => {
    dispatch({ type: 'USER_PASSWORD_UPDATE_API_STATUS', payload: 'INPROGRESS' });
    updatePassword(filter)
      .then(() => {
        dispatch(setNotification('SUCCESS', 'Saved successfully.'));
        dispatch({ type: 'USER_PASSWORD_UPDATE_API_STATUS', payload: 'SUCCESS' });
      })
      .catch(() => {
        dispatch({ type: 'USER_PASSWORD_UPDATE_API_STATUS', payload: 'FAILED' });
      });
  };
}

function updateUserDetails(userInfo) {
  return async dispatch => {
    dispatch(setUpdateUserDetailsApiStatus('INPROGRESS'));
    try {
      const response = await updateUser(userInfo);
      const { data } = response;
      dispatch(setNotification('SUCCESS', 'Saved successfully.'));
      dispatch(setUpdatedUserDetails({ Users: [data] }));
      dispatch(setUpdateUserDetailsApiStatus('SUCCESS'));
    } catch {
      dispatch(setUpdateUserDetailsApiStatus('FAILED'));
    }
  };
}

function fetchZipCodes(filter) {
  return async dispatch => {
    let response;
    let payload;
    dispatch({
      type: 'SET_USER_ZIP_CODES_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      response = await jobRepository.getZipCodes(filter);
      payload = response.data;
      dispatch({
        type: 'SET_USER_ZIP_CODES',
        payload,
      });
      dispatch({
        type: 'SET_USER_ZIP_CODES_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'SET_USER_ZIP_CODES_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
}

function fetchLocations(filter) {
  return async dispatch => {
    let response;
    let payload;
    dispatch({
      type: 'FETCH_USER_LOCATION_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      response = await jobRepository.getLocations(filter);
      payload = response.data;
      dispatch({
        type: 'SET_USER_LOCATIONS',
        payload,
      });
      dispatch({
        type: 'SET_USER_ZIP_CODE_STATUS',
        payload: false,
      });
      dispatch({
        type: 'FETCH_USER_LOCATION_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'FETCH_USER_LOCATION_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
}

const fetchScoutingAgentUsers = (filter = {}, orgId = null) => {
  const updatedFilter = {
    ...filter,
    from: 0,
    size: filter.userIds?.length || 10,
    searchTerm: filter.searchTerm,
    isActive: filter.isActive,
    userIds: filter.userIds || [],
  };
  return async () => {
    try {
      const response = await fetchUsersFromRepo(updatedFilter, orgId);
      return response.data;
    } catch (error) {
      return {};
    }
  };
};

function getAdminFeaturetoggles() {
  return async dispatch => {
    return fetchAdminFeaturetoggles()
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_ADMIN_FEATURES',
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
      });
  };
}

export {
  fetchUsers,
  fetchUsersForDropdownLists,
  fetchUsersForShareTemplate,
  fetchConnectUsers,
  clearDropdownLists,
  createUnregisteredUser,
  updateUserDetails,
  fetchLocations,
  fetchZipCodes,
  updateUserPassword,
  generateMagicLink,
  clearGenerateMagicLinkApiStatus,
  setCurrentUserTryNowFlag,
  fetchScoutingAgentUsers,
  getAdminFeaturetoggles,
};
