/* eslint-disable no-param-reassign */
import { setNotification } from './ActionCreators/ConnectActions';
import * as actionCreators from './ActionCreators/WorkflowWindowActionCreator';
import * as workflowWindowRepository from '../Repository/WorkflowWindowRepository';
import * as workflowRepository from '../Repository/WorkflowRepository';

import { setApiStatus } from './ApiStatusActions';

export function setCandidateWorkflowHistory(jobGuid, candidateId) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'candidateWorkflowHistoryApiStatus', status: 'INPROGRESS' }));
      const response = await workflowWindowRepository.getWorkflowHistory(jobGuid, candidateId);
      dispatch(actionCreators.setWorkflowWindowhistory(candidateId, response.data));
      dispatch(setApiStatus({ apiName: 'candidateWorkflowHistoryApiStatus', status: 'COMPLETED' }));
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'candidateWorkflowHistoryApiStatus', status: 'FAILED' }));
    }
  };
}

export function startCandidateWorkflow(startWorkflowData, updateWorkflowData) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'startWorkflowWindowApiStatus', status: 'INPROGRESS' }));
      if (updateWorkflowData) {
        const savedWorkflowRespose = await workflowRepository.saveWorkflowTemplate(updateWorkflowData);
        const { DefinitionId: definitionId, Name: definitionName } = savedWorkflowRespose.data;
        startWorkflowData.DefinitionId = definitionId;
        startWorkflowData.DefinitionName = definitionName;
      }
      await workflowWindowRepository.startWorkflow(startWorkflowData);
      dispatch(setApiStatus({ apiName: 'startWorkflowWindowApiStatus', status: 'COMPLETED' }));
      dispatch(setNotification('SUCCESS', 'Workflow has been initiated for candidate'));
    } catch (e) {
      if (e.response.status === 406) {
        dispatch(setNotification('ERROR', 'Workflow is already initiated for candidate'));
      } else if (e.response.status === 409) {
        dispatch(
          setNotification(
            'ERROR',
            `Workflow with name ${updateWorkflowData.Name} already exists. Please give a different name.`
          )
        );
      } else {
        dispatch(setNotification('ERROR', 'Error occured while trying to initiate workflow for candidate'));
      }
      dispatch(setApiStatus({ apiName: 'startWorkflowWindowApiStatus', status: 'FAILED' }));
    }
  };
}

export function stopCandidateWorkflow(jobGuid, candidateId) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'stopWorkflowApiStatus', status: 'INPROGRESS' }));
      await workflowWindowRepository.stopWorkflow(jobGuid, candidateId);
      dispatch(setApiStatus({ apiName: 'stopWorkflowApiStatus', status: 'COMPLETED' }));
      dispatch(setNotification('SUCCESS', 'Workflow has been stopped for candidate'));
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'stopWorkflowApiStatus', status: 'FAILED' }));
    }
  };
}
