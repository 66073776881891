import React from 'react';
import { Icon } from 'antd';
import styles from './ScoringInProgressMessage.module.scss';

export default function ScoringInProgressMessage() {
  return (
    <div className={styles.BannerMessageWrapper}>
      {' '}
      <Icon className={styles.loadingIcon} type="loading" spin />
      Scoring and ranking in progress
    </div>
  );
}
