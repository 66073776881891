import React from 'react';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Icon } from 'antd';
import CandidateAdvanceFilterFields from './CandidateAdvanceFilterFields';
import CandidateAdvanceFilterOperators from './CandidateAdvanceFilterOperators';
import CandidateAdvanceFilterValues from './CandidateAdvanceFilterValues';
import * as autocompleteActions from '../../../../Actions/AutocompleteActions';
import * as locationSearchActions from '../../../../Actions/LocationSearchActions';
import { getCandidateAdvanceFilterPortals } from '../../../../Reducers/CandidateAdvanceFiltersReducer';
import * as CandidateAdvanceFilterActions from '../../../../Actions/CandidateAdvanceFiltersActions';
import styles from './CandidateAdvanceFilters.module.scss';
import {
  handleCampaignSearchOptions,
  handleIndustryList,
  handleGlobalOptions,
  handleOperatorsOptions,
  handleLocationOptions,
  handleTemplateSearchOptions,
} from '../../../../Utils/AdvanceFiltersJsonGenerationUtils';

const mapDispatchToProps = {
  fetchJobTitleAutocomplete: autocompleteActions.fetchJobTitleAutocomplete,
  fetchSkillSuggestions: autocompleteActions.fetchSkillSuggestions,
  fetchCampaignTitleSuggestions: CandidateAdvanceFilterActions.fetchCampaignTitleSuggestions,
  fetchEmails: CandidateAdvanceFilterActions.fetchEmails,
  fetchLocations: locationSearchActions._fetchCityAndStateSuggestions,
  fetchCandidateAdvanceFilterIndustries: CandidateAdvanceFilterActions.fetchCandidateAdvanceFilterIndustries,
};

const mapStateToProps = state => ({
  portals: getCandidateAdvanceFilterPortals(state),
});

let fieldId;

export const handleGlobalFunction = async props => {
  const { nextValue, fetchLocations, attributeId } = props;
  let response;
  switch (attributeId) {
    case 11:
      response = await CandidateAdvanceFilterActions.fetchCampaignTitleSuggestions({ searchTerm: nextValue })();
      return handleCampaignSearchOptions(response?.Campaigns);
    case 10:
      response = await CandidateAdvanceFilterActions.fetchCandidateAdvanceFilterIndustries({ searchTerm: nextValue })();
      return handleIndustryList(response);
    case 13:
      response = await CandidateAdvanceFilterActions.fetchEmails(nextValue);
      return handleTemplateSearchOptions(response.Templates);
    case 3:
      response = await autocompleteActions.fetchSkillSuggestions({ searchTerm: nextValue })();
      return _.uniqBy(
        (response?.Skills ?? []).map(skillData => ({ value: skillData.Skill, text: skillData.Skill })),
        'value'
      );
    case 5:
      response = await fetchLocations({ country: 'US', searchTerm: nextValue });
      return handleLocationOptions(response);
    case 4:
      response = await autocompleteActions.fetchJobTitleAutocomplete({ from: 0, size: 10, title: nextValue })();
      return response?.titles?.map(title => ({ value: title, text: title }));
    default:
      return [];
  }
};

function CandidateAdvanceFilters(props) {
  const {
    filterGroupId,
    handleFilterGroupItemChange,
    FilterItems,
    filterGroupList,
    filterFields,
    fetchLocations,
    portals,
    isClearButtonClicked,
    setshowAddFilterGroupButton,
    setFilterGroupList,
    setShowAddFilterGroupDeleteButton,
    component,
    updateApplyButtonState,
    shortlistedCount,
  } = props;
  const [inputList, setInputList] = React.useState(filterGroupList[filterGroupId].FilterItems);
  const [showAddFilterButton, setshowAddFilterButton] = React.useState(true);
  const [options, setOptions] = React.useState(undefined);
  const [titleInput, setTitleInput] = React.useState(undefined);
  const [globalApiStatus, setGloblaApiStatus] = React.useState();
  const [isLocationContentVisible, setIsLocationContentVisible] = React.useState(false);

  React.useEffect(() => {
    setInputList(FilterItems);
  }, [FilterItems]);

  React.useEffect(() => {
    if (isClearButtonClicked && inputList?.length > 0) {
      setInputList([]);
      setshowAddFilterButton(true);
      setshowAddFilterGroupButton(false);
      setShowAddFilterGroupDeleteButton(1);
      setFilterGroupList([
        {
          FilterItems: [],
        },
      ]);
    }
    if (inputList && !isClearButtonClicked) {
      handleFilterButtonDisplay(inputList);
    }
  }, [inputList, isClearButtonClicked]);

  const handleAddFilterItem = () => {
    const newList = [...inputList, {}];
    setTitleInput(undefined);
    setOptions(undefined);
    setInputList(newList);
    handleFilterButtonDisplay(newList);
    handleFilterGroupItemChange(filterGroupId, newList);
  };

  const handleRemoveFilterItem = index => {
    if (inputList[index]?.AttributeId === 8) {
      setIsLocationContentVisible(true);
    }
    const newList = inputList.filter((item, currentIndex) => currentIndex !== index);
    setInputList(newList);
    handleFilterGroupItemChange(filterGroupId, newList);
  };

  const addFilterField = (index, value) => {
    fieldId = value.props.AttributeId;
    const defaultOptions = value.props.QueryOperator?.split(',');
    const clonedFilters = _.cloneDeep(inputList);
    if (clonedFilters[index]) {
      const [firstOption] = defaultOptions;
      clonedFilters[index].AttributeName = value.props.value;
      clonedFilters[index].QueryOperator = value.props.QueryOperator;
      clonedFilters[index].Type = value.key;
      clonedFilters[index].DataType = value.props.DataType;
      clonedFilters[index].AttributeId = value.props.AttributeId;
      clonedFilters[index].IsRepetitive = value.props.IsRepetitive;
      clonedFilters[index].DisplayedValue = undefined;
      clonedFilters[index].Operator = firstOption || undefined;
      clonedFilters[index].AttributeValue = undefined;
      if (value.props.AttributeId === 5) {
        setIsLocationContentVisible(true);
      }
      if (value.props.AttributeId === 8) {
        setIsLocationContentVisible(false);
      }
      setTitleInput(undefined);
      setOptions(undefined);
      setInputList(clonedFilters);
      handleFilterGroupItemChange(filterGroupId, clonedFilters);
    }
  };

  const addFilterOperator = (index, value) => {
    const clonedFilters = _.cloneDeep(inputList);
    if (clonedFilters[index]) {
      clonedFilters[index].Operator = value;
      setInputList(clonedFilters);
      handleFilterGroupItemChange(filterGroupId, clonedFilters);
    }
  };

  const handleSegmentFiltersComponent = index => {
    if (inputList[index].AttributeId === 10) {
      const notFoundContent = getContentNotFound(globalApiStatus);
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          options={options}
          inputList={inputList}
          handleSearch={debounceFetchSuggestedTags}
          notFoundContent={notFoundContent}
          attributeId={inputList[index]?.AttributeId}
          mode={inputList[index]?.Type === 'Multi' ? 'multiple' : 'combobox'}
        />
      );
    }
    if (inputList[index]?.AttributeId === 5) {
      const notFoundContent = getContentNotFound(globalApiStatus);
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          handleSearch={debounceFetchSuggestedTags}
          notFoundContent={notFoundContent}
          attributeId={inputList[index]?.AttributeId}
          options={options}
          inputList={inputList}
          mode={inputList[index]?.Type === 'Multi' ? 'multiple' : 'combobox'}
          isLocationContentVisible={isLocationContentVisible}
        />
      );
    }
    if (inputList[index].DataType === 'Bit') {
      const options = [
        { value: 'True', text: 'True' },
        { value: 'False', text: 'False' },
      ];
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          options={options}
          inputList={inputList}
          attributeId={inputList[index]?.AttributeId}
          mode="combobox"
        />
      );
    }
    if (inputList[index].DataType === 'Int' || inputList[index].DataType === 'Decimal') {
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          options={options}
          inputList={inputList}
          attributeId={inputList[index]?.AttributeId}
          updateApplyButtonState={updateApplyButtonState}
          shortlistedCount={shortlistedCount}
          feildType="Number"
        />
      );
    }
    if (inputList[index].DataType === 'DateTime') {
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          options={options}
          inputList={inputList}
          attributeId={inputList[index]?.AttributeId}
          feildType="Date"
        />
      );
    }
    if (inputList[index].Type === 'Single') {
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          options={options}
          handleSearch={debounceFetchSuggestedTags}
          inputList={inputList}
          attributeId={inputList[index]?.AttributeId}
          mode="combobox"
        />
      );
    }
    if (inputList[index]?.AttributeId === 9) {
      return (
        <CandidateAdvanceFilterValues
          filterId={index}
          addFilterValues={addFilterValues}
          options={handleGlobalOptions(portals?.portals)}
          inputList={inputList}
          mode={inputList[index]?.Type === 'Multi' ? 'multiple' : 'combobox'}
          attributeId={inputList[index]?.AttributeId}
        />
      );
    }
    const notFoundContent = getContentNotFound(globalApiStatus);
    return (
      <CandidateAdvanceFilterValues
        filterId={index}
        addFilterValues={addFilterValues}
        handleSearch={debounceFetchSuggestedTags}
        notFoundContent={notFoundContent}
        options={options}
        inputList={inputList}
        attributeId={inputList[index]?.AttributeId}
        mode="multiple"
      />
    );
  };

  const addFilterValues = (index, value, selectedOption) => {
    const clonedFilters = _.cloneDeep(inputList);
    if (clonedFilters[index]) {
      clonedFilters[index].AttributeValue = value;
      clonedFilters[index].DisplayedValue = value;
      if (clonedFilters[index].AttributeId === 5) {
        const newValue = options?.find(option => option?.id === selectedOption.key);
        clonedFilters[index].AttributeValue = JSON.stringify(newValue?.data) ?? value;
      }
      setInputList(clonedFilters);
      handleFilterGroupItemChange(filterGroupId, clonedFilters);
    }
  };

  const debounceFetchSuggestedTags = React.useCallback(
    debounce(async nextValue => {
      setTitleInput(nextValue);
      setGloblaApiStatus('INPROGRESS');
      const results = await handleGlobalFunction({ nextValue, fetchLocations, attributeId: fieldId });
      if (results?.length > 0) {
        setOptions(results);
      } else if (nextValue?.trim()?.length > 0) {
        setOptions([{ value: nextValue, text: nextValue }]);
      } else {
        setOptions([]);
      }
      setGloblaApiStatus('COMPLETED');
    }, 600),
    []
  );

  const handleFilterButtonDisplay = newList => {
    if (newList?.length > 0) {
      if (newList[newList.length - 1] && Object.keys(newList[newList.length - 1]).length > 0) {
        const filterListObject = newList[newList.length - 1];
        if (
          _.has(filterListObject, 'AttributeName') &&
          _.has(filterListObject, 'Operator') &&
          _.has(filterListObject, 'AttributeValue') &&
          filterListObject?.AttributeValue?.length > 0
        ) {
          setshowAddFilterButton(true);
        } else {
          setshowAddFilterButton(false);
        }
      } else {
        setshowAddFilterButton(false);
      }
    } else {
      setshowAddFilterButton(true);
    }
  };

  const getContentNotFound = apiStatus => {
    if (titleInput) return apiStatus === 'INPROGRESS' ? 'Loading...' : '';
    return 'Type to search';
  };

  return (
    <div className={styles.advanceFiltersWrapper}>
      {inputList?.map((input, index) => (
        <div>
          <div key={index} className={styles.advanceFilters}>
            <Row>
              <Col span={7}>
                <CandidateAdvanceFilterFields
                  filterId={index}
                  inputList={inputList}
                  addFilterField={addFilterField}
                  filterFields={filterFields}
                  component={component}
                />
              </Col>
              <Col span={4}>
                <CandidateAdvanceFilterOperators
                  filterId={index}
                  inputList={inputList}
                  addFilterOperator={addFilterOperator}
                  options={handleOperatorsOptions(inputList[index]?.QueryOperator?.split(','))}
                />
              </Col>
              <Col span={10}>{handleSegmentFiltersComponent(index)}</Col>
              <Col>
                <Icon
                  className={styles.closeIcon}
                  theme="filled"
                  onClick={() => handleRemoveFilterItem(index)}
                  type="close-circle"
                />
              </Col>
            </Row>
          </div>
          {inputList?.length > 1 && index + 1 !== inputList?.length ? (
            <div className={styles.filterItemsAggregationSpliter}> AND</div>
          ) : null}
        </div>
      ))}
      {showAddFilterButton ? (
        <div
          className={styles.addFilterItemButton}
          role="button"
          tabIndex={0}
          onKeyPress={handleAddFilterItem}
          onClick={handleAddFilterItem}
        >
          <Icon className={styles.addIcon} theme="filled" type="plus-circle" />
          Filter
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAdvanceFilters);
export { CandidateAdvanceFilters as CandidateAdvanceFiltersWithoutStore };
