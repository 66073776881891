import React from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import * as CandidateActions from '../../Actions/CandidateActions';
import {
  getCandidateJobs,
  getCandidateJobsTotalCount,
  getJobAggregations,
  getCandidateMatchingJobs,
} from '../../Reducers/CandidateReducer';
import CandidateJobsWrapper from '../../Components/CandidateJobs/CandidateJobsWrapper';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import styles from '../../Components/CandidateJobsHeader/CandidateJobsHeader.module.scss';
import { getDefaultJobMatchingConfig } from '../../Utils/JobMatchingModalUtils';

const mapsDispatchToProps = {
  fetchCandidateMatchingJobs: CandidateActions.fetchCandidateMatchingJobs,
  fetchCandidateJobs: CandidateActions.fetchCandidateJobs,
  appendCandidateJobs: CandidateActions.appendCandidateJobs,
};
const mapStateToProps = state => ({
  candidateJobs: getCandidateJobs(state),
  fetchCandidateJobsApiStatus: getApiStatus(state, 'fetchCandidateJobsApiStatus'),
  candidateJobsTotalCount: getCandidateJobsTotalCount(state),
  jobAggregations: getJobAggregations(state),
  candidateMatchingJobs: getCandidateMatchingJobs(state),
});

const JobsHeader = (setCandidateMatchingJobModalVisiblity, version, candidateJobsTotalCount, showJobCountsInAts) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', marginLeft: '30px', marginTop: '10px' }}>
      <div className={styles.recentJobs}>
        Recent Jobs
        {showJobCountsInAts && version === 'ats' && candidateJobsTotalCount > 0 && ` (${candidateJobsTotalCount})`}
      </div>
      <div className={styles.textDivider}>&nbsp;/&nbsp;</div>
      <div
        className={styles.matchingJobs}
        role="button"
        tabIndex={0}
        onClick={() => {
          setCandidateMatchingJobModalVisiblity(true);
        }}
        onKeyPress={() => {
          setCandidateMatchingJobModalVisiblity(true);
        }}
      >
        Matching Jobs
      </div>
    </div>
  );
};

function CandidateJobsContainer(props) {
  const {
    fetchCandidateJobs,
    candidateJobs,
    candidate,
    fetchCandidateJobsApiStatus,
    jobAggregations,
    candidateJobsTotalCount,
    fetchCandidateMatchingJobs,
    appendCandidateJobs,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    version,
    candidateJobMatchingInitialAggregation,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isATSViewActive,
    setCandidateJobMatchingInitialAggregation,
    candidateMatchingJobs,
    setIsFindMathingJobClicked,
    isFindMathingJobClicked,
    featureToggleList,
    showJobCountsInAts,
  } = props;
  const [searchTerm, setSearchTerm] = React.useState('');
  const isCandidateMatchingJobsEnabled = featureToggleList?.CandidateMatchingJobs?.IsEnabled;
  const [selectedCandidateStageFilterOptions, setSelectedCandidateStageFilterOptions] = React.useState([]);
  const [selectedJobStatusFilterOptions, setSelectedJobStatusFilterOptions] = React.useState([1]);
  const jobs = candidateJobs;
  const onSearchChange = event => {
    const { value } = event.target;
    setSearchTerm(value);
    if (!value) {
      fetchCandidateJobs({ candidateId: candidate.Id, searchTerm: value, JobStatuses: selectedJobStatusFilterOptions });
    }
  };
  const onSearch = value => {
    if (searchTerm.trim().length && value.length)
      fetchCandidateJobs({ candidateId: candidate.Id, searchTerm: value, JobStatuses: selectedJobStatusFilterOptions });
  };
  const clearAllFilter = () => {
    setSelectedCandidateStageFilterOptions([]);
    setSelectedJobStatusFilterOptions([]);
    fetchCandidateJobs({
      candidateId: candidate.Id,
      CandidateStages: [],
      JobStatuses: [],
      searchTerm,
    });
  };

  const displayNewFindMatchingJobs = async () => {
    if (!candidateMatchingJobs?.length) {
      const response = await fetchCandidateMatchingJobs(getDefaultJobMatchingConfig(candidate));
      setCandidateJobMatchingInitialAggregation(response.Aggregations);
    }
  };

  React.useEffect(() => {
    if (!isCandidateMatchingJobModalVisible)
      fetchCandidateJobs({ candidateId: candidate.Id, JobStatuses: selectedJobStatusFilterOptions });
    else if (!isFindMathingJobClicked && isCandidateMatchingJobModalVisible) {
      displayNewFindMatchingJobs();
    }
  }, [isCandidateMatchingJobModalVisible]);

  if (fetchCandidateJobsApiStatus === 'INPROGRESS')
    return (
      <>
        {isCandidateMatchingJobsEnabled
          ? JobsHeader(setCandidateMatchingJobModalVisiblity, version, candidateJobsTotalCount, showJobCountsInAts)
          : null}
        <div className={styles.candidateJobsLoadingState}>
          <Skeleton paragraph={{ rows: 10 }} loading active />
        </div>
      </>
    );
  return (
    <div>
      {!isCandidateMatchingJobModalVisible && isCandidateMatchingJobsEnabled
        ? JobsHeader(setCandidateMatchingJobModalVisiblity, version, candidateJobsTotalCount, showJobCountsInAts)
        : null}
      <CandidateJobsWrapper
        jobs={jobs}
        jobAggregations={jobAggregations}
        fetchCandidateJobs={fetchCandidateJobs}
        candidate={candidate}
        onSearchChange={onSearchChange}
        onSearch={onSearch}
        searchTerm={searchTerm}
        selectedCandidateStageFilterOptions={selectedCandidateStageFilterOptions}
        setSelectedCandidateStageFilterOptions={setSelectedCandidateStageFilterOptions}
        selectedJobStatusFilterOptions={selectedJobStatusFilterOptions}
        setSelectedJobStatusFilterOptions={setSelectedJobStatusFilterOptions}
        jobsTotalCount={candidateJobsTotalCount}
        appendCandidateJobs={appendCandidateJobs}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
        clearAllFilter={clearAllFilter}
        fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
        isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
        setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
        version={version}
        candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        isATSViewActive={isATSViewActive}
        setIsFindMathingJobClicked={setIsFindMathingJobClicked}
      />
    </div>
  );
}
export default connect(mapStateToProps, mapsDispatchToProps)(CandidateJobsContainer);
export { CandidateJobsContainer as CandidateJobsContainerWithoutStore };
