import { Button, Tooltip } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { CandidateScoreIcon } from '../../../Icons/AryaIcons';
import styles from './CandidateScore.module.scss';
import ParsingFailedIcon from '../../../Icons/CandidateIcons/ParsingFailedIcon';
import config from '../../../Config/Config';
import { getCandidate360ProfileActionEventName } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';

export const getCandidateScoreContent = ({ whiteLabelInfo, candidateScore, candidate, status }) => {
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
  const scoreIndicator = candidateScore;
  const scoreErrorIndicator = (
    <Tooltip title={`${AppName} could not analyze this candidate`} placement="topLeft">
      <div>
        <ParsingFailedIcon className={styles.scoreErrorIcon} />
      </div>
    </Tooltip>
  );
  const scorePendingIndicator = (
    <Tooltip title={`${AppName} still analyzing`} placement="topLeft">
      <div>
        <img
          src={`${config.urls.appUrl}/static/Images/loader-icon.gif`}
          alt="Arya still analyzing"
          className={styles.scoreErrorPendingIcon}
        />
      </div>
    </Tooltip>
  );
  let scoreContent = null;
  const appliedTimeDifference = moment().diff(moment.utc(candidate?.AppliedTime), 'minutes');

  if (Number.isFinite(candidate?.Score) && candidate?.Score >= 0) {
    scoreContent = scoreIndicator;
  } else if (status?.toLowerCase() === 'applied' && [null, undefined]?.includes(candidate?.Score)) {
    if (candidate?.AppliedResumeParsingStatus?.toUpperCase() === 'FAILED') {
      scoreContent = scoreErrorIndicator;
    } else if (appliedTimeDifference >= 5) {
      scoreContent = scoreErrorIndicator;
    } else scoreContent = scorePendingIndicator;
  }
  return <span>{scoreContent}</span>;
};

function CandidateScore(props) {
  const {
    candidate,
    status,
    whiteLabelInfo,
    onCandidateCardClick,
    featureToggleList,
    candidateType,
    candidateContext,
    userConfig,
  } = props;

  const candidateScore = candidate.Score;
  const maxCandidateScore = userConfig?.ScoreConfig?.Max || 3;

  const {
    CandidateIntelV2: { IsEnabled: isCandidateIntelV2Enabled },
  } = featureToggleList || {};

  const isCandidateIntelVisible = candidateContext === 'job' && isCandidateIntelV2Enabled;
  const showRatingButton = isCandidateIntelVisible && candidateType !== 'quick-search' && isCandidateIntelV2Enabled;

  const ratingExplanationButton = showRatingButton ? (
    <Button
      data-testid="candidate-intel"
      type="link"
      className={styles.candidateRatingLinkButton}
      onClick={e => {
        e.stopPropagation();
        onCandidateCardClick('candidate-intel');
      }}
      size="small"
      sk-event-name={getCandidate360ProfileActionEventName(
        eventTypes.candidate.candidate360Profile.goToCandidateScoreDetails,
        candidate
      )}
    >
      Why this rating?
    </Button>
  ) : null;

  const maxScoreWithSlashIcon = (
    <span>
      <span className={styles.slashIcon}>/</span>
      <span className={styles.maxCandidateScore}>{maxCandidateScore}</span>
    </span>
  );

  return (
    <div className={styles.candidateScore}>
      <div className={styles.candidateScoreSection}>
        <CandidateScoreIcon className={styles.candidateScoreIcon} />
        <div className={styles.candidateScoreWithSlash}>
          {getCandidateScoreContent({ whiteLabelInfo, candidateScore, candidate, status })}
          {maxScoreWithSlashIcon}
        </div>
      </div>
      {ratingExplanationButton}
    </div>
  );
}

export default CandidateScore;
