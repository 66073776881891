import { Col, Row } from 'antd';
import React from 'react';
import EmploymentCriteriaWrapper from '../../../../CompoundComponents/EmploymentWrapper/EmploymentWrapper';
import PreferredIndustry from '../../../../CompoundComponents/EmploymentWrapper/PreferredIndustry';
import ExcludedIndustry from '../../../../CompoundComponents/EmploymentWrapper/ExcludeIndustry';
import PreferredCompany from '../../../../CompoundComponents/EmploymentWrapper/PreferredCompany';
import ExcludedCompany from '../../../../CompoundComponents/EmploymentWrapper/ExcludeCompany';
import TextWithInfoIcon from '../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import PreferredCompanyCheckbox from '../../../../CompoundComponents/EmploymentWrapper/PreferredCompanyCheckbox';
import styles from './EmploymentSection.module.scss';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import eventTypes from '../../../../Analytics/EventTypes';

function InputItem({ label, inputElement, tooltipTitle }) {
  const inputFieldTitle = <div className={styles.inputLabels}>{label}</div>;
  return (
    <Row className={styles.empolymentRow}>
      <Col span={5}>
        {tooltipTitle ? <TextWithInfoIcon text={inputFieldTitle} tooltipTitle={tooltipTitle} /> : inputFieldTitle}
      </Col>
      <Col span={19}>{inputElement}</Col>
    </Row>
  );
}

export default function EmploymentSection(props) {
  const { form, defaultFormValues, industries, sourceName } = props;
  const onClear = () => {
    form.setFieldsValue({
      PreferredIndustry: undefined,
      ExcludedIndustries: undefined,
      PreferredCompany: undefined,
      ExcludedCompanies: undefined,
      IsPastCompany:false,
      IsCurrentCompany:false,
    });
  };

  return (
    <div className={styles.employmentSection}>
      <div className={styles.employmentSectionClearAll}>
        <div className={styles.employmentSectionLabelWrapper}>Employment</div>
        <div className={styles.clearAll}>
          <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearEmploymentFilter} />
        </div>
      </div>
      <div className={styles.employmentCriteriaWrapper}>
        <EmploymentCriteriaWrapper form={form} industries={industries}>
          <Row>
            <Col span={20} className={styles.empolymentColumn}>
              <InputItem
                label="Industry"
                tooltipTitle={`Industry will be supported only by ${sourceName}`}
                inputElement={<PreferredIndustry initialValue={defaultFormValues?.Industries?.Musts} />}
              />
              <InputItem
                label="Industry Exclusions"
                tooltipTitle={`Exclude Industries will be supported only by ${sourceName}`}
                inputElement={<ExcludedIndustry initialValue={defaultFormValues?.Industries?.Excludes} />}
              />
              <InputItem
                label="Company"
                inputElement={<PreferredCompany initialValue={defaultFormValues?.Companies?.Musts} />}
              />
              <InputItem
                label=""
                inputElement={
                  <PreferredCompanyCheckbox
                    isCurrentCompanyInitialValue={defaultFormValues?.Companies?.IsCurrent}
                    isPastCompanyInitialValue={defaultFormValues?.Companies?.IsPast}
                  />
                }
              />
              <InputItem
                label="Company Exclusions"
                tooltipTitle="Exclude Companies is not available in Monster Power"
                inputElement={<ExcludedCompany initialValue={defaultFormValues?.Companies?.Excludes} />}
              />
            </Col>
          </Row>
        </EmploymentCriteriaWrapper>
      </div>
    </div>
  );
}
