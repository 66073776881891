import React from 'react';
import { Select, Icon, Input, Button } from 'antd';
import classnames from 'classnames';
import ChatPane from '../ChatPane/ChatPane';
import './MessagePopup.scss';
import { formatTextMessage } from '../../../Utils/TextUtils';
import ReportInvalidIncorrectButton from '../ReportInvalidPhone/ReportInvalidPhoneButton';
import InvalidPhoneButton from '../ReportInvalidPhone/InvalidPhoneButton';
import { getLocaleDateStringWithTimeStamp } from '../../../Utils/RelativeTimeCalculator';

import UnsubscribedButton from '../../UnsubscribeCandidate/UnsubscribedButton';
import UnsubscribeCandidateButton from '../../UnsubscribeCandidate/UnsubsCribeCandidateButton';

const { TextArea } = Input;
const { Option } = Select;
const { MessageBubble } = ChatPane;

//! TODO: move to util? Or a component?
function getConsentStatusInfoBanner(messageConsentStatus, candidateName, selectedPhone, connectInfo, userByGuId) {
  const nameParts = (candidateName || '').split(' ');
  const firstName = nameParts && nameParts.length > 0 ? nameParts[0] : '';
  const subscription = connectInfo?.Contact?.Subscription;

  if (subscription?.Status?.toLowerCase() === 'unsubscribed') {
    const { UpdatedTime, Remarks: remarks } = subscription;
    const createdByName = userByGuId[subscription.CreatedBy]?.FirstName;
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="info-circle" theme="filled" />
        <span>{`You won't be able to communicate with ${candidateName} as ${createdByName} has marked the candidate as unsubscribed with reason “ ${remarks} ” on ${getLocaleDateStringWithTimeStamp(
          UpdatedTime
        )}`}</span>
      </div>
    );
  }
  if (!messageConsentStatus) {
    return (
      <div className="consent-status-info-message">
        <Icon type="info-circle" theme="filled" />
        <span>{`A consent text will be sent with your first message. You must receive ${firstName}'s consent before sending further texts.`}</span>
      </div>
    );
  }
  if (messageConsentStatus === 'Denied') {
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="close-circle" theme="filled" />
        <span>{`We're sorry, but ${firstName} has declined consent. You may no longer communicate on this number via Arya.`}</span>
      </div>
    );
  }
  if (messageConsentStatus === 'Pending') {
    return (
      <div className="consent-status-info-message consent-pending">
        <Icon type="clock-circle" theme="filled" />
        <span>{`Consent is pending. Communication will be enabled when ${firstName} gives consent.`}</span>
      </div>
    );
  }
  if (selectedPhone?.IsUnsubscribeByUser) {
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="close-circle" theme="filled" />
        <span>Recruiter has unsubscribed {selectedPhone.Number}</span>
      </div>
    );
  }
  return null;
}

export default class MessagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messageText: '', isCandidateUnsubscribed: false };
    this.onSend = this.onSend.bind(this);
    this.updateIsCandidateUnsubscribed = this.updateIsCandidateUnsubscribed.bind(this);
    this.setIsCandidateUnsubscribed = this.setIsCandidateUnsubscribed.bind(this);
  }

  componentDidMount() {
    this.updateIsCandidateUnsubscribed();
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscription !== prevProps.subscription) {
      this.updateIsCandidateUnsubscribed();
    }
  }

  updateIsCandidateUnsubscribed() {
    const { subscription } = this.props;
    if (subscription) {
      this.setState({
        isCandidateUnsubscribed: subscription?.toLowerCase() === 'unsubscribed',
      });
    }
  }

  setIsCandidateUnsubscribed(value) {
    this.setState({ isCandidateUnsubscribed: value });
  }

  onSend(e) {
    e.preventDefault();
    const { sendMessage, selectedPhone } = this.props;
    const { messageText } = this.state;
    sendMessage(messageText, selectedPhone);
    this.setState({ messageText: '' });
  }

  render() {
    const {
      className,
      onClose,
      conversationId,
      messages,
      personDetails,
      messageType,
      connectInfo,
      consentStatus,
      phones,
      onChangePhone,
      selectedPhone,
      headerSubText,
      personId,
      featureToggleList,
      connectApiStatus,
      userByGuId,
      history,
      notificationPayload,
      saveRecentReadNotificationInfo,
    } = this.props;
    const { messageText, isCandidateUnsubscribed } = this.state;
    const isSendMessageAllowed = (messageType === 'Chat' || consentStatus === 'Approved') && !isCandidateUnsubscribed;
    const candidate = { ConversationId: conversationId, PersonId: personId, connectInfo };
    const isUnsubscribeCandidateEnabled = featureToggleList?.UnsubscribeCandidate.IsEnabled;
    let unsubscribeCandidateButton = null;
    const isCurrentSelectedPhoneNumberInvalid = phones?.find(
      phone =>
        phone.Number === selectedPhone && phone.ContactMismatchInfo && phone.ContactMismatchInfo?.IsMismatch === true
    );
    if (connectApiStatus === 'COMPLETED') {
      unsubscribeCandidateButton = isCandidateUnsubscribed ? (
        <UnsubscribedButton candidate={candidate} />
      ) : (
        <UnsubscribeCandidateButton
          setIsCandidateUnsubscribed={this.setIsCandidateUnsubscribed}
          candidate={candidate}
        />
      );
    }

    return (
      <div className={`popup-container ${className}`}>
        <div className="popup-header">
          <div className="popup-name-close">
            <div className="popup-name-wrapper">
              <div
                className="popup-name"
                onClick={() => {
                  history.push('/connect?activeTab=message');
                  saveRecentReadNotificationInfo('Connect', notificationPayload);
                }}
              >
                {personDetails.Name}
              </div>

              <div className="popup-job-details">{headerSubText}</div>
            </div>
            {isUnsubscribeCandidateEnabled && (
              <span
                style={{
                  transform: 'scale(0.75)',
                  marginLeft: '0px !important',
                  display: 'flex',
                  maxHeight: '30px',
                  minWidth: 'fit-content',
                  maxWidth: '155px',
                  position: 'absolute',
                  right: '10px',
                  top: '5px',
                }}
              >
                {unsubscribeCandidateButton}
              </span>
            )}
            <div className="popup-close">
              <Icon type="close" onClick={() => onClose(conversationId)} />
            </div>
          </div>
          {messageType === 'SMS' ? (
            <div className="popup-phones">
              <Select
                dropdownClassName="popup-message-dropdown"
                defaultValue={selectedPhone}
                className={classnames(
                  `phone-select ${isCurrentSelectedPhoneNumberInvalid ? 'phone-select-invalid' : ''}`
                )}
                value={selectedPhone}
                onChange={onChangePhone}
              >
                {phones.map(phone => {
                  const invalidReason = phone?.ContactMismatchInfo?.MismatchReason;
                  return (
                    <Option key={phone.Id} value={phone.Number}>
                      <span className={invalidReason ? 'lineForInvalidContact' : ''}>{phone.Number}</span>
                    </Option>
                  );
                })}
              </Select>
              {isCurrentSelectedPhoneNumberInvalid ? (
                <InvalidPhoneButton
                  isCurrentSelectedPhoneNumberInvalid={isCurrentSelectedPhoneNumberInvalid}
                  userByGuId={userByGuId}
                />
              ) : (
                <ReportInvalidIncorrectButton candidate={candidate} selectedPhone={selectedPhone} />
              )}
            </div>
          ) : null}
        </div>
        <div className="popup-body">
          <ChatPane className="popup-chat-pane">
            {messages.map(message =>
              message.Body ? (
                <MessageBubble
                  key={message.Id}
                  message={formatTextMessage(message.Body)}
                  align={message.IsByPerson ? 'left' : 'right'}
                  timestamp={message.CreatedTime}
                  status={message.Status}
                  CreatedByName={message.CreatedByName || personDetails.Name}
                  personDetails={personDetails}
                  isByBot={message?.IsByBot}
                />
              ) : null
            )}
          </ChatPane>
        </div>
        <div className="popup-footer">
          {isSendMessageAllowed ? (
            <div className="popup-chat-textarea">
              <TextArea
                placeholder="Type a message"
                style={{ height: '50px', border: '0px', resize: 'none' }}
                autosize={{ maxRows: 3 }}
                value={messageText}
                onPressEnter={this.onSend}
                onChange={event => this.setState({ messageText: event.target.value })}
              />
              <Button type="primary" id="popup-chat-send-button" onClick={this.onSend}>
                <Icon type="arrow-right" />
              </Button>
            </div>
          ) : (
            <>
              {connectInfo.Contact
                ? getConsentStatusInfoBanner(consentStatus, personDetails.Name, selectedPhone, connectInfo, userByGuId)
                : null}
            </>
          )}
        </div>
      </div>
    );
  }
}
