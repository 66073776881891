import React from 'react';
import { Row, Col, Form, Select, Typography, Divider } from 'antd';
import { getSupportedSourcesDisplayText } from '../../../CompoundComponents/EducationWrapper/Degree';
import { GlobalWorkStatusOptions } from '../../../Utils/AdvanceRefineIntelUtils';
import TitlesSection from './TitlesSection/TitlesSection';
import SkillsSection from './SkillsSection/SkillsSection';
import ExperienceSection from './ExperienceSection/ExperienceSection';
import LocationSection from './LocationSection/LocationSection';
import styles from './SearchCriteria.module.scss';
import ResumeLastUpdatedSection from './ResumeLastUpdatedSection/ResumeLastUpdatedSection';

export function InputItem({ children, label }) {
  return (
    <Row>
      <Col span={5}>
        <div className={styles.inputLabels}>{label}</div>
      </Col>
      <Col span={19}>{children}</Col>
    </Row>
  );
}

function SearchCriteria(props) {
  const {
    jobId,
    isManualSearchFormMinimized,
    form,
    defaultFormValues,
    currentUserDetails,
    booleanSearchStrings,
    fetchBooleanSearchStrings,
    candidateContext,
  } = props;
  const {
    Titles: defaultTitles = [],
    Skills: defaultSkills = {},
    VisaStatuses: defaultVisaStatuses = [],
    QueryString: defaultQueryString,
    ActiveSearchString: defaultActiveSearchString,
    IsExactTitleMatchRequired: defaultIsExactTitleMatchRequired,
  } = defaultFormValues;

  return (
    <div className={styles.searchCriteriaWrapper}>
      <TitlesSection
        form={form}
        defaultTitles={defaultTitles}
        isManualSearchFormMinimized={isManualSearchFormMinimized}
        defaultIsExactTitleMatchRequired={defaultIsExactTitleMatchRequired}
      />
      <SkillsSection
        jobId={jobId}
        booleanSearchStrings={booleanSearchStrings}
        fetchBooleanSearchStrings={fetchBooleanSearchStrings}
        form={form}
        defaultSkills={defaultSkills}
        defaultQueryString={defaultQueryString}
        defaultActiveSearchString={defaultActiveSearchString}
        isManualSearchFormMinimized={isManualSearchFormMinimized}
        currentUserDetails={currentUserDetails}
      />
      <div className={styles.experienceLastUpdatedWrapper}>
        <ExperienceSection form={form} defaultFormValues={defaultFormValues} />
        <ResumeLastUpdatedSection form={form} defaultFormValues={defaultFormValues} />
      </div>
      <LocationSection form={form} defaultFormValues={defaultFormValues} candidateContext={candidateContext} />
      <Divider className={styles.workStatusDivider} />
      <div className={styles.workStatusSection}>
        <InputItem label="Work Authorization">
          <Form.Item>
            {form.getFieldDecorator('VisaStatuses', {
              initialValue: defaultVisaStatuses,
            })(
              <Select
                placeholder="Select Work Authorization"
                mode="multiple"
                className={styles.selectDropdown}
                allowClear
                showArrow
              >
                {GlobalWorkStatusOptions.map(({ value, label, supportedBy }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                    <br />
                    <Typography.Text type="secondary">{getSupportedSourcesDisplayText(supportedBy)}</Typography.Text>
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </InputItem>
      </div>
    </div>
  );
}

export default React.memo(SearchCriteria);
