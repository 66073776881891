import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import { Button, Checkbox, Select, Input, Spin, message } from 'antd';
import styles from './ScoutingAgent.module.scss';
import {
  generateScoutingAgentListPayload,
  getUpdatedUsers,
  getIsSaveScoutingAgentUsersButtonDisabled,
} from '../../../../Utils/ScoutingAgentUtils';
import { fetchUsers as fetchUsersFromRepo } from '../../../../Repository/UserRepository';

const ScoutingAgent = props => {
  const {
    currentUser,
    getScoutingAgentEnabledUsers,
    saveSourcingAgentUserList,
    fetchUsers,
    userById,
    maxUsers,
    userList,
    fetchUserDetailsApiStatus,
  } = props;

  const [scoutingAgentUserList, setScoutingAgentUserList] = React.useState([]);
  const [initialScoutingAgentUserList, setInitialScoutingAgentList] = React.useState([]);
  const [isSelectAllChecked, setSelectAllCheckboxChecked] = React.useState(false);
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const [selectedPermissionOptions, setSelectedPermissionOptions] = React.useState({});
  const [saveSourcingAgentListApiStatus, setSaveSourcingAgentListApiStatus] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');

  const isSaveButtonDisbaled = getIsSaveScoutingAgentUsersButtonDisabled(
    selectedKeys,
    initialScoutingAgentUserList,
    selectedPermissionOptions
  );

  const handleLoadMore = page => {
    if ((page - 1) * 10 > maxUsers) {
      return;
    }
    if (searchValue) {
      fetchUsers({ page, searchTerm: searchValue, isActive: true, listFor: 'ShareJob' });
    } else {
      fetchUsers({ page, isActive: true, listFor: 'ShareJob' });
    }
  };

  const handeleSearchUsers = async value => {
    const cleanValue = value.trim().toLowerCase();
    if (cleanValue) {
      const filterScoutingAgentUserList = initialScoutingAgentUserList.filter(({ user, email }) => {
        return !!(user.toLowerCase().includes(cleanValue) || email.toLowerCase().includes(cleanValue));
      });
      setScoutingAgentUserList(filterScoutingAgentUserList);
      await fetchUsers({ page: 1, searchTerm: cleanValue, isActive: true, listFor: 'ShareJob' });
    } else {
      fetchUsers({ page: 1, isActive: true, listFor: 'ShareJob' });
    }
    setSearchValue(cleanValue);
  };
  const handleSearchAndDownloadSelect = value => {
    const [permission, id] = value.split('-');
    const newSelectedPermissions = { ...selectedPermissionOptions, [id]: permission };
    setSelectedPermissionOptions(newSelectedPermissions);
  };

  const getSelectPermissionOptions = (defaultValue, userId, isSelectDisabled) => {
    let disable = isSelectDisabled;
    if (isSelectAllChecked) disable = false;
    return (
      <Select
        className={styles.scoutingAgentContent}
        defaultValue={defaultValue}
        onSelect={handleSearchAndDownloadSelect}
        disabled={disable}
      >
        <Select.Option value={`search-${userId}`}>Search</Select.Option>
        <Select.Option value={`searchAndDownload-${userId}`}>Search & Auto download candidates</Select.Option>
      </Select>
    );
  };

  const fetchScoutingAgentUserList = async () => {
    const scoutingAgentEnabledUsers = await getScoutingAgentEnabledUsers();
    const userIds = scoutingAgentEnabledUsers.map(res => res.UserId);
    const filter = { userIds, isActive: true, listFor: 'ShareJob' };
    const updatedFilter = {
      ...filter,
      from: 0,
      size: filter.userIds?.length || 10,
      searchTerm: filter.searchTerm,
      isActive: filter.isActive,
      userIds: filter.userIds || [],
    };
    const { data: userData } = await fetchUsersFromRepo(updatedFilter, currentUser.OrgId);
    const tempSelectedPermission = {};
    const tempSelectedKeys = [...selectedKeys];
    const scoutingUserList = (scoutingAgentEnabledUsers || []).map(res => {
      const { UserId, UserName, IsSearchEnabled, IsAutoDownloadEnabled, Email } = res;
      if (!tempSelectedKeys.includes(UserId)) tempSelectedKeys.push(UserId);
      const defaultValue =
        IsSearchEnabled && IsAutoDownloadEnabled ? `searchAndDownload-${UserId}` : `search-${UserId}`;
      const permission = IsSearchEnabled && IsAutoDownloadEnabled ? `searchAndDownload` : `search`;

      tempSelectedPermission[UserId] = permission;
      return {
        key: UserId,
        user: UserName,
        email: Email,
        IsAdmin: userData.Users.find(entry => entry?.Id === UserId)?.IsAdmin,
        defaultValue,
      };
    });
    setSelectedKeys(tempSelectedKeys);
    setSelectedPermissionOptions(tempSelectedPermission);
    setScoutingAgentUserList(scoutingUserList);
    setInitialScoutingAgentList(scoutingUserList);
  };

  React.useEffect(() => {
    fetchScoutingAgentUserList();
  }, []);

  const onSelectAllChange = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allKeys = scoutingAgentUserList.map(item => item.key);
      setSelectedKeys(allKeys);
    } else {
      setSelectedKeys([]);
    }
    setSelectAllCheckboxChecked(isChecked);
  };

  const onChangeCheckbox = value => {
    const updatedSelectedKeys = [...selectedKeys];
    if (updatedSelectedKeys.includes(value)) {
      updatedSelectedKeys.splice(updatedSelectedKeys.indexOf(value), 1);
    } else {
      updatedSelectedKeys.push(value);
    }
    setSelectedKeys(updatedSelectedKeys);
  };

  const onClickSaveButton = async () => {
    const selectedUserList = isSelectAllChecked ? userList : selectedKeys;
    const selectedKeysWithPermission = {};
    selectedUserList.forEach(id => {
      selectedKeysWithPermission[id] = selectedPermissionOptions[id] ?? 'search';
    });
    const updatedUsers = getUpdatedUsers(initialScoutingAgentUserList, selectedKeysWithPermission);
    try {
      if (updatedUsers.length > 0) {
        setSaveSourcingAgentListApiStatus('INPROGRESS');
        await saveSourcingAgentUserList(updatedUsers);
        setSaveSourcingAgentListApiStatus('COMPLETED');
        message.success('Scouting Agent settings saved successfully');
        fetchScoutingAgentUserList();
      }
    } catch (error) {
      setSaveSourcingAgentListApiStatus('FAILED');
      message.error('Failed to save Scouting Agent settings');
    }
  };

  const handleSearchClear = event => {
    if (!event.target.value) {
      const cleanValue = event.target.value;
      setScoutingAgentUserList(initialScoutingAgentUserList);
      setSearchValue(cleanValue.toLowerCase());
      if (cleanValue) {
        fetchUsers({ page: 1, searchTerm: cleanValue.toLowerCase(), isActive: true, listFor: 'ShareJob' });
      } else {
        fetchUsers({ page: 1, isActive: true, listFor: 'ShareJob' });
      }
    }
  };
  const isUserExist = id => {
    return scoutingAgentUserList.find(item => item.key === id);
  };
  const uniqueUserList = userList.filter(id => !isUserExist(id));
  return (
    <div>
      <h2>Scouting Agent</h2>
      <p style={{ fontSize: '16px' }}>
        Settings will enable the recruiter to initiate a scouting agent on jobs when turned ON
      </p>
      <Spin spinning={fetchUserDetailsApiStatus === 'PENDING'}>
        <div className={styles.contentContainer}>
          <div className={styles.searchUserContainer}>
            <Input.Search
              placeholder="Search Users"
              size="large"
              className={styles.scoutingAgentContent}
              onSearch={handeleSearchUsers}
              allowClear
              onChange={handleSearchClear}
            />
          </div>
          <div className={styles.userWithPermissionContainer}>
            <div className={styles.userTableContainer}>
              <div className={styles.usersColumn}>
                <div className={styles.userWithCheckbox}>
                  <Checkbox
                    onChange={onSelectAllChange}
                    checked={isSelectAllChecked && scoutingAgentUserList.length === selectedKeys.length}
                  />
                  Users
                </div>
              </div>
              <div className={styles.permissionColumn}>
                <div className={styles.permissionSelect}>Permissions</div>
              </div>
            </div>
            <div style={{ height: '192px', overflow: 'auto' }}>
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={handleLoadMore}
                hasMore={userList.length < maxUsers}
                // TODO: implement a logic to do the infinite scroll when the scroll hits bottom
                useWindow={false}
                threshold={50}
                loader={<div className="loader" key={0}></div>}
              >
                <div className={styles.scoutingAgentUserList}>
                  {scoutingAgentUserList.map(_item => {
                    const isSelectDisabled = !selectedKeys.includes(_item.key);
                    const item = {
                      ..._item,
                      permission: getSelectPermissionOptions(_item.defaultValue, _item.key, isSelectDisabled),
                    };

                    return (
                      <div className={styles.userOptionsWithPermission} key={_item.key}>
                        <div className={styles.userWithCheckbox}>
                          <Checkbox
                            onChange={() => onChangeCheckbox(item.key)}
                            checked={selectedKeys.includes(item.key)}
                            value={item.key}
                          />
                          <div className={styles.userNameWithAdminLabel}>
                            {item.user}
                            {item.IsAdmin ? <span className={styles.adminText}>Admin</span> : null}
                          </div>
                        </div>
                        <div className={styles.permissionSelect}>{item.permission}</div>
                      </div>
                    );
                  })}
                  {uniqueUserList.map(value => {
                    const defaultValue = selectedPermissionOptions[value]
                      ? `${selectedPermissionOptions[value]}-${value}`
                      : `search-${value}`;
                    const isSelectDisabled = !selectedKeys.includes(value);
                    const item = {
                      key: value,
                      user: userById[value]?.FullName,
                      permission: getSelectPermissionOptions(defaultValue, value, isSelectDisabled),
                      IsAdmin: userById[value]?.IsAdmin,
                    };
                    return (
                      <div className={styles.userOptionsWithPermission}>
                        <div className={styles.userWithCheckbox}>
                          <Checkbox
                            onChange={() => onChangeCheckbox(item.key)}
                            checked={isSelectAllChecked || selectedKeys.includes(item.key)}
                            value={item.key}
                          />
                          <div className={styles.userNameWithAdminLabel}>
                            {item.user}
                            {item.IsAdmin ? <span className={styles.adminText}>Admin</span> : null}
                          </div>
                        </div>
                        <div className={styles.permissionSelect}>{item.permission}</div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
            </div>
          </div>

          <div className={styles.footerContainer}>
            <Button
              type="primary"
              shape="round"
              disabled={!selectedKeys.length}
              onClick={onClickSaveButton}
              loading={saveSourcingAgentListApiStatus === 'INPROGRESS'}
            >
              Save
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ScoutingAgent;
