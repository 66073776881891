import React from 'react';
import moment from 'moment';
import {
  CareerBuilderIcon,
  DiceIcon,
  IndeedIcon,
  NexxtIcon,
  MonsterIcon,
  CVLibraryIcon,
  ClearanceJobsIcon,
  ResumeLibraryIcon,
  EFinancialIcon,
  CWJobsIcon,
  TotalJobsIcon,
  JobsiteIcon,
} from '../Icons/JobBoardsIcons';
import { AryaActiveIcon, AryaPassiveIcon, SocialIcon, LinkedInCircularIcon } from '../Icons/AryaIcons';
import { getSourceDisplayName } from './SourceUtils';

const singleCredentialJobBoards = ['monster']; // Job boards which have at max one autheticated credential

const getJobBoardsIcon = (name, style) => {
  switch (name?.toUpperCase()) {
    case 'CAREERBUILDER':
      return <CareerBuilderIcon style={style} tag="Classic" />;
    case 'DICE':
      return <DiceIcon style={style} />;
    case 'CWJOBS':
      return <CWJobsIcon style={style} />;
    case 'ACTIVE':
      return <AryaActiveIcon style={style} />;
    case 'NEXXT':
      return <NexxtIcon style={style} />;
    case 'JOBSITE':
      return <JobsiteIcon style={style} />;
    case 'MONSTER':
      return <MonsterIcon style={style} />;
    case 'TOTALJOBS':
      return <TotalJobsIcon style={style} />;
    case 'INDEED':
      return <IndeedIcon style={style} />;
    case 'EFINANCIAL':
      return <EFinancialIcon style={style} />;
    case 'SOCIAL':
      return <SocialIcon style={style} />;
    case 'RESUMELIBRARY':
      return <ResumeLibraryIcon style={style} />;
    case 'CVLIBRARY':
      return <CVLibraryIcon style={style} />;
    case 'CLEARANCEJOBS':
      return <ClearanceJobsIcon style={style} />;
    case 'PASSIVE':
      return <AryaPassiveIcon style={style} />;
    case 'CAREERBUILDEREDGE':
      return <CareerBuilderIcon style={style} tag="Edge" />;
    case 'LINKEDINADVANCE':
      return <LinkedInCircularIcon style={style} />;
    default:
      return <SocialIcon style={style} />;
  }
};

export const getJobBoardErrorDetails = (accountDetail, jobBoard, userConfig) => {
  switch (accountDetail.HoldReason) {
    case 'PortalAuthorizationFailed':
    case 'PortalAuthenticationFailed':
      if (accountDetail.HoldUntilTime && moment.utc(accountDetail.HoldUntilTime).diff(moment(), 'seconds') > 0)
        return {
          Type: 'ReauthenticationRequired',
          Message: `ERROR!!! - Invalid job board credentials, Please disconnect and reconnect ${getSourceDisplayName(
            {
              Portal: jobBoard,
            },
            userConfig
          )} for search to work.`,
        };
      return {};
    default:
      return {};
  }
};

const JOBBOARDFORPUBLISH = 'talroo';

export { getJobBoardsIcon, singleCredentialJobBoards, JOBBOARDFORPUBLISH };
