import React from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { getJobsById } from '../../Reducers/JobReducer';
import BotConfig from '../../Components/Connect/BotConfig/BotConfig';
import * as AryabotTemplateActions from '../../Actions/AryabotTemplates';
import * as AryabotContextActions from '../../Actions/AryabotContextActions';
import { getFrequentQuestions, getContextById, getFrequentQuestionGroups } from '../../Reducers/AryabotContextReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCurrentAryabotTemplateIds, getAryabotTemplatesById } from '../../Reducers/AryabotTemplates';
import * as ConnectActions from '../../Actions/ConnectActions';

const mapStateToProps = state => ({
  frequentQuestions: getFrequentQuestions(state),
  frequentQuestionGroups: getFrequentQuestionGroups(state),
  contextById: getContextById(state),
  currentAryabotTemplateIds: getCurrentAryabotTemplateIds(state),
  aryabotTemplatesById: getAryabotTemplatesById(state),
  jobsById: getJobsById(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  postQuestionsApiStatus: getApiStatus(state, 'PostQuestionsApiStatus'),
  postAnswersApiStatus: getApiStatus(state, 'PostAnswersApiStatus'),
  generateQuestionsApiStatus: getApiStatus(state, 'GenerateQuestionsApiStatus'),
  getJobQuestionApiStatus: getApiStatus(state, 'getJobQuestionApiStatus'),
});

const mapDispatchToProps = {
  fetchFrequentQuestions: AryabotContextActions.fetchFrequentQuestions,
  getQuestions: AryabotContextActions.getQuestions,
  postQuestions: AryabotContextActions.postQuestions,
  getAnswers: AryabotContextActions.getAnswers,
  postAnswers: AryabotContextActions.postAnswers,
  searchAryabotTemplates: AryabotTemplateActions.searchAryabotTemplates,
  getTemplateQuestions: AryabotTemplateActions.getQuestions,
  createContext: AryabotContextActions.createContext,
  editConversationReferenceDetails: ConnectActions.editConversationReferenceDetails,
  generateQuestions: AryabotContextActions.generateQuestions,
};

class BotConfigContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    const { createContext, jobId, fetchFrequentQuestions, getQuestions, getAnswers, searchAryabotTemplates, jobsById } =
      this.props;
    const { JobGuid, BotContextId } = _.get(jobsById, [jobId], {});

    fetchFrequentQuestions();
    if (BotContextId) {
      getQuestions(BotContextId);
      getAnswers(BotContextId);
    } else {
      createContext('REF', JobGuid, jobId);
    }
    searchAryabotTemplates({ From: 0, Size: 100, SearchTerm: '' });
  }

  async onSave(questions, answers, botStatus) {
    const { onClose, postQuestions, postAnswers, editConversationReferenceDetails, jobId, jobsById } = this.props;
    const { JobGuid, BotContextId, IsBotEnabled } = _.get(jobsById, [jobId], {});
    if (IsBotEnabled !== botStatus) editConversationReferenceDetails(JobGuid, botStatus, jobId);
    const [postQuestionsReponse, postAnswersResponse] = await Promise.all([
      postQuestions(BotContextId, questions),
      postAnswers(BotContextId, answers),
    ]);
    if ([postAnswersResponse, postQuestionsReponse].includes(false)) {
      message.error('Failed to save. Please try again');
      return;
    }
    message.success('Saved successfully');
    onClose();
  }

  render() {
    const {
      frequentQuestions,
      frequentQuestionGroups,
      contextById,
      currentAryabotTemplateIds,
      aryabotTemplatesById,
      getTemplateQuestions,
      jobId,
      jobsById,
      whiteLabelInfo,
      postAnswersApiStatus,
      postQuestionsApiStatus,
      generateQuestions,
      generateQuestionsApiStatus,
      getJobQuestionApiStatus,
    } = this.props;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    const { BotContextId, IsBotEnabled } = _.get(jobsById, [jobId], {});

    return (
      <BotConfig
        frequentQuestions={frequentQuestions}
        frequentQuestionGroups={frequentQuestionGroups}
        onSave={this.onSave}
        questions={_.get(contextById, [BotContextId, 'Questions'], [])}
        answers={_.get(contextById, [BotContextId, 'Answers'], {})}
        currentAryabotTemplateIds={currentAryabotTemplateIds}
        aryabotTemplatesById={aryabotTemplatesById}
        getTemplateQuestions={getTemplateQuestions}
        isBotEnabled={IsBotEnabled}
        AppName={AppName}
        saveLoading={[postAnswersApiStatus, postQuestionsApiStatus].includes('INPROGRESS')}
        jobDescription={_.get(contextById, [BotContextId, 'Answers', 'JobDescription'], '')}
        BotContextId={BotContextId}
        generateQuestions={generateQuestions}
        generateQuestionsApiStatus={generateQuestionsApiStatus}
        contextById={contextById}
        getJobQuestionApiStatus={getJobQuestionApiStatus}
      />
    );
  }
}

export { BotConfigContainer as BotConfigContainerWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BotConfigContainer));
