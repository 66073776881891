export const BulkShortlistCreditInfoAlertStatus = 'BulkShortlistCreditInfoAlertStatus';

function getCandidateListPageSize({ currentUserEmail = '', paginationId }) {
  const candidateListPageSizeConfig = localStorage.getItem(`CandidateListPageSize:${paginationId}`);
  const parsedCandidateListConfig = candidateListPageSizeConfig && JSON.parse(candidateListPageSizeConfig);
  const candidateListPageSize = parsedCandidateListConfig?.[currentUserEmail];
  return Number(candidateListPageSize ?? 0);
}

const getStorageData = storageKey => {
  return JSON.parse(localStorage.getItem(storageKey));
};

const getUserSkillSearchPreference = () => {
  return 'AI';
  // const userSkillSearchPreference = JSON.parse(localStorage.getItem('userSkillSearchPreference') || '{}');
  // return userSkillSearchPreference[userGuid];
};

const setUserSkillSearchPreference = (preference, userGuid) => {
  const userSkillSearchPreference = JSON.parse(localStorage.getItem('userSkillSearchPreference') || '{}');
  userSkillSearchPreference[userGuid] = preference;
  localStorage.setItem('userSkillSearchPreference', JSON.stringify(userSkillSearchPreference));
};

export { getCandidateListPageSize, getStorageData, getUserSkillSearchPreference, setUserSkillSearchPreference };
