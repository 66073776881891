import React from 'react';
import { Skeleton, Button, Modal } from 'antd';
import styles from './CandidateCardTertiaryContent.module.scss';
import CandidateCardNotesIcon from './CandidateCardNotesIcon';
import SocialIcon from '../Common/SocialIcon/SocialIcon';
import CandidateCardConnectOptions from './CandidateCardConnectOptions';
import { getCandidateApplicationPreviewBtn } from '../CandidateListItem/CandidateListItem';

function CandidateCardTertiaryContent(props) {
  const {
    onCandidateCardClick,
    candidate,
    maskingConfig,
    candidateContext,
    featureToggleList,
    connectStatusLoaded,
    showCandidateDetail,
    setComposeEmailType,
    indexKey,
    currentJobDetails,
    handleContactPull,
    showConnectWidget,
    status,
    candidateType,
    version,
    isCandidateViewIconVisible,
    isUndoModalVisible,
    setUndoModalVisibility,
    undoPortalApply,
  } = props;

  const jobGuid = currentJobDetails?.JobGuid;
  const candidateApplicationPreviewButton = getCandidateApplicationPreviewBtn(jobGuid, candidate, featureToggleList);
  const isUndoAppliedEnabled =
    featureToggleList?.UndoCandidateApplication?.IsEnabled && status?.toLowerCase() === 'applied';
  const applicationPreviewBtn = candidateApplicationPreviewButton || null;

  const onUndoPortal = () => {
    undoPortalApply(currentJobDetails.JobGuid, candidate.Id);
    setUndoModalVisibility(false);
  };

  return (
    <Skeleton active loading={!connectStatusLoaded} rows={1} title={false}>
      <div className={styles.candidateCardTertiaryContent}>
        {applicationPreviewBtn}
        {isUndoAppliedEnabled ? (
          <Button
            type="link"
            onClick={e => {
              e.stopPropagation();
              setUndoModalVisibility(true);
            }}
            className={styles.linkButton}
          >
            Undo Apply
          </Button>
        ) : (
          <></>
        )}
        <CandidateCardNotesIcon
          onCandidateCardClick={onCandidateCardClick}
          candidate={candidate}
          featureToggleList={featureToggleList}
          candidateType={candidateType}
          version={version}
        />
        <SocialIcon candidate={candidate} isActive={maskingConfig?.IsActive} />
        <CandidateCardConnectOptions
          featureToggleList={featureToggleList}
          connectStatusLoaded={connectStatusLoaded}
          showCandidateDetail={showCandidateDetail}
          setComposeEmailType={setComposeEmailType}
          candidate={candidate}
          indexKey={indexKey}
          currentJobDetails={currentJobDetails}
          handleContactPull={handleContactPull}
          showConnectWidget={showConnectWidget}
          candidateContext={candidateContext}
          isCandidateViewIconVisible={isCandidateViewIconVisible}
        />

        <div
          role="presentation"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Modal
            visible={isUndoModalVisible}
            onCancel={() => {
              setUndoModalVisibility(false);
            }}
            okText="Confirm"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            onOk={onUndoPortal}
            closable={false}
          >
            <div className={styles.undoModalContent}>
              Kindly confirm the request to withdraw your job application from the careerportal
            </div>
          </Modal>
        </div>
      </div>
    </Skeleton>
  );
}

export default CandidateCardTertiaryContent;
