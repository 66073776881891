import React from 'react';
import { Skeleton, Tooltip } from 'antd';
import _ from 'lodash';
import { AutomationOnIcon, AutomationOffIcon, EditIcon } from '../../Icons/AryaIcons';
import styles from './ListDetailHeader.module.scss';
import CollaboratorsContainer from '../../Containers/CollaboratorsContainer/CollaboratorsContainer';
import AddListModal from '../../Containers/Lists/Modals/AddListModal';
import CreateCampaignIcon from '../../Icons/CreateCampaignIcon';
import BulkAdditionProgressIndicator from '../BulkAdditionProgressIndicator/BulkAdditionProgressIndicator';
import { EllipsedText } from '../../Utils/TextUtils';
import ScoutingAgentInsideIcon from '../../Containers/ManualSearch/ScoutingAgent/ScoutingAgentInsideIcon';

function ListDetailHeader(props) {
  const {
    toggleSettingsDrawer,
    description,
    segmentTitle,
    jobDetailsStatus,
    isAutomationEnabled,
    jobId,
    currentJobDetails,
    usersById,
    form,
    editSegmentApiStatus,
    editSegment,
    fetchJobDetails,
    onSegmentCreationCallback,
    toggleCampaignDrawer,
    fetchActivationStatus,
    candidatesRecommendationStatus,
    isAutoMessageEnabled,
    featureToggleList,
  } = props;
  const [isSeeMore, setSeeMore] = React.useState(true);
  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
  const [inputText, setInputText] = React.useState('');
  const [isCampaignStatsToolTipVisible, setCampaignStatsToolTipVisiblity] = React.useState(false);
  const isSegmentCreationEnabled = featureToggleList?.SegmentCreation?.IsEnabled;
  const segmentDetails = {};
  const onDescriptionChange = value => {
    setInputText(value);
  };

  const isLoading = editSegmentApiStatus === 'INPROGRESS';

  const automationIconStyle = {
    borderRadius: '4px',
    border: '1px solid #dadbdd',
  };

  React.useEffect(() => {
    fetchJobDetails(jobId);
  }, []);

  React.useEffect(() => {
    if (isEditModalVisible) {
      form.setFieldsValue({ listName: segmentTitle, listDescription: description });
    }
  }, [isEditModalVisible]);

  React.useEffect(() => {
    fetchActivationStatus([jobId]);
  }, [fetchActivationStatus, jobId]);

  const showEditModal = () => {
    setIsEditModalVisible(true);
    onDescriptionChange(description);
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
    setInputText('');
    form.setFieldsValue({ listName: undefined, listDescription: undefined });
  };
  const handleOk = async () => {
    const formValues = form.getFieldsValue();
    const { listName, listDescription } = formValues;
    segmentDetails.JobTitle = listName;
    segmentDetails.Description = listDescription;
    await editSegment(jobId, segmentDetails, onSegmentCreationCallback);
    setIsEditModalVisible(false);
    form.setFieldsValue({ listName: undefined, listDescription: undefined });
  };

  const handleButtonClick = () => setSeeMore(!isSeeMore);
  const buttonName = isSeeMore ? 'Show More' : 'Show Less';
  let automationEnabledClassName = '';
  if (isAutomationEnabled) {
    automationEnabledClassName = 'automation-on';
  }
  const getAutomationTooltipTitle = () => {
    let tooltipTitle;
    if (isAutomationEnabled && isAutoMessageEnabled) {
      tooltipTitle = 'Email and Text automation is on';
    } else if (isAutomationEnabled && !isAutoMessageEnabled) {
      tooltipTitle = 'Email Automation is on';
    } else if (!isAutomationEnabled && isAutoMessageEnabled) {
      tooltipTitle = 'Text automation is on';
    } else {
      tooltipTitle = 'Email and Text automation is off';
    }
    return tooltipTitle;
  };
  const isDescriptionNotAvailable = description === undefined || description === '';
  const updatedDescription = isSeeMore ? <EllipsedText text={description} maxTextLength={40} /> : description;
  const updatedTitle = <EllipsedText text={segmentTitle} maxTextLength={20} />;
  const isShowButtonVisible = description?.length > 80;
  const showButton = isShowButtonVisible ? (
    <span className={styles.seeMoreButton} onClick={handleButtonClick} role="presentation">
      {buttonName}
    </span>
  ) : null;
  let allUsers = [];
  if (usersById) {
    allUsers = allUsers.concat(currentJobDetails?.SharedWith || []);
    allUsers = allUsers.concat(currentJobDetails?.AssignedTo || []);
  }
  const getUserName = id => {
    return usersById[id] ? usersById[id].FullName : null;
  };

  const getUserEmail = id => {
    return usersById[id] ? usersById[id].Email : null;
  };

  let users = _.uniq(allUsers).map(userId => {
    return {
      name: getUserName(userId),
      Id: userId,
      Email: getUserEmail(userId),
    };
  });
  users = users.filter(user => !!user);

  if (jobDetailsStatus === 'INPROGRESS') return <Skeleton paragraph={{ rows: 5 }} loading active />;
  return (
    <div className={styles.listHeader}>
      <div className={styles.headerAndBulkProgressIndicator}>
        <div className={styles.listInfoContainer}>
          <div className={styles.listNameWrapper}>
            <div className={styles.listName}>{updatedTitle}</div>
            <div className={styles.edit} onClick={showEditModal} onKeyPress={showEditModal} role="button" tabIndex={0}>
              {isSegmentCreationEnabled ? <EditIcon fill="grey" /> : null}
            </div>
          </div>
          {isDescriptionNotAvailable ? (
            jobId
          ) : (
            <div className={styles.listDescription}>
              {jobId} | Description: {updatedDescription} &nbsp;
              {showButton}
            </div>
          )}
        </div>
        {isEditModalVisible ? (
          <AddListModal
            isModalVisible={isEditModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
            title="Edit name and description"
            buttonName="Save"
            form={form}
            isLoading={isLoading}
            inputText={inputText}
            characterLimit={200}
            onDescriptionInputChange={onDescriptionChange}
            modalType="Segment"
          />
        ) : null}
        {candidatesRecommendationStatus?.ActiveRequestCount ? (
          <div className={styles.bulkProgressModel}>
            <BulkAdditionProgressIndicator bulkAdditionProgressStats={candidatesRecommendationStatus} />
          </div>
        ) : null}
      </div>
      <div className={styles.segmentActions}>
        <CollaboratorsContainer
          collaborators={users}
          shareSize="small"
          jobId={jobId}
          candidateContext="segment"
          isDetailHeader
        />
        <ScoutingAgentInsideIcon jobId={jobId} candidateContext="segments" />
        <div className={styles.toolIcons}>
          <Tooltip placement="bottom" className="automation-tooltip" title={getAutomationTooltipTitle()}>
            <div
              role="presentation"
              onClick={toggleSettingsDrawer}
              className={`automation-config ${automationEnabledClassName}`}
              style={automationIconStyle}
            >
              {isAutomationEnabled || isAutoMessageEnabled ? (
                <AutomationOnIcon key="automationOnIcon" className="automation-icon" />
              ) : (
                <AutomationOffIcon key="automationOffIcon" className="automation-icon" />
              )}
            </div>
          </Tooltip>
        </div>
        <Tooltip placement="bottom" title="Campaign stats & history" visible={isCampaignStatsToolTipVisible}>
          <div
            role="presentation"
            onClick={() => {
              toggleCampaignDrawer();
              setCampaignStatsToolTipVisiblity(false);
            }}
            className={styles.createCampaignIcon}
            onMouseMove={() => setCampaignStatsToolTipVisiblity(true)}
            onMouseLeave={() => setCampaignStatsToolTipVisiblity(false)}
          >
            <CreateCampaignIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default ListDetailHeader;
