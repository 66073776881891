import React from 'react';
import { Empty } from 'antd';
import classNames from 'classnames';
import { EmptyCandidatesIcon } from '../../Icons/AryaIcons';
import styles from './SubSegmentStyles.module.scss';

const EmptyScreen = () => {
  return (
    <div className={styles.emptyScreen}>
      <Empty
        image={<EmptyCandidatesIcon className={classNames(styles.emptySubsegment, 'candidate-empty-list')} />}
        description={
          <div className={styles.emptySubsegmentText}>
            <h2>No Sub-Segments found</h2>
            <p>Use ‘Create New’ button above to create the new Sub-Segment</p>
          </div>
        }
      />
    </div>
  );
};

export default EmptyScreen;
