import _ from 'lodash';

export const getFormattedMissingSkills = (
  value,
  missingSkillInputValue,
  isDoubleQuotesAllowed,
  setIsDoubleQuotesAllowed
) => {
  const hasWhitespace = /\s/.test(value);
  const surroundedByQuotes = /^".*"$/;
  const singleDoubleQuoteRegex = /^([^"]*"){1}[^"]*$/;
  const prevValueHasDoubleQuotes = surroundedByQuotes.test(missingSkillInputValue);
  if (value?.trim() && hasWhitespace && !/"/.test(missingSkillInputValue) && isDoubleQuotesAllowed) {
    return `"${value}"`;
  }
  if (prevValueHasDoubleQuotes && singleDoubleQuoteRegex.test(value)) {
    if (missingSkillInputValue?.length > 2) setIsDoubleQuotesAllowed(false);
    return value.replace(/"/g, '');
  }
  if (!value?.length || value === '"') setIsDoubleQuotesAllowed(true);
  return value;
};

export const filterUniqueSkills = (combinedMustHavesAndCurrentMissingSkills = {}) => {
  const uniqueSkillsSet = new Set();
  const filteredCombinedMustHaves = combinedMustHavesAndCurrentMissingSkills;
  Object.keys(filteredCombinedMustHaves).forEach(key => {
    const skills = (filteredCombinedMustHaves[key]?.selectedSkills.join('-') || '').toLowerCase();

    if (uniqueSkillsSet.has(skills) || skills?.length === 0) {
      delete filteredCombinedMustHaves[key];
    } else {
      uniqueSkillsSet.add(skills);
    }
  });

  return filteredCombinedMustHaves;
};

export const updatedCandidateMissingSkill = currentMissingSkills => {
  const updatedMissingSkills = _.cloneDeep(currentMissingSkills);
  Object.keys(updatedMissingSkills)?.forEach(key => {
    const skills = updatedMissingSkills[key]?.selectedSkills;
    const hasDoubleQuotes = skills ? skills.join('').includes('"') : false;
    if (!hasDoubleQuotes && skills[0]?.includes(' ')) {
      skills[0] = `"${skills[0]}"`;
    }
  });
  return updatedMissingSkills || {};
};

export const missingSkillsAfterSelectOrDeSelect = (currentIndex, mustHaves = {}, currentMissingSkills = {}) => {
  const currentSkill = {};

  if (mustHaves[currentIndex] === undefined) {
    currentSkill[currentIndex] = currentMissingSkills[currentIndex];
  } else {
    currentSkill[currentIndex] = mustHaves[currentIndex];
  }
  return { ...currentMissingSkills, ...currentSkill };
};

export const checkIfDuplicateIsPresent = (currentSkill, combinedMustHavesAndCurrentMissingSkills) => {
  let isDuplicatePresent = false;

  Object.keys(combinedMustHavesAndCurrentMissingSkills)?.forEach(key => {
    const currentSkills = combinedMustHavesAndCurrentMissingSkills[key]?.selectedSkills;

    const sanitizeSkill = skill => {
      if (skill?.length && skill.charAt(0) === '"' && skill.charAt(skill.length - 1) === '"') {
        return `${skill.substring(1, skill.length - 1).toLowerCase()}`;
      }
      return skill.toLowerCase();
    };
    const cleanedCurrentSkill = sanitizeSkill(currentSkill);

    const isUniqueSkill = !currentSkills?.find(
      selectedSkill => selectedSkill.toLowerCase() === currentSkill.toLowerCase()
    );

    if (!isUniqueSkill) {
      isDuplicatePresent = true;
    }
  });

  return isDuplicatePresent;
};

export const filterUniqueSkills1 = (mustHaves = {}, currentMissingSkills = {}) => {
  const skillsOfCurrentMissingSkills = new Set();

  Object.keys(currentMissingSkills).forEach(key => {
    const skills = (currentMissingSkills[key]?.selectedSkills || []).join('-').toLowerCase();
    skillsOfCurrentMissingSkills.add(skills);
  });
  const filterMustHaves = mustHaves;
  Object.keys(filterMustHaves).forEach(key => {
    const skills = (filterMustHaves[key]?.selectedSkills || []).join('-').toLowerCase();

    if (skillsOfCurrentMissingSkills.has(skills) || skills.length === 0) {
      delete filterMustHaves[key];
    }
  });

  return filterMustHaves;
};
