import React from 'react';
import { InputNumber, Form } from 'antd';
import { validateDistance } from '../../../Utils/FormValidators';
import MultiLocationContext from '../MultiLocationContext';

const MileSelect = props => {
  const {
    isRemoteJob,
    isCountryWideSearchEnabled,
    initialRange,
    isManualSearchFormMinimized,
    shouldHideManualSearchMiles,
    candidateContext = 'job',
    currentKey,
    currentLocation,
    locationContext,
  } = props;

  const { isStateWideSearchChecked, onMilesChange, Distance, setMilesInputValidationError, milesInputValidationError } =
    React.useContext(MultiLocationContext);
  const getInitialRange = () => {
    if (initialRange) return Math.min(initialRange, 999);
    if (candidateContext === 'segment') return 100;
    return 5;
  };

  const inputStyle = { width: isManualSearchFormMinimized ? '122px' : '144px' };
  const isMilesDisabled = isCountryWideSearchEnabled || isRemoteJob || isStateWideSearchChecked(currentKey);
  const shouldHideMilesValidation = locationContext === 'Region' ? !currentLocation.Region : !currentLocation.Location;

  const onMileChange = value => {
    validateDistance(undefined, value, setMilesInputValidationError, isMilesDisabled, true, shouldHideMilesValidation);
    onMilesChange(value);
  };

  React.useEffect(() => {
    if (isMilesDisabled) setMilesInputValidationError();
    else
      validateDistance(
        undefined,
        Distance,
        setMilesInputValidationError,
        isMilesDisabled,
        true,
        shouldHideMilesValidation
      );
  }, [isMilesDisabled, shouldHideMilesValidation]);

  return (
    <div style={{ display: shouldHideManualSearchMiles ? 'none' : 'block', maxWidth: '144px' }}>
      <Form.Item
        validateStatus={milesInputValidationError ? 'error' : 'success'}
        help={milesInputValidationError}
        initialValue={getInitialRange()}
        style={{ maxHeight: '54px' }}
      >
        <InputNumber
          value={Distance === 0 ? undefined : Distance}
          // defaultValue={getInitialRange()}
          style={inputStyle}
          min={5}
          max={999}
          placeholder={isManualSearchFormMinimized ? 'Maximum miles' : 'Maximum miles for sourcing'}
          disabled={isMilesDisabled}
          onChange={onMileChange}
          allowClear
        />
      </Form.Item>
    </div>
  );
};

export default MileSelect;
