import React, { useCallback, useEffect } from 'react';
import { Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setManualSearchFormMustHavesValue } from '../../../Actions/ManualSearchActions';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import CandidateRejectReasonsPopover from '../../CandidateRejectReasonsPopover/CandidateRejectReasonsPopover';
import RejectReasonsContentMapper from '../../RejectReasonsContentMapper/RejectReasonsContentMapper';
import {
  v2RejectReasonKeysToDisplayNameMapper,
  rejectReasonKeysToTitleMapper,
  getIsCurrentRejectReasonSelected,
  getIsWarningMessageVisible,
  getIsRejectReasonValuesChanged,
  getMergedRejectReasonValues,
  getRejectReasonsInputValues,
  missingMandatorySkills,
  getRejectReasonInitialValue,
  handleTagClick,
} from '../../../Utils/CandidateRejectReasonsUtils';
import { getSuggestions } from '../../../Reducers/SuggestionsReducer';
import { getJobsById } from '../../../Reducers/JobReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { MenuIcon } from '../../../Icons/AryaIcons';
import { fetchSuggestions, clearSuggestions } from '../../../Actions/SuggestionsActions';
import '../../CandidateRejectCard/CandidateRejectCard.scss';

function CandidateRejectReasonsV2(props) {
  const {
    candidateStatusReasons,
    onApplyRejectReason,
    onClearRejectReason,
    onResetRejectReason,
    negativeValues,
    jobId,
    candidateId,
    size,
    form,
    candidateConnectionStatus,
    onAddingMissingMandatorySkills,
    openInNewTab,
    manualSearchForm,
    isConnectTab,
  } = props;

  const dispatch = useDispatch();
  const suggestions = useSelector(state => getSuggestions(state, candidateId));
  const featureToggleList = useSelector(state => getFeatureToggleList(state));
  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  useEffect(() => {
    return () => {
      dispatch(clearSuggestions(candidateId));
    };
  }, []);

  const suggestionsFetchApiStatus = useSelector(state => getApiStatus(state, 'suggestionsFetchApiStatus'));
  const jobsById = useSelector(state => getJobsById(state));
  const currentJobDetails = jobsById[jobId] || {};
  const { MinExperience, MaxExperience } = currentJobDetails;
  const formValues = form.getFieldsValue();
  const missingMandatorySkillsFormValues = formValues.MISSING_MANDATORY_SKILLS || {};

  const applyRejectReason = useCallback(
    rejectReasonKey => {
      const selectedMandatorySkills = {};
      const rejectReasonInputKey = `${rejectReasonKey}-Input`;
      const formRejectReasonInputValue = form.getFieldValue(rejectReasonInputKey);
      Object.keys(missingMandatorySkillsFormValues).forEach(key => {
        selectedMandatorySkills[key] = {
          selectedSkills: missingMandatorySkillsFormValues[key].selectedSkills,
        };
      });
      if (!openInNewTab && isAdvancedSearchV2Enabled && !isConnectTab)
        onAddingMissingMandatorySkills(selectedMandatorySkills);

      if (formRejectReasonInputValue?.trim()) {
        const existingRejectReasonValues = form.getFieldValue(rejectReasonKey);
        const rejectReasonInputValues = getRejectReasonsInputValues(rejectReasonKey, formRejectReasonInputValue);
        const mergedRejectReasonValues = getMergedRejectReasonValues(
          rejectReasonKey,
          existingRejectReasonValues,
          rejectReasonInputValues
        );
        const updatedFormValues = { [rejectReasonInputKey]: '', [rejectReasonKey]: mergedRejectReasonValues };
        form.setFieldsValue({ ...updatedFormValues });
        if (rejectReasonKey === missingMandatorySkills)
          dispatch(
            fetchSuggestions({
              jobId,
              candidateId,
              skills: rejectReasonInputValues,
              suggestionsType: rejectReasonKey,
            })
          );
      }
      onApplyRejectReason(rejectReasonKey);
      if (isAdvancedSearchV2Enabled && !isConnectTab) {
        const manualSearchFormValues = manualSearchForm.getFieldsValue();
        const { MustHaves: mustHaves } = manualSearchFormValues;
        dispatch(setManualSearchFormMustHavesValue({ mustHaves, jobId }));
      }
    },
    [form]
  );

  return Object.keys(v2RejectReasonKeysToDisplayNameMapper).map(rejectReasonKey => {
    const rejectReasonTag = (
      <Tag
        className={`reject-reason-tag ${
          getIsCurrentRejectReasonSelected(rejectReasonKey, candidateStatusReasons)
            ? 'selected-reject-reason'
            : 'unselected-reject-reason'
        }`}
        key={rejectReasonKey}
        onClick={() => {
          handleTagClick(form, onApplyRejectReason, rejectReasonKey);
        }}
      >
        {rejectReasonKey === 'MORE_REASONS' ? <MenuIcon /> : null}
        {v2RejectReasonKeysToDisplayNameMapper[rejectReasonKey]}
      </Tag>
    );
    const isRejectReasonChanged = getIsRejectReasonValuesChanged(formValues, candidateStatusReasons, rejectReasonKey);
    if (rejectReasonKey === 'OPT_OUT' && (!candidateConnectionStatus || candidateConnectionStatus === 'NotConnected')) {
      return null;
    }
    if (rejectReasonKey === 'CANDIDATE_NOT_INTERESTED') {
      const initialValue = getRejectReasonInitialValue(candidateStatusReasons, rejectReasonKey);
      return form.getFieldDecorator('CANDIDATE_NOT_INTERESTED', { initialValue })(rejectReasonTag);
    }
    return (
      <CandidateRejectReasonsPopover
        content={
          <RejectReasonsContentMapper
            {...props}
            suggestions={suggestions}
            rejectReasonKey={rejectReasonKey}
            suggestionsFetchApiStatus={suggestionsFetchApiStatus}
            experienceRange={{ MinExperience, MaxExperience }}
            openInNewTab={openInNewTab}
            featureToggleList={featureToggleList}
          />
        }
        showWarningMessage={getIsWarningMessageVisible(rejectReasonKey, negativeValues)}
        title={rejectReasonKeysToTitleMapper[rejectReasonKey]}
        key={rejectReasonKey}
        rejectReasonKey={rejectReasonKey}
        onApplyRejectReason={applyRejectReason}
        onClearRejectReason={onClearRejectReason}
        onResetRejectReason={onResetRejectReason}
        size={size}
        okButtonText={isRejectReasonChanged ? 'Apply' : 'Done'}
        cancelButtonText="Clear"
        isCancelButtonDisabled={!isRejectReasonChanged}
      >
        {rejectReasonTag}
      </CandidateRejectReasonsPopover>
    );
  });
}

export default CandidateRejectReasonsV2;
