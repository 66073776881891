import React from 'react';
import { Skeleton, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Delay, Rule } from './WorkflowComponents';
import WorkflowEmail from './WorkflowEmail';
import styles from './CandidateWorkflow.module.scss';
import * as WorkflowReducer from '../../../Reducers/WorkflowReducer';
import * as EmailTemplatesReducer from '../../../Reducers/EmailTemplates';
import Candidate360EmailAndMessageEmptyScreen from '../Candidate360ContactInfo/Candidate360EmailAndMessageEmptyScreen';
import { getWorkflowData, createWorkflowData, addEmailTemplateWorkflowData } from './CandidateWorkflowUtils';

const mapStateToProps = (state, props) => {
  const { workflowId } = props;
  const workflowTemplatesById = WorkflowReducer.getWorkflowDripTemplatesById(state, workflowId);
  const workflowTemplateApiStatus = WorkflowReducer.getWorkflowApiStatusById(state, workflowId);
  return {
    workflowTemplatesById,
    workflowTemplateApiStatus,
    emailTemplateIds: EmailTemplatesReducer.getEmailTemplateIds(state),
    emailTemplatesById: EmailTemplatesReducer.getEmailTemplatesById(state),
  };
};

const CandidateWorkflow = props => {
  const [workflowData, setWorkflowData] = React.useState([]);
  const { workflowTemplatesById, workflowTemplateApiStatus, emailTemplatesById, workflowReportFilterData } = props;
  const { Activities, Connections, StartActivityId } = workflowTemplatesById;
  const workflowLength = Object.keys(workflowTemplatesById).length;
  const isWorkflowInitiated = workflowReportFilterData.Jobs?.length > 0;
  React.useEffect(() => {
    if (Array.isArray(Activities) && Activities.length > 0) {
      const orderWorkflow = createWorkflowData(workflowTemplatesById);
      const workflowActivites = getWorkflowData(orderWorkflow);
      const addEmailTemplate = addEmailTemplateWorkflowData(workflowActivites, emailTemplatesById);
      setWorkflowData(addEmailTemplate);
    }
  }, [workflowLength, Activities, Connections, StartActivityId]);

  const onMailClick = id => {
    const newWorkflowData = workflowData.map(item => {
      const newItem = { ...item };
      if (newItem.Type === 'AryaConnectSendEmail') {
        if (newItem.ActivityId === id) newItem.State = 'max';
        else newItem.State = 'min';
      }
      return newItem;
    });
    setWorkflowData(newWorkflowData);
  };

  const onChangeEmailContent = (type, content, id) => {
    const newWorkflowData = workflowData.map(item => {
      const newItem = { ...item };
      if (newItem.ActivityId === id) newItem[type] = content;
      return newItem;
    });
    setWorkflowData(newWorkflowData);
  };

  if (workflowData.length === 0)
    return (
      <div className={styles.defaultScreenContainer}>
        <Candidate360EmailAndMessageEmptyScreen
          activeTab="workflow"
          workflowheaderMessage="You don’t have existing workflows"
          infoMessage="Create workflow to get started"
          hideButton
        />
        <Link
          to={{
            pathname: '/connect',
            search: '?tab=workflows',
            state: { chooseDefaultWorkflow: true },
          }}
        >
          <Button type="primary" shape="round">
            Create Workflow
          </Button>
        </Link>
      </div>
    );

  return (
    <Skeleton loading={workflowTemplateApiStatus === 'InProgress'}>
      <div className={styles.CandidateWorkflowContainer}>
        <div className={styles.IconLineContainer}>
          <div className={styles.IconLine}></div>
        </div>
        <div className={styles.CandidateWorkflowContent}>
          {workflowData.map(item => {
            if (item.Type === 'AryaConnectSendEmail')
              return (
                <div className={styles.mailContainer}>
                  <div className={styles.mailIconContainer}>
                    <Icon type="mail" />
                  </div>
                  <WorkflowEmail
                    disabled={isWorkflowInitiated}
                    renderState={item.State}
                    body={item.Body}
                    subject={item.Subject}
                    description={item.Description}
                    onClick={onMailClick}
                    status={item.status}
                    id={item.ActivityId}
                    onChangeEmailContent={onChangeEmailContent}
                  />
                </div>
              );
            if (item.Type === 'If')
              return (
                <div>
                  <Rule
                    key={item.ActivityId}
                    firstRuleText={` ${item.DisplayName}: ${item.Description}`}
                    secondRuleText={item.nextRule?.text}
                  />
                </div>
              );
            return <Delay key={item.ActivityId} text={` ${item.DisplayName}: ${item.Description}`} />;
          })}
        </div>
      </div>
    </Skeleton>
  );
};

export default connect(mapStateToProps, null)(CandidateWorkflow);
export { CandidateWorkflow as CandidateWorkflowWithoutStore };
