import React from 'react';
import { Modal } from 'antd';
import CreditInfoAlert from '../CreditInfo/CreditInfoAlert';

function BulkShortlistModal(props) {
  const {
    visible,
    credits,
    onCloseCreditInfoAlert,
    selectedActiveCandidateCount,
    currentUser,
    isPaidJobServiceEnabled,
    onCancel,
    bulkCandidateShortlist,
    candidateContext,
    availableCredits,
    userConfig,
  } = props;

  const handleSubmit = e => {
    e.preventDefault();
    bulkCandidateShortlist();
  };

  const modalTitle = candidateContext === 'job' ? 'Bulk Shortlist' : 'Bulk Add';
  const text = candidateContext === 'job' ? 'Shortlist' : 'Add';
  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      okText={text}
      style={{ width: '26%', minWidth: 600 }}
    >
      <CreditInfoAlert
        credits={credits}
        onCloseCreditInfoAlert={onCloseCreditInfoAlert}
        selectedActiveCandidateCount={selectedActiveCandidateCount}
        currentUser={currentUser}
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
        statusKey="BulkShortlistCreditInfoAlertStatus"
        candidateContext={candidateContext}
        availableCredits={availableCredits}
        userConfig={userConfig}
        showCreditInfoAlert
      />
    </Modal>
  );
}

export { BulkShortlistModal };
