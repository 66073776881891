import React from 'react';
import { Tooltip, Button, Popover } from 'antd';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { getConfig } from '../../../Reducers/ConfigReducer';
import {
  generateAryaActivatePayloadWithoutPersonalInfo,
  aryaRankingDisabledPopover,
} from '../../../Utils/ManualSearchUtils';
import { getIsReactivationAllowed, getManualSearchCriteria } from '../../../Reducers/ManualSearchReducer';
import ActivationDialog from '../../ActivationDialog/ActivationDialog';
import styles from './ReactivateArya.module.scss';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer';
import { InfoIconV2 } from '../../../Icons/AryaIcons';
import { getIsScoreAndRankAndManualSearchDisabled } from '../../../Utils/JobUtils';
import { getAdminFeatures } from '../../../Reducers/UserReducer';
import { getCandidatePublishStatus } from '../../../Utils/getCandidatePublishStatus';

const mapStateToProps = (state, props) => ({
  userConfig: getConfig(state),
  unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
  isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
  featureToggleList: getFeatureToggleList(state),
  adminFeatures: getAdminFeatures(state),
});

function ReactivateArya(props) {
  const {
    jobId,
    currentJobDetails = {},
    userConfig,
    unsavedCriteria,
    isReactivationAllowed,
    AppName,
    featureToggleList,
    smartRecruiterVersion,
    isDragging = false,
    setIsDragging,
    onStatusChange,
    activeTab,
    adminFeatures,
  } = props;

  const [activationDialogVisibility, setActivationDialogVisibility] = React.useState(false);
  const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
  if (isCandidatePublishEnabled) return null;

  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  let reactivateArya = `Reactivate ${AppName}`;
  let reactivationNotAllowedTooltipContent =
    'After editing the search criteria under Arya Search, click on the ‘Search’ button before Arya can ‘Reactivating’';
  if (isAdvancedSearchV2Enabled || smartRecruiterVersion === 'v2') {
    reactivationNotAllowedTooltipContent =
      'After editing the search criteria under Arya Search, click on the ‘Search’ button before Arya can ‘Score and Rank’';
    reactivateArya = 'Score and Rank';
  }
  const eventControl = event => {
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  const unsavedCriteriaWithoutPersonalInfo = generateAryaActivatePayloadWithoutPersonalInfo({
    criteria: unsavedCriteria,
  });

  const closeActivationDialog = () => {
    setActivationDialogVisibility(false);
  };

  const onReactivateAryaClick = e => {
    if (isReactivationAllowed && !isDragging) {
      setActivationDialogVisibility(true);
    }
  };

  let showReactivateAryaButton = true;

  const { IsAllowed: isAryaRankingAllowed, IsEnabled: isAryaRankingEnabled } = featureToggleList?.AryaRanking || {};
  if (isAdvancedSearchV2Enabled) {
    showReactivateAryaButton = isAryaRankingEnabled || smartRecruiterVersion === 'v2';
  }

  let isReactivateAryaButtonDisabled = !isReactivationAllowed;
  if (isAdvancedSearchV2Enabled && !isAryaRankingAllowed) {
    isReactivateAryaButtonDisabled = smartRecruiterVersion !== 'v2';
  }
  const isScoreAndRankButtonDisabled = getIsScoreAndRankAndManualSearchDisabled({
    userConfig,
    currentJobDetails,
    isAdvancedSearchV2: isAdvancedSearchV2Enabled,
    candidateContext: 'job',
  });
  const isAryaJobStatusOpen = currentJobDetails.AryaStatus?.toLowerCase() === 'open';
  const scoreAndRankButtonDisabledTooltip = isAryaJobStatusOpen
    ? ''
    : `Can't perform score and rank on a ${currentJobDetails.AryaStatus?.toLowerCase()} job`;

  const buttonClassNames = [
    styles.reactivateAryaButton,
    isAdvancedSearchV2Enabled ? styles.draggableReactivateButton : '',
    isReactivateAryaButtonDisabled || isScoreAndRankButtonDisabled ? styles.disabled : '',
  ]
    .filter(Boolean)
    .join(' ');

  const buttonTextClassNames = [
    styles.reactivateAryaButtonText,
    isAdvancedSearchV2Enabled ? styles.draggableReactivateButtonText : '',
  ].join(' ');

  const reactivationTooltip = isReactivationAllowed ? '' : reactivationNotAllowedTooltipContent;
  const reactivateButtonTooltip = isScoreAndRankButtonDisabled
    ? scoreAndRankButtonDisabledTooltip
    : reactivationTooltip;

  const isScoreAndRankInfoIconVisible =
    isAdvancedSearchV2Enabled && (isReactivateAryaButtonDisabled || isScoreAndRankButtonDisabled);

  const reactivateAryaButton = (
    <div className={styles.reactivateArya} style={{ display: showReactivateAryaButton ? 'block' : 'none' }}>
      <Tooltip title={reactivateButtonTooltip} mouseEnterDelay={0.5} defaultOpen overlayStyle={{ position: 'fixed' }}>
        <Button
          className={buttonClassNames}
          type="secondary"
          shape="round"
          onClick={onReactivateAryaClick}
          disabled={isReactivateAryaButtonDisabled || isScoreAndRankButtonDisabled}
        >
          <span className={buttonTextClassNames}>{reactivateArya}</span>
          {isScoreAndRankInfoIconVisible ? (
            <span className={styles.infoIcon}>
              <InfoIconV2 style={{}} />
            </span>
          ) : null}
        </Button>
      </Tooltip>
    </div>
  );

  let showPopover = isAdvancedSearchV2Enabled && !isAryaRankingAllowed;
  if (smartRecruiterVersion === 'v2') showPopover = false;

  const reactivateAryaButtonWithPopover = showPopover ? (
    <Popover
      trigger="hover"
      overlayStyle={{ width: '450px', height: 'fit-content', position: 'fixed' }}
      placement="left"
      content={aryaRankingDisabledPopover}
    >
      {reactivateAryaButton}
    </Popover>
  ) : (
    reactivateAryaButton
  );

  return (
    <>
      <Draggable
        defaultClassName={isAdvancedSearchV2Enabled && 'draggedReactivateArya'}
        axis="both"
        positionOffset={isAdvancedSearchV2Enabled ? { x: '0%', y: '0%' } : {}}
        onDrag={eventControl}
        onStop={eventControl}
        disabled={!isAdvancedSearchV2Enabled}
        scale={1}
      
      >
        
        {reactivateAryaButtonWithPopover}
      </Draggable>
      <ActivationDialog
        jobId={jobId}
        visible={activationDialogVisibility}
        handleCancel={closeActivationDialog}
        job={currentJobDetails}
        aryaVersion={userConfig?.SubscriptionType}
        searchCriteria={unsavedCriteriaWithoutPersonalInfo}
        type="manualSearch"
        featureToggleList={featureToggleList}
        smartRecruiterVersion={smartRecruiterVersion}
        onStatusChange={onStatusChange}
        activeTab={activeTab}
      />
    </>
  );
}

export default connect(mapStateToProps)(ReactivateArya);
export { ReactivateArya as ReactivateAryaWithoutStore };
