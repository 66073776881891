import { Select, Form, DatePicker, Radio, Button } from 'antd';
import React from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment';
import styles from './EmailWorkflow.module.scss';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';

const FormItem = Form.Item;
function EmailWorkflow(props) {
  const {
    workflowDripTemplatesById,
    workflowDripTemplatesCount,
    searchWorkflowTemplates,
    currentWorkflowDripTemplateIds,
    form,
    setDefinitionId,
    setStartTime,
    setEndTime,
    setCampaignTriggeredEndTime,
    isSendToOptionAllowed,
    isEmailAutomationNoteAllowed,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    selectedEmailTemplateSendTo,
    onEmailSendToChange,
    sendEmailTo,
    getIsEndTimeValid,
    getIsCampaignEndTimeValid,
    definitionId,
    startTime,
    endTime,
    campaignTriggeredEndTime,
    setWorkflowDefinitionName,
    version,
    openWorkflowinNewTab,
    featureToggleList,
  } = props;

  const {
    AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled } = {},
    AryaRanking: { IsAllowed: isAryaRankingAllowed } = {},
  } = featureToggleList || {};

  const workflowTemplatesByIdLength = Object.keys(workflowDripTemplatesById)?.length;
  React.useEffect(() => {
    if (workflowTemplatesByIdLength) {
      form.setFieldsValue({
        SelectedWorkflowTemplate: definitionId,
      });
    }
  }, [workflowTemplatesByIdLength]);
  const [isWorkflowShared, setIsWorkflowShared] = React.useState(false);
  const dateFormat = 'MM/DD/YYYY';
  const formattedStartDate = moment(startTime);

  const formattedEndDate = !endTime ? undefined : moment(endTime);
  const formattedTriggeredEndDate = !campaignTriggeredEndTime
    ? undefined
    : moment(moment.utc(campaignTriggeredEndTime).local().format('MM/DD/YYYY'));
  const handleSearch = debounce(value => {
    searchWorkflowTemplates({ page: 0, pageSize: 10, searchTerm: value, isDraft: false });
  }, 600);

  React.useEffect(() => {
    searchWorkflowTemplates({ page: 0, pageSize: 50, searchTerm: '', isDraft: false });
  }, []);

  const handleDropdownScroll = e => {
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      currentWorkflowDripTemplateIds.length < workflowDripTemplatesCount
    ) {
      searchWorkflowTemplates({ page: currentWorkflowDripTemplateIds.length / 10, pageSize: 10, isDraft: false }, true);
    }
  };
  const handleOnClear = () => {
    form.setFieldsValue({ SelectedWorkflowTemplate: undefined });
    setDefinitionId(undefined);
    setWorkflowDefinitionName(undefined);
  };
  React.useEffect(() => {
    const workflowDefinitionId = form.getFieldValue('SelectedWorkflowTemplate');
    const SelectedWorkflowDefinitionName = workflowDripTemplatesById?.[workflowDefinitionId]?.Name;
    const initialName = 'This Workflow is not shared with you';
    if (!SelectedWorkflowDefinitionName && Object.keys(workflowDripTemplatesById)?.length && workflowDefinitionId) {
      form.setFieldsValue({
        SelectedWorkflowTemplate: initialName,
      });
      setIsWorkflowShared(true);
    }
  }, [startTime, endTime, campaignTriggeredEndTime, definitionId, Object.keys(workflowDripTemplatesById)?.length]);
  const handleOnWorkflowClick = () => {
    const workflowDefinitionId = form.getFieldValue('SelectedWorkflowTemplate');
    if (version === 'ats') {
      openWorkflowinNewTab(workflowDefinitionId);
    } else {
      window.open(`/connect?tab=workflows&workflowId=${workflowDefinitionId}`);
    }
  };
  const handleSelect = value => {
    setDefinitionId(value);
    const SelectedWorkflowDefinitionName = workflowDripTemplatesById?.[value]?.Name;
    setWorkflowDefinitionName(SelectedWorkflowDefinitionName);
    setIsWorkflowShared(false);
  };
  const handleStartDateChange = (value, date) => {
    setStartTime(date);
  };
  const handleEndDateChange = (value, date) => {
    setEndTime(date);
  };
  const handleOnTriggerCampaignEndDateChange = (value, date) => {
    setCampaignTriggeredEndTime(date);
  };
  return (
    <div>
      <div className={styles.selectAndButtonWrapper}>
        {form.getFieldDecorator('SelectedWorkflowTemplate', {
          initialValue: form.getFieldValue('SelectedWorkflowTemplate'),
        })(
          <Select
            placeholder="Choose a workflow"
            showSearch
            optionFilterProp="value"
            className={styles.workflowSelect}
            dropdownStyle={{ zIndex: '2200' }}
            onSearch={value => handleSearch(value)}
            onPopupScroll={handleDropdownScroll}
            filterOption={false}
            onSelect={handleSelect}
          >
            {currentWorkflowDripTemplateIds?.map(templateId => {
              const workflow = workflowDripTemplatesById[templateId];
              return (
                <Select.Option key={workflow?.DefinitionId} value={workflow?.DefinitionId}>
                  {workflow?.Name}
                </Select.Option>
              );
            })}
          </Select>
        )}
        <div className={styles.linkButtons}>
          <Button type="link" onClick={handleOnClear}>
            <span>Clear</span>
          </Button>
          {!isWorkflowShared ? (
            <>
              |
              <Button
                type="link"
                onClick={handleOnWorkflowClick}
                disabled={!form.getFieldValue('SelectedWorkflowTemplate')}
              >
                <span>Go to Workflow</span>
              </Button>
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.startEndDate}>
        <FormItem label="Start" required colon={false}>
          <DatePicker
            defaultValue={formattedStartDate}
            className={styles.datePicker}
            dropdownClassName={styles.datePickerDropdown}
            format={dateFormat}
            placeholder="MM/DD/YYYY"
            onChange={handleStartDateChange}
          />
        </FormItem>
        <FormItem
          label="End"
          colon={false}
          validateStatus={getIsEndTimeValid() ? '' : 'error'}
          help={getIsEndTimeValid() ? '' : 'End time cannot be less than start time'}
        >
          <DatePicker
            className={styles.datePicker}
            defaultValue={formattedEndDate}
            dropdownClassName={styles.datePickerDropdown}
            format={dateFormat}
            placeholder="MM/DD/YYYY"
            onChange={handleEndDateChange}
          />
        </FormItem>
      </div>
      <div className={styles.triggeredEndTimeSelect}>
        {/* {form.getFieldDecorator('isTriggeredEndDateTimeChecked')(
          <Checkbox onChange={handleCheckbox}>Do not start campaign after</Checkbox>
        )} */}
        <FormItem
          colon={false}
          label="Do not start campaign after"
          validateStatus={getIsCampaignEndTimeValid() ? '' : 'error'}
          help={getIsCampaignEndTimeValid() ? '' : 'Campaign end time should be between start time and end time'}
        >
          <DatePicker
            className={styles.datePicker}
            defaultValue={formattedTriggeredEndDate}
            dropdownClassName={styles.datePickerDropdown}
            format={dateFormat}
            placeholder="MM/DD/YYYY"
            onChange={handleOnTriggerCampaignEndDateChange}
          />
        </FormItem>
      </div>
      <br />
      {isSendToOptionAllowed ? (
        <div className={styles.sendToContainer}>
          <div className={styles.sendToLabel}>Send To</div>
          <Radio.Group value={selectedEmailTemplateSendTo} onChange={onEmailSendToChange}>
            <Radio value={sendEmailTo.shortlistedIdentifier}>Shortlisted Candidates</Radio>
            <Radio value={sendEmailTo.allIdentifier}>All</Radio>
            {isAdvanceSearchV2Enabled && !isAryaRankingAllowed ? null : (
              <Radio value={sendEmailTo.userGivenIdentifier}>User Given with shortlisted</Radio>
            )}
          </Radio.Group>
        </div>
      ) : null}
      {isEmailAutomationNoteAllowed ? (
        <BulkCandidatesDownloadFilterInfo
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          workflowTemplate
        />
      ) : null}
    </div>
  );
}

export default EmailWorkflow;
