/* eslint-disable react/no-danger */
import { Button, Icon, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import React from 'react';
import { CandidateScoreIcon } from '../../Icons/AryaIcons';
import BellCurveGraph from './BellCurveGraph';
import CompanyTable from './CompanyTable';
import { generatePdf } from '../../Utils/PdfGeneratorUtils';

const sectionOrder = ['Summary', 'Skill', 'Company', 'Experience', 'Industry', 'Role', 'Education'];

const ScoreDetailSection = ({
  type,
  data,
  styles,
  candidateDetails,
  id,
  overview,
  isCandidateIntelDownloadEnabled,
}) => {
  switch (type.toLowerCase()) {
    case 'summary':
      return (
        <Summary
          overview={overview}
          type={type}
          data={data}
          styles={styles}
          candidateDetails={candidateDetails}
          id={id}
          isCandidateIntelDownloadEnabled={isCandidateIntelDownloadEnabled}
        />
      );
    case 'skill':
      return <Skills candidateDetails={candidateDetails} type={type} data={data} styles={styles} id={id} />;
    default:
      return <Default type={type} data={data} styles={styles} id={id} />;
  }
};

const initiatePdfGeneration = async (candidateDetails, elementIds, exclusionClassNames, setDownloadButtonLoading) => {
  const { candidateName, jobTitle } = candidateDetails;
  await generatePdf(candidateName, jobTitle, elementIds, setDownloadButtonLoading, exclusionClassNames);
};

const handleCandidateExplanationDownloadClick = async (candidateDetails, setDownloadButtonLoading) => {
  const elementIds = sectionOrder.map(item => `${item}-id-ref-wrapper`);
  setDownloadButtonLoading(true);
  await initiatePdfGeneration(
    candidateDetails,
    elementIds,
    ['ant-btn', 'anticon-info-circle'],
    setDownloadButtonLoading
  );
  setDownloadButtonLoading(false);
};

const Summary = ({ data, styles, candidateDetails, id, overview, isCandidateIntelDownloadEnabled }) => {
  const { candidateName, jobTitle } = candidateDetails;
  const bulletPoints = data.Facts.map(fact => fact.Fact) || [];
  const highlightedKeyWords = data.Facts.filter(({ HighlightKeywords }) => HighlightKeywords).map(
    item => item.HighlightKeywords
  );
  const [downloadButtonLoading, setDownloadButtonLoading] = React.useState(false);
  return (
    <div className={styles.summaryWrapper} id={`${id}-wrapper`}>
      <div id={id}></div>
      <div className={styles.summaryContainer}>
        <div className={`${styles.bigHeading} ${styles.summaryHeader}`}>
          <span>
            Summary of {candidateName.split(' ')[0]} for the {jobTitle} role
          </span>
        </div>
        <div className={styles.skillFacts}>
          <BulletPoints styles={styles} bulletPoints={bulletPoints} highlightedKeyWords={highlightedKeyWords} />
        </div>
      </div>
      <div className={styles.radarChartContainer}>
        {isCandidateIntelDownloadEnabled ? (
          <Button
            shape="round"
            onClick={() => handleCandidateExplanationDownloadClick(candidateDetails, setDownloadButtonLoading)}
            loading={downloadButtonLoading}
          >
            Download
          </Button>
        ) : null}
      </div>
    </div>
  );
};

function getskillFacts(facts, type) {
  return facts.filter(item => item.Type.toLowerCase() === type).map(factsObj => factsObj.Fact);
}

const Skills = ({ data, styles, candidateDetails, id }) => {
  const skillTypes = Object.keys(data);
  const skillSectionData = [];
  skillTypes.forEach(skillType => {
    const tempObj = {};
    if (skillType.toLowerCase() === 'matched') {
      tempObj.title = 'Perfect match skills';
      tempObj.facts = getskillFacts(data.Facts, 'matched');
      tempObj.tooltipData = "The job skills that perfectly align with the candidate's existing abilities.";
    } else if (skillType.toLowerCase() === 'missing') {
      tempObj.title = 'Missing skills';
      tempObj.facts = getskillFacts(data.Facts, 'missing');
      tempObj.tooltipData = 'Job skills that the candidate does not possess and may need to learn.';
    } else if (skillType.toLowerCase() === 'trainable') {
      tempObj.title = 'Trainable or similar skills';
      tempObj.facts = getskillFacts(data.Facts, 'trainable');
      tempObj.tooltipData = 'Job skills that the candidate can easily acquire based on their current skill set';
    } else if (skillType.toLowerCase() === 'transferable') {
      tempObj.title = 'Transferable skills';
      tempObj.facts = getskillFacts(data.Facts, 'transferable');
      tempObj.tooltipData =
        'Valuable skills the candidate has that, while not listed in the job description, are still relevant and beneficial for the job role.';
    } else return;
    tempObj.skills = data[skillType].Skills || [];
    if (
      (data[skillType].Skills && data[skillType].Skills.length > 0) ||
      (data[skillType].Facts && data[skillType].Facts.length > 0)
    ) {
      skillSectionData.push(tempObj);
    }
  });
  const { candidateName, score } = candidateDetails;
  return (
    <div className={styles.sectionWrapper} id={`${id}-wrapper`}>
      <div id={id}></div>
      <div className={styles.skillContainer}>
        <div className={styles.bigHeading}>
          Why is {candidateName} rated <CandidateScoreIcon /> <span>{score}</span> ?{' '}
        </div>
        <div className={styles.sectionHeading}>
          Skills{' '}
          <Tooltip
            placement="right"
            title="Gain insight into a candidate's suitability for a job by evaluating their skills in the below categories"
          >
            <Icon type="info-circle" />
          </Tooltip>
        </div>
        <div className={styles.sectionCard}>
          {skillSectionData.map((content, i) => (
            <SkillSection {...content} lastElement={i === skillSectionData.length - 1} styles={styles} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SkillSection = ({ title, tooltipData, skills, facts, styles, lastElement }) => (
  <div className={styles.skillSectionContent}>
    <div className={styles.sectionContentHeading}>
      {title}
      <Tooltip placement="right" title={tooltipData}>
        <Icon type="info-circle" />
      </Tooltip>
    </div>
    <div className={styles.skillArray}>
      {skills &&
        skills.map((skill, i) => {
          return (
            <span>
              {skill}
              {skills.length - 1 === i ? '' : ','}{' '}
            </span>
          );
        })}
    </div>
    <div className={styles.skillFacts}>{facts && <BulletPoints styles={styles} bulletPoints={facts} />}</div>
    {!lastElement && <div className={styles.sectionLine}></div>}
  </div>
);

const tooltipMapper = {
  Company:
    "Quickly evaluate and compare companies using these key indicators\nArya Popularity – Measures the company's prominence based on inbound and outbound activity.\nTotal Employees – Indicates the total number of employees working for the company.\nAverage Tenure – Provides insight into the average length of time employees stay with the company.\nOperating Locations – Displays the number of locations where the company has operations.\nAnnual Revenue – Reports the company's yearly revenue.\nIndustries – Identifies the industries in which the company has a presence.",
  Experience:
    "Evaluate candidates based on their experience using these key insights:\n1. Experience Comparison – Compare the candidate's years of experience with the average for the role.\n2. Experience Relevance – Consider if the candidate's extensive experience aligns with the specific requirements\n and expectations of the role.",
  Industry:
    "Assess candidates based on their industry experience with these key factors:\n1. Client Industries – Identify the industries in which the client company operates.\n2. Candidate Industries – Recognize the industries in which the candidate has recent experience.\n3. Industry Dominance – Determine if the job position is typically filled by candidates from specific industries.\n4. Candidate's Industry Relevance – Evaluate if the candidate's industry background aligns with the dominant industries for the job.\n5. Industry Fit – Assess how well-suited the candidate is for the job based on their industry experience.",
  Education:
    "Analyze candidates based on their educational background using these key aspects:\n1. Educational Requirements – Identify the minimum education level required for the job.\n2. Degree Demand – Understand the importance of the specified degree for the job role.\n3. Education Match – Evaluate how well the candidate's education aligns with the job requirements, \ndetermining their suitability for the position. ",
  Role: 'Evaluate candidates based on the similarity between the Roles.',
};

const Default = ({ type, styles, data, id }) => {
  let bulletPoints = [];
  bulletPoints = data.Facts.map(fact => fact.Fact) || [];
  return (
    <div className={`${styles.defaultContainer} ${styles.sectionWrapper}`} id={`${id}-wrapper`}>
      <div className={styles.sectionHeading}>
        {type}
        {tooltipMapper[type] && (
          <Tooltip
            overlayClassName={styles.candidateIntelV2Tooltip}
            openClassName="openClassName"
            overlayStyle={{ width: 'fit-content' }}
            placement="right"
            title={tooltipMapper[type]}
          >
            <Icon type="info-circle" />
          </Tooltip>
        )}
      </div>
      <div className={styles.sectionCard}>
        <div id={id}></div>
        {type === 'Company' && data?.CompanyFeaturesScales && <CompanyTable tableData={data?.CompanyFeaturesScales} />}
        {type === 'Experience' && (
          <BellCurveGraph
            meanExperience={data.Metadata.AverageExperience}
            candidateExperience={data.Metadata.CandidateTotalExperience}
            requiredExperience={data.Metadata.RequiredExperience}
          />
        )}
        <BulletPoints styles={styles} bulletPoints={bulletPoints} />
      </div>
    </div>
  );
};

export const BulletPoints = ({ bulletPoints, styles, highlightedKeyWords }) => {
  function highlightKeywordsInFacts(facts, highlightWords) {
    return facts.map((str, i) => {
      let tempString = ` ${str}`.slice(1);
      highlightWords[i].forEach(word => {
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedWord, 'gi');
        tempString = tempString.replace(regex, `<b>${word}</b>`);
      });
      return tempString;
    });
  }
  const highlightedFacts =
    highlightedKeyWords && highlightedKeyWords.length > 0
      ? highlightKeywordsInFacts(bulletPoints, highlightedKeyWords)
      : bulletPoints;
  return (
    <div>
      {highlightedFacts &&
        highlightedFacts.map((fact, index) => {
          const key = `${fact[0]}-${index}`;
          return (
            <p key={key} className={styles.bulletPointContainer}>
              <p className={styles.bulletLine}></p>
              <p style={{ width: 'fit-content' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fact) }}></p>
            </p>
          );
        })}
    </div>
  );
};
export default ScoreDetailSection;
export { handleCandidateExplanationDownloadClick };
