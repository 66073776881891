import React from 'react';
import _ from 'lodash';
import { Modal, Button, Icon } from 'antd';
import styles from './CloseMailAndMessageComposeWindowModal.module.scss';

export default function CloseMailAndMessageComposeWindowModal(props) {
  const { isCloseModalVisible, onCloseComposeWindow, setCloseModalVisibility, context } = props;

  const onCancel = () => {
    setCloseModalVisibility(false);
  };

  const mediaDisplayName = context.toLowerCase() === 'message' ? 'text' : context;

  const footerButtons = (
    <div className={styles.actionButtons}>
      <Button shape="round" key="back" onClick={onCancel}>
        Cancel
      </Button>
      <Button shape="round" key="submit" onClick={onCloseComposeWindow} type="primary">
        Yes
      </Button>
    </div>
  );

  return (
    <Modal
      title={`Close ${_.capitalize(mediaDisplayName)} Composer`}
      visible={isCloseModalVisible}
      onCancel={onCancel}
      className={styles.closeModal}
      footer={[footerButtons]}
      zIndex={2501}
      maskClosable={false}
    >
      <div className={styles.modalContent}>
        <div className={styles.iconDiv}>
          <Icon type="exclamation-circle" />
        </div>
        <div className={styles.message}>Are you sure to close {mediaDisplayName} composer?</div>
      </div>
    </Modal>
  );
}
