/* eslint-disable react/button-has-type */
import React from 'react';
import { Popover, Tooltip, Icon, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { SourceHtmlIcon, MergeTagIcon, FontSizeIcon } from '../../Icons/AryaIcons';
import './CustomToolbar.scss';

export const handleScrollDown = ref => {
  const divElement = ref.current;
  if (divElement) {
    divElement.scrollTop = divElement.scrollHeight;
  }
};

export const getScrollTopPosition = (popoverTargetRef, maxHeight, isEmailPanel) => {
  if (isEmailPanel) {
    const viewportHeight = window.innerHeight;
    const target = popoverTargetRef.current ? popoverTargetRef.current.getBoundingClientRect() : {};
    const { bottom } = target;
    const isCollidedAtBottom = bottom + maxHeight > viewportHeight;
    return { isCollidedAtBottom, scrollTop: bottom + maxHeight - viewportHeight + 30 || 0 }; // adding 30 to scroll till subject
  }
  return { isCollidedAtBottom: true, scrollTop: 0 };
};

function popoverContent(mergeTags, addTags, isCreateTemplate) {
  return (
    <div
      className={classNames('tags-popover-content', {
        'tags-popover-content-candidate-360': !isCreateTemplate,
      })}
    >
      {mergeTags.map(mergeTag => (
        <div
          className="merge-tag"
          onClick={() => {
            addTags(mergeTag);
          }}
          key={mergeTag.Key}
          role="presentation"
        >
          + {mergeTag.DisplayName === 'Chatbot URL' ? 'AryaBot URL' : mergeTag.DisplayName}
        </div>
      ))}
    </div>
  );
}

export function getClassNameUsingCondition(text, conditionalText, predicate) {
  return predicate ? `${text} ${conditionalText}` : `${text}`;
}

export function getToolButttons(tools = []) {
  return tools.map(tool => (
    <button
      className={getClassNameUsingCondition(tool.className, tool.conditionalClassName, tool.condition)}
      title={tool.title}
      value={tool.value}
      disabled={tool.disabled}
      type="button"
    />
  ));
}

export const getPopoverPosition = (isEmailPanel, isCreateTemplate) => {
  if (isCreateTemplate) return 'left';
  if (isEmailPanel) return 'bottomRight';
  return 'topLeft';
};

const CustomToolbar = props => {
  const {
    mergeTags,
    addTags,
    id,
    onlyMergeTags,
    imageSupport,
    toggleComposePreview,
    sourceCodeSupport,
    isHtmlToolActive,
    isChatGptContentGenerated,
    fontSize,
    handleFontSizeChange,
    enableGenerationThroughChatGpt,
    isCandidate360MailWindow,
    isEmailTemplateCreate,
    mailRef,
    isEmailPanel,
    isDripEmail,
    isCreateDripTemplate,
  } = props;

  const fontSizeIconRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  const toolbarRef = React.useRef(null);
  const popoverTargetRef = React.useRef(null);
  const [toolbarSection, setToolbarSection] = React.useState(0);

  const onClickSizeChange = value => {
    handleFontSizeChange(value.key);
  };

  const newToolbarSectionValue = value => {
    if (value < 0) return 2;
    if (value > 2) return 0;
    return value;
  };

  const onClickNavigationIcon = navValue => setToolbarSection(newToolbarSectionValue(navValue));

  const sizeDropdownOptions = [
    { label: 'Small', key: 'small', size: '12px' },
    { label: 'Medium', key: 'medium', size: '14px' },
    { label: 'Large', key: 'large', size: '16px' },
  ];

  const getActiveSizeOption = key => {
    if (fontSize === key) return <Icon type="check" />;
    return null;
  };

  const menu = (
    <Menu onClick={onClickSizeChange}>
      {sizeDropdownOptions.map(option => {
        const { label, key, size } = option;
        const activeSizeOption = getActiveSizeOption();
        return (
          <Menu.Item key={key} style={{ fontSize: `${size}`, color: '#1F2730' }} disabled={isHtmlToolActive}>
            {activeSizeOption}
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const handleCodeViewiconClick = e => {
    toggleComposePreview();
    e.stopPropagation();
  };

  const textEditorOffsetTop = fontSizeIconRef?.current?.offsetTop;

  const fontSizeIconStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const renderFontSizeSection = () => {
    if (isCandidate360MailWindow) {
      const fontSizeSectionClass = getClassNameUsingCondition(
        'ql-formats',
        'custom-toolbar-html-true',
        isHtmlToolActive
      );

      return (
        <Dropdown
          overlay={menu}
          overlayStyle={{ zIndex: '33000' }}
          trigger="click"
          style={{
            position: 'absolute',
            top: `${textEditorOffsetTop + 10}px`,
          }}
        >
          <span
            className={fontSizeSectionClass}
            style={fontSizeIconStyle}
            ref={fontSizeIconRef}
            data-testid="font-size-icon"
          >
            <FontSizeIcon style={{ color: 'black' }} />
          </span>
        </Dropdown>
      );
    }
    return (
      <select
        className={getClassNameUsingCondition('ql-size', 'custom-toolbar-html-true', isHtmlToolActive)}
        defaultValue="medium"
        title="Font Size"
        disabled={isHtmlToolActive}
      >
        <option value="small" disabled={isHtmlToolActive}>
          Small
        </option>
        <option value="medium" disabled={isHtmlToolActive}>
          Medium
        </option>
        <option value="large" disabled={isHtmlToolActive}>
          Large
        </option>
      </select>
    );
  };

  const handleOnClick = () => {
    const { isCollidedAtBottom, scrollTop } = getScrollTopPosition(popoverTargetRef, 236, isEmailPanel); // 236 is the height of popover
    const mailElement = mailRef.current;
    if (mailElement && isCollidedAtBottom)
      mailElement.scrollTop = isEmailPanel ? mailElement.scrollTop + scrollTop : scrollTop;
  };

  const renderMergeTagsSection = () => {
    if (mergeTags) {
      const mergeTagsSectionClass = customToolbarSpanClassName;
      return (
        <div>
          <span className={mergeTagsSectionClass}>{mergeTagsButton}</span>
        </div>
      );
    }
    return null;
  };

  const popoverTrigger = !isChatGptContentGenerated ? 'click' : '';
  const popoverClassName = isChatGptContentGenerated ? 'mergeTag-popup' : '';
  const isCreateTemplate = isCreateDripTemplate || isEmailTemplateCreate;
  const popoverPlacement = getPopoverPosition(isEmailPanel, isCreateTemplate);
  const customToolbarSpanClassName = isCandidate360MailWindow ? 'ql-formats' : '';

  const mergeTagsButton = mergeTags && (
    <button className={getClassNameUsingCondition('ql-mergetags', 'custom-toolbar-html-true', isHtmlToolActive)}>
      <Popover
        overlayStyle={{ zIndex: 2223 }}
        content={popoverContent(mergeTags, addTags, isCreateTemplate)}
        placement={popoverPlacement}
        trigger={popoverTrigger}
        className={popoverClassName}
        ref={popoverRef}
      >
        <Tooltip zIndex={2223} title="Merge Tags">
          <span data-testid="merge-tag-icon" onClick={handleOnClick} ref={popoverTargetRef}>
            <MergeTagIcon />
          </span>
        </Tooltip>
      </Popover>
    </button>
  );

  const textTransformTools = [
    {
      className: 'ql-bold',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      title: 'Bold',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-italic',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      title: 'Italic',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-underline',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      title: 'Underline',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-strike',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      title: 'Strikethrough',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-blockquote',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      title: 'Blockquote',
      disabled: isHtmlToolActive,
    },
  ];
  const textHeaderTools = [
    {
      className: 'ql-header',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      value: '1',
      title: 'Header 1',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-header',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      value: '2',
      title: 'Header 2',
      disabled: isHtmlToolActive,
    },
  ];
  const textListTools = [
    {
      className: 'ql-list',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      value: 'ordered',
      title: 'Numbering',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-list',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      value: 'bullet',
      title: 'Bullets',
      disabled: isHtmlToolActive,
    },
  ];
  const textIndentationTools = [
    {
      className: 'ql-indent',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      value: '-1',
      title: 'Decrease Indent',
      disabled: isHtmlToolActive,
    },
    {
      className: 'ql-indent',
      conditionalClassName: 'custom-toolbar-html-true',
      condition: isHtmlToolActive,
      value: '+1',
      title: 'Increase Indent',
      disabled: isHtmlToolActive,
    },
  ];

  const mergeTagsSection = renderMergeTagsSection();

  return (
    <div
      className={classNames('custom-toolbar-container', {
        'create-email-template-toolbar': isEmailTemplateCreate,
      })}
    >
      {isCandidate360MailWindow && (
        <div
          className="custom-toolbar-up-icon"
          onClick={() => onClickNavigationIcon(toolbarSection - 1)}
          data-testid="down-arrow-icon"
        >
          <Icon type="up" />
        </div>
      )}
      <div
        id={id}
        className={classNames('custom-toolbar ql-toolbar ql-snow', {
          'custom-toolbar-candidate360-view ': enableGenerationThroughChatGpt,
          'custom-toolbar-chatGpt-view': !enableGenerationThroughChatGpt,
        })}
        ref={toolbarRef}
      >
        {isDripEmail ? <span className="ql-formats">{mergeTagsButton} </span> : null}
        {!onlyMergeTags ? (
          <div key="custom-toolbar-header" className="custom-toolbar-header">
            <Candidate360ToolbarOptions
              toolbarSection={toolbarSection}
              mergeTagsSection={mergeTagsSection}
              customToolbarSpanClassName={customToolbarSpanClassName}
              textTransformTools={textTransformTools}
              renderFontSizeSection={renderFontSizeSection}
              isHtmlToolActive={isHtmlToolActive}
              imageSupport={imageSupport}
              textListTools={textListTools}
              textIndentationTools={textIndentationTools}
              sourceCodeSupport={sourceCodeSupport}
              handleCodeViewiconClick={handleCodeViewiconClick}
              textHeaderTools={textHeaderTools}
              isCandidate360MailWindow={isCandidate360MailWindow}
            />
          </div>
        ) : null}
      </div>
      {isCandidate360MailWindow && (
        <div
          className="custom-toolbar-down-icon"
          onClick={() => onClickNavigationIcon(toolbarSection + 1)}
          data-testid="down-arrow-icon"
        >
          <Icon type="down" />
        </div>
      )}
    </div>
  );
};

export const Candidate360ToolbarOptions = props => {
  const {
    toolbarSection,
    mergeTagsSection,
    customToolbarSpanClassName,
    textTransformTools,
    renderFontSizeSection,
    isHtmlToolActive,
    imageSupport,
    textListTools,
    textIndentationTools,
    sourceCodeSupport,
    handleCodeViewiconClick,
    textHeaderTools,

    isCandidate360MailWindow,
  } = props;
  const toolbarOptionsFirstSet = (
    <>
      <span
        className={getClassNameUsingCondition(customToolbarSpanClassName, 'custom-toolbar-html-true', isHtmlToolActive)}
      >
        <select
          className={getClassNameUsingCondition('ql-background', 'custom-toolbar-html-true', isHtmlToolActive)}
          title="Highlight"
          disabled={isHtmlToolActive}
        />
      </span>
      <span
        className={getClassNameUsingCondition(customToolbarSpanClassName, 'custom-toolbar-html-true', isHtmlToolActive)}
      >
        <select
          className={getClassNameUsingCondition('ql-color', 'custom-toolbar-html-true', isHtmlToolActive)}
          title="Font Color"
          disabled={isHtmlToolActive}
        />
      </span>

      <span className={customToolbarSpanClassName}>{getToolButttons(textListTools)}</span>

      <span
        className={getClassNameUsingCondition(customToolbarSpanClassName, 'custom-toolbar-html-true', isHtmlToolActive)}
      >
        <select
          className={getClassNameUsingCondition('ql-align', 'custom-toolbar-html-true', isHtmlToolActive)}
          title="Align"
          disabled={isHtmlToolActive}
        />
      </span>

      <span className={customToolbarSpanClassName}>{getToolButttons(textIndentationTools)}</span>
    </>
  );
  const toolbarOptionsSecondSet = (
    <>
      <span className={customToolbarSpanClassName}>
        <button
          className={getClassNameUsingCondition('ql-link', 'custom-toolbar-html-true', isHtmlToolActive)}
          title="Insert Hyperlink"
          disabled={isHtmlToolActive}
        />

        {imageSupport === false ? null : (
          <button
            className={getClassNameUsingCondition('ql-image', 'custom-toolbar-html-true', isHtmlToolActive)}
            title="Insert Image"
            disabled={isHtmlToolActive}
          />
        )}
      </span>
      <span className={customToolbarSpanClassName}>
        <button
          className={getClassNameUsingCondition('ql-clean', 'custom-toolbar-html-true', isHtmlToolActive)}
          title="Remove Formatting"
          disabled={isHtmlToolActive}
        />
      </span>
      <span className={customToolbarSpanClassName}>
        {sourceCodeSupport ? (
          <button className="source-button" type="button" onClick={handleCodeViewiconClick} title="Code View">
            <SourceHtmlIcon className={`html-tool html-tool-${isHtmlToolActive}`} />
          </button>
        ) : null}
      </span>
      <span className={customToolbarSpanClassName}>{getToolButttons(textHeaderTools)}</span>
    </>
  );
  const toolbarOptionsThirdSet = (
    <>
      {mergeTagsSection}
      <span className={customToolbarSpanClassName}>{getToolButttons(textTransformTools)}</span>
      {renderFontSizeSection()}
    </>
  );
  if (isCandidate360MailWindow)
    return (
      <>
        <div className={classNames('section-1', { 'hide-section': toolbarSection !== 1 })}>
          {toolbarOptionsFirstSet}
        </div>
        <div className={classNames('section-2', { 'hide-section': toolbarSection !== 2 })}>
          {toolbarOptionsSecondSet}
        </div>

        <div className={classNames('section-3', { 'hide-section': toolbarSection })}>{toolbarOptionsThirdSet}</div>
      </>
    );
  return (
    <div>
      {toolbarOptionsThirdSet}
      {toolbarOptionsSecondSet}
      {toolbarOptionsFirstSet}
    </div>
  );
};
export default CustomToolbar;
