import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Modal, Button, Radio, Spin, Icon, Checkbox, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import cleanSet from 'clean-set';
import './ActivationDialog.scss';
import JobPortal from '../../Components/JobPortal/JobPortal';
import UserAlerts from '../../Components/UserAlerts/UserAlerts';
import ReviewCommentsHeader from '../../Components/ReviewDialog/ReviewCommentsHeader';
import * as JobActions from '../../Actions/JobActions';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getSourceName, getSourceDisplayName, getSourceListItemSource } from '../../Utils/SourceUtils';
import { getIsJobActivationAllowed, getJobLimitExceededAlertMessage } from '../../Utils/JobUtils';
import { isDisabledReactivationOptions } from '../../Utils/ActivationUtils';
import ReActivationAlert from './ReActivationAlert/ReActivationAlert';
import message from './messages';
import { getEventNameByFeatureType } from '../../Analytics/Job/JobEventUtils';

const RadioGroup = Radio.Group;

const mapStateToProps = state => {
  return {
    sources: _.get(getConfig(state), ['PortalSources'], []),
    showVaultName: _.get(getConfig(state), ['ShowVaultName'], false),
    authorizedPortals: _.get(getConfig(state), ['AuthorizedPortals'], []),
    whiteLabelInfo: getWhiteLabelInformation(state),
    userConfig: getConfig(state),
  };
};

const mapDispatchToProps = {
  activatePortals: JobActions.activatePortals,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  setActivationStateChanged: JobActions.setActivationStateChanged,
  fetchOrgActivatedJobsCount: JobActions.fetchOrgActivatedJobsCount,
  fetchOrgOpenedJobsCount: JobActions.fetchOrgOpenedJobsCount
};

export const getIsButtonDisabled = (
  isActivationRequired,
  isAppliedCandidateScoringRequired,
  portalStatusChangeCount,
  aryaVersion
) => {
  if (aryaVersion === 'Lite') {
    return !(isActivationRequired || isAppliedCandidateScoringRequired);
  }
  if (isActivationRequired && isAppliedCandidateScoringRequired) {
    return !portalStatusChangeCount;
  }
  if (!isActivationRequired && isAppliedCandidateScoringRequired) {
    return false;
  }
  if (isActivationRequired && !isAppliedCandidateScoringRequired) {
    return !portalStatusChangeCount;
  }
  return true;
};

class ActivationDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      portalStatusChangeCount: 0,
      retainId: 2,
      expandReviewDetails: false,
      includeRejectedCandidates: false,
      derivedInitialPortalSelectionState: false,
      isActivationRequired: true,
      isAppliedCandidateScoringRequired: true,
    };
    this.handlePortalSelect = this.handlePortalSelect.bind(this);
    this.deactivateArya = this.deactivateArya.bind(this);
    this.getRetainType = this.getRetainType.bind(this);
    this.onRetainTypeChange = this.onRetainTypeChange.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
    this.getPortalSourcesView = this.getPortalSourcesView.bind(this);
    this.onClickSeeReviewDetails = this.onClickSeeReviewDetails.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { job } = props;
    if (state.derivedInitialPortalSelectionState) {
      return null;
    }
    let jobActivationStatus = _.get(job, ['aryaState']);
    if (!jobActivationStatus) {
      return {
        jobActivationStatus: {},
        portalStatusChangeCount: 0,
      };
    }
    const hasAnyActiveSource = Object.values(jobActivationStatus).some(sourceActivationStatus => {
      const sourceName = getSourceName(sourceActivationStatus.Source, false).toLowerCase();
      return _.get(jobActivationStatus, [sourceName, 'IsActivated'], false);
    });
    const areDefaultConfiguredSourcesInactive = !hasAnyActiveSource && !job.lastChangeTime;
    let portalStatusChangeCount = 0;
    if (areDefaultConfiguredSourcesInactive) {
      portalStatusChangeCount = Object.values(jobActivationStatus)?.length;
    }

    Object.values(jobActivationStatus).forEach(sourceActivationStatus => {
      const sourceName = getSourceName(sourceActivationStatus.Source, false).toLowerCase();
      jobActivationStatus = cleanSet(
        jobActivationStatus,
        [sourceName, 'isClicked'],
        jobActivationStatus[sourceName].IsActivated || areDefaultConfiguredSourcesInactive
      );
    });
    return {
      jobActivationStatus,
      portalStatusChangeCount,
      derivedInitialPortalSelectionState: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { jobId, job, fetchJobSourcingStats, visible, fetchOrgActivatedJobsCount, fetchOrgOpenedJobsCount } = this.props;
    const isActivationStateChanged =
      _.get(job, ['activationStateChanged']) !== _.get(prevProps.job, ['activationStateChanged']) &&
      _.get(job, ['activationStateChanged'], false) === true;
    if (isActivationStateChanged) {
      fetchJobSourcingStats([jobId]);
    }
    if (!job.HadActivated && !prevProps.visible && visible) {
      fetchOrgActivatedJobsCount()
      fetchOrgOpenedJobsCount()
    };
  }

  onRetainTypeChange(event) {
    this.setState({
      retainId: event.target.value,
    });
  }

  onClickSeeReviewDetails() {
    const { expandReviewDetails } = this.state;
    this.setState({
      expandReviewDetails: !expandReviewDetails,
    });
  }

  onIncludeRejectedCandidatesChange = event => {
    this.setState({
      includeRejectedCandidates: event.target.checked,
    });
  };

  getRetainType() {
    const { retainId } = this.state;
    switch (retainId) {
      case 3:
        return 'deleteAll';
      case 1:
        return 'keepAll';
      default:
        return 'keepShortlisted';
    }
  }

  getPortalSourcesView() {
    const {
      sources,
      authorizedPortals,
      job,
      showVaultName,
      aryaVersion,
      version,
      whiteLabelInfo,
      userConfig,
      activationDialogAdditionalHeader,
    } = this.props;
    const {
      jobActivationStatus: currentJobActivationStatus,
      retainId,
      includeRejectedCandidates,
      isActivationRequired,
    } = this.state;
    const disableFlag = isDisabledReactivationOptions(job, version);
    const isJobActivationAllowed = getIsJobActivationAllowed(userConfig, job.HadActivated, job.HadOpened);
    const protalSourcesViewClass = classNames({
      'activate-portal-details-disable': !isActivationRequired,
      'activate-portal-details': isActivationRequired,
    });
    return (
      <div className={protalSourcesViewClass}>
        {activationDialogAdditionalHeader}
        <div className="retain-div">
          <RadioGroup onChange={this.onRetainTypeChange} value={retainId}>
            <Radio value={1} className="radio-select">
              <FormattedMessage {...message.keepAll} />
            </Radio>
            <Radio value={2} className="radio-select" disabled={disableFlag}>
              <FormattedMessage {...message.keepShortlisted} />
            </Radio>
            <Radio value={3} className="radio-select" disabled={disableFlag}>
              <FormattedMessage {...message.deleteAll} />
            </Radio>
          </RadioGroup>
          {retainId === 3 ? (
            <Checkbox
              checked={includeRejectedCandidates}
              onChange={this.onIncludeRejectedCandidatesChange}
              disabled={disableFlag && version !== 'ats'}
            >
              Delete Rejected Also?
            </Checkbox>
          ) : null}
        </div>
        {aryaVersion !== 'Lite' ? (
          <div className="portals">
            <div className="source-title">
              <FormattedMessage {...message.chooseSource} />
            </div>
            <div className="portal-activation-buttons">
              {sources.map(portalSource => {
                const sourceName = getSourceName(portalSource);
                const sourceDisplayName = getSourceDisplayName(
                  portalSource,
                  userConfig,
                  showVaultName,
                  {
                    Name: 'activationDialougeIcon',
                  },
                  whiteLabelInfo
                );
                const index = sourceName.toLowerCase();
                const isDisabled =
                  !authorizedPortals.includes(portalSource.Group || portalSource.Portal) ||
                  !_.get(currentJobActivationStatus, [index], false);
                return (
                  <JobPortal
                    name={sourceDisplayName}
                    showVaultName={showVaultName}
                    key={sourceName}
                    index={index}
                    source={portalSource}
                    selected={
                      !isDisabled &&
                      currentJobActivationStatus &&
                      currentJobActivationStatus[index] &&
                      currentJobActivationStatus[index].isClicked
                    }
                    change={this.handlePortalSelect}
                    disabled={!isJobActivationAllowed || isDisabled}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  handlePortalSelect(sourceName) {
    const { jobActivationStatus } = this.state;
    let { portalStatusChangeCount } = this.state;
    jobActivationStatus[sourceName].isClicked = !jobActivationStatus[sourceName].isClicked;
    if (jobActivationStatus[sourceName].isClicked === jobActivationStatus[sourceName].IsActivated) {
      portalStatusChangeCount -= 1;
    } else {
      portalStatusChangeCount += 1;
    }
    this.setState({
      jobActivationStatus,
      portalStatusChangeCount,
    });
  }

  resetActivationDialogState = () => {
    this.setState({
      retainId: 2,
      includeRejectedCandidates: false,
    });
  };

  handleScoreAndRankCheckboxClicked = value => {
    const { checked } = value.target;
    this.setState({
      isActivationRequired: checked,
    });
  };

  handleScoreAndRankAppliedCheckboxClicked = value => {
    this.setState({
      isAppliedCandidateScoringRequired: value.target.checked,
    });
  };

  handleActivation() {
    const {
      jobId,
      activatePortals,
      handleCancel,
      onStatusChange,
      activeTab,
      sources,
      aryaVersion,
      storeJobReactivationApiStatus,
      searchCriteria,
    } = this.props;
    const { jobActivationStatus, includeRejectedCandidates, isActivationRequired, isAppliedCandidateScoringRequired } =
      this.state;
    const sourceList = [];
    const retainType = this.getRetainType();
    if (aryaVersion === 'Lite') {
      sources.forEach(source => {
        sourceList.push({
          Source: { Portal: source.Portal, Name: source.Name, Type: source.Type },
          RetainType: retainType,
          IsActivated: true,
        });
      });
    } else {
      sources.forEach(source => {
        const sourceName = getSourceName(source);
        const index = sourceName.toLowerCase();
        const sourceListItemSource = getSourceListItemSource(source);
        const sourceListItem = {
          RetainType: retainType,
          IsActivated: _.get(jobActivationStatus, [index, 'isClicked'], false),
          Source: sourceListItemSource,
        };
        sourceList.push(sourceListItem);
      });
    }
    const activationDetails = {
      JobId: jobId,
      Sources: sourceList,
      IsActivationRequired: isActivationRequired,
      IsAppliedCandidateScoringRequired: isAppliedCandidateScoringRequired,
    };
    if (retainType === 'deleteAll') {
      activationDetails.IncludeRejectedCandidates = includeRejectedCandidates;
    }
    if (sourceList.length || aryaVersion !== 'Lite') {
      activatePortals(activationDetails, storeJobReactivationApiStatus, searchCriteria);
    }
    handleCancel({ isAryaReactivated: true });
    this.resetActivationDialogState();
    if (activeTab !== 'ranked') {
      onStatusChange('ranked');
    }
  }

  deactivateArya() {
    const { jobId, activatePortals, handleCancel, sources, storeJobReactivationApiStatus } = this.props;
    const retainType = this.getRetainType();
    const deactivateSourceList = [];
    sources.forEach(source => {
      const sourceListItemSource = getSourceListItemSource(source);
      deactivateSourceList.push({
        Source: sourceListItemSource,
        RetainType: retainType,
        IsActivated: false,
      });
    });
    const deactivationDetails = {
      JobId: jobId,
      Sources: deactivateSourceList,
    };
    if (deactivateSourceList.length) {
      activatePortals(deactivationDetails, storeJobReactivationApiStatus);
    }
    handleCancel();
    this.resetActivationDialogState();
  }

  render() {
    const {
      jobActivationStatus,
      jobId,
      visible,
      handleCancel,
      /* showReviewDetails , */ sources,
      job,
      aryaVersion,
      whiteLabelInfo,
      userConfig,
      activationDialogAdditionalHeader,
      closeButtonTooltip,
      type,
      featureToggleList,
      smartRecruiterVersion,
    } = this.props;
    const {
      jobActivationStatus: currentJobActivationStatus,
      portalStatusChangeCount,
      expandReviewDetails,
      retainId,
      isActivationRequired,
      isAppliedCandidateScoringRequired,
    } = this.state;

    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');

    let deactivateButtonName = <FormattedMessage {...message.deactivateArya} values={{ AppName }} />;
    let reactivateAryaButton = `Reactivate ${AppName}`;
    if (featureToggleList?.AdvanceSearchV2?.IsEnabled || smartRecruiterVersion === 'v2') {
      deactivateButtonName = 'Stop Scoring and Rank';
      reactivateAryaButton = 'Score and Rank';
    }
    const isJobActivationAllowed = getIsJobActivationAllowed(userConfig, job.HadActivated, job.HadOpened);
    const { recentSourcedTime, lastChangeTime } = job;
    const recentSourced = moment.utc(recentSourcedTime).local();
    const lastChange = moment.utc(lastChangeTime).local();
    let lastSourcedTime = lastChange;
    if (recentSourcedTime) {
      lastSourcedTime = moment.max(recentSourced, lastChange);
    }
    let reviewCommentState = false;
    const timeDiff = moment().diff(lastSourcedTime, 'days');
    if (timeDiff >= 2) {
      reviewCommentState = true;
    }
    if (
      _.get(job, 'sourcingStats.Shortlisted', 0) ||
      _.get(job, 'sourcingStats.Sourced', 0) ||
      _.get(job, 'sourcingStats.Connected', 0)
    ) {
      reviewCommentState = false;
    }
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    let footer;

    if (
      !currentJobActivationStatus ||
      sources.every(source => {
        const sourceName = getSourceName(source).toLowerCase();
        return !_.get(currentJobActivationStatus, [sourceName, 'IsActivated'], false);
      })
    ) {
      footer = [
        <Button
          key="submit"
          type="primary"
          onClick={this.handleActivation}
          disabled={
            !isJobActivationAllowed ||
            getIsButtonDisabled(
              isActivationRequired,
              isAppliedCandidateScoringRequired,
              portalStatusChangeCount,
              aryaVersion
            )
          }
          className="activate-btn-text-jay"
        >
          {featureToggleList?.AdvanceSearchV2?.IsEnabled || smartRecruiterVersion === 'v2' ? (
            'Score and Rank'
          ) : (
            <FormattedMessage {...message.activateArya} values={{ AppName }} />
          )}
        </Button>,
      ];
    } else {
      footer = [
        <Button
          key="deactivate"
          type="danger"
          onClick={this.deactivateArya}
          className="deactivate"
          disabled={!isJobActivationAllowed}
          sk-event-name={getEventNameByFeatureType(type, 'deactivateArya')}
        >
          {deactivateButtonName}
        </Button>,
        <Button
          key="reactivate"
          type="primary"
          onClick={this.handleActivation}
          id="reactivate-arya-btn"
          disabled={!isJobActivationAllowed || !(isActivationRequired || isAppliedCandidateScoringRequired)}
          sk-event-name={getEventNameByFeatureType(type, 'reactivateArya')}
        >
          {reactivateAryaButton}
        </Button>,
      ];
    }

    const closeIcon = (
      <Tooltip placement="top" title={closeButtonTooltip}>
        <Icon type="close" />
      </Tooltip>
    );

    return (
      <>
        {!isJobActivationAllowed && visible ? (
          <div className="userAlertWrapper">
            <UserAlerts header="Feature Locked!" content={getJobLimitExceededAlertMessage(userConfig.JobLimit)} />
          </div>
        ) : null}
        <ReActivationAlert retainId={retainId} />
        <Modal
          className="activate-modal-id"
          width="579px"
          visible={visible}
          wrapClassName="activation-modal-wrapper"
          title={
            !activationDialogAdditionalHeader ? (
              <span className="activate-modal-title">
                <FormattedMessage {...message.aryaSourcing} values={{ AppName }} />
                {reviewCommentState ? ' (Needs Review)' : ''}
              </span>
            ) : null
          }
          onOk={this.handleActivation}
          onCancel={handleCancel}
          footer={!expandReviewDetails ? footer : null}
          closeIcon={closeIcon}
        >
          <Spin indicator={antIcon} spinning={!jobActivationStatus}>
            {reviewCommentState ? (
              <ReviewCommentsHeader
                jobId={jobId}
                isExpanded={expandReviewDetails}
                onClickSeeDetails={this.onClickSeeReviewDetails}
                AppName={AppName}
              />
            ) : null}
            <Checkbox className="score-rank" defaultChecked onChange={this.handleScoreAndRankCheckboxClicked}>
              <FormattedMessage {...message.aryaScoringRanking} />
            </Checkbox>
            {reviewCommentState && expandReviewDetails ? null : this.getPortalSourcesView()}
            <Checkbox
              className="score-rank-applied"
              defaultChecked={false}
              onChange={this.handleScoreAndRankAppliedCheckboxClicked}
              checked={isAppliedCandidateScoringRequired}
            >
              <FormattedMessage {...message.aryaScoringRankingApplied} />
            </Checkbox>
          </Spin>
        </Modal>
      </>
    );
  }
}

ActivationDialogContainer.propTypes = {
  jobId: PropTypes.number,
  jobActivationStatus: PropTypes.shape({}),
  visible: PropTypes.bool,
  fetchActivationStatus: PropTypes.func.isRequired,
  activatePortals: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string,
      Type: PropTypes.string,
      Portal: PropTypes.string,
    })
  ),
  handleCancel: PropTypes.func,
};

ActivationDialogContainer.defaultProps = {
  jobActivationStatus: {},
  visible: false,
  sources: [],
  handleCancel: () => { },
  jobId: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationDialogContainer);

export { ActivationDialogContainer as ActivationDialogContainerWithoutStore };
