import React from 'react';
import { Input, Form } from 'antd';
import PersonalInformationContext from './PersonalInformationContext';
import { validateEmail } from '../../Utils/FormValidators';

const { Item } = Form;

function Email(props) {
  const { initialValue, isNameMasked } = props;
  const { form } = React.useContext(PersonalInformationContext);

  return (
    <Item colon={false}>
      {form.getFieldDecorator('Email', {
        initialValue,
        rules: [
          {
            validator: validateEmail,
          },
        ],
      })(<Input style={{ overflow: 'hidden' }} placeholder="Enter Email Address" disabled={isNameMasked} />)}
    </Item>
  );
}

export default Email;
