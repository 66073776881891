import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Popover, Checkbox, Tooltip } from 'antd';
import DiversityFilterIcon from '../../../Icons/DiversityFilterIcon';
import DiversityGreyFilterIcon from '../../../Icons/DiversityGreyFilterIcon';
import styles from './CandidateDiversityFilter.module.scss';

export default function CandidateDiversityFilter(props) {
  const [genders, setGenders] = useState([]);
  const [veteran, setVeteran] = useState(null);
  const [minority, setMinority] = useState(null);
  const [diversityFilterVisibility, setDiversityFilterVisibility] = useState(false);

  const handleDiversityFilterVisibility = newDiversityFilterVisibility => {
    setDiversityFilterVisibility(newDiversityFilterVisibility);
  };

  const { applyCandidateFilter, currentPipelineFilter, candidateAggs, isDiversityAttributesVisible } = props;
  const { Genders, IsVeteran, IsMinority } = currentPipelineFilter;

  const femaleAgg = candidateAggs?.Gender?.find(val => val.Value === 'Female') ?? { Count: 0 };
  const minorityAgg = candidateAggs?.Minority?.find(val => val.Value === 'Minority') ?? { Count: 0 };
  const veteranAgg = candidateAggs?.Veteran?.find(val => val.Value === 'Veteran') ?? { Count: 0 };

  useEffect(() => {
    setGenders(Genders);
    setVeteran(IsVeteran);
    setMinority(IsMinority);
  }, [...Genders, IsVeteran, IsMinority]);

  const genderOptions = [{ label: `Female (${femaleAgg?.Count})`, value: 'Female' }];

  const applyDiversityFilter = () => {
    setDiversityFilterVisibility(false);
    if (!_.isEqual(genders, Genders) || veteran !== IsVeteran || minority !== IsMinority) {
      applyCandidateFilter({
        modifiedFilters: {
          Genders: genders,
          IsVeteran: veteran,
          IsMinority: minority,
        },
      });
    }
  };

  const clearDiversityFilter = () => {
    setDiversityFilterVisibility(false);
    if (genders?.length || veteran || minority) {
      setGenders([]);
      setVeteran(null);
      setMinority(null);
    }
    if (Genders?.length || IsVeteran || IsMinority) {
      applyCandidateFilter({
        modifiedFilters: {
          Genders: [],
          IsVeteran: null,
          IsMinority: null,
        },
      });
    }
  };

  const onGenderFilter = selectedGenders => {
    setGenders(selectedGenders);
  };

  const onVeteranFilter = event => {
    setVeteran(!event?.target?.checked ? null : event?.target?.checked);
  };

  const onMinorityFilter = event => {
    setMinority(!event?.target?.checked ? null : event?.target?.checked);
  };

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      visible={diversityFilterVisibility}
      onVisibleChange={handleDiversityFilterVisibility}
      title={
        <div className={styles.diversityFilterHeader}>
          <div className={styles.diversityHeaderLabel}>Diversity Options</div>
          <div className={styles.diversityFilters}>
            <Button className={styles.diversityClearButton} onClick={clearDiversityFilter}>
              Clear
            </Button>
            <Button className={styles.diversityApplyButton} type="primary" onClick={applyDiversityFilter}>
              Apply
            </Button>
          </div>
        </div>
      }
      content={
        <div className={styles.diversityFilter}>
          <Checkbox.Group
            className={styles.genderFilter}
            options={genderOptions}
            onChange={onGenderFilter}
            value={genders}
          />
          <Checkbox checked={veteran} onChange={onVeteranFilter} label="Veteran">
            Veteran ({veteranAgg?.Count})
          </Checkbox>
          <Checkbox checked={minority} onChange={onMinorityFilter} label="Minority">
            Minority ({minorityAgg?.Count})
          </Checkbox>
        </div>
      }
    >
      <Tooltip title="Diversity Filter">
        <Button className={isDiversityAttributesVisible ? styles.diversityIcon : styles.diversityGreyIcon}>
          {isDiversityAttributesVisible ? (
            <DiversityFilterIcon style={{ fontSize: '20px' }} />
          ) : (
            <DiversityGreyFilterIcon style={{ fontSize: '20px' }} />
          )}
        </Button>
      </Tooltip>
    </Popover>
  );
}
