import React from 'react';
import { Switch } from 'antd';
import styles from './NotificationSettingsItem.module.scss';

export default function NotificationSettingsItem(props) {
  const { className, title, onChange, isSubscribed, disabled, loading } = props;
  const excludedTitles = ['Alternate candidates are available', 'Reminders to act on alternate candidate list banner'];

  if (excludedTitles.includes(title)) {
    return null;
  }
  return (
    <div className={`${styles.notificationSettingsItem} ${className}`}>
      <div className={styles.title}>{title}</div>
      <div>
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={onChange}
          checked={isSubscribed}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
