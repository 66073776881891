import React from 'react';
import { Select, Tooltip, Icon } from 'antd';
import styles from './SubSegmentStyles.module.scss';

const { Option } = Select;

const SubSegmentSelectBox = props => {
  const {
    selectStyle,
    handleSubSegmentOption,
    onPopupScroll,
    dropDownRender,
    subSegmentList,
    subsegmentCandidateCount,
    extraWidth,
    selectedSubsegmentId,
    isCandidateAssociationVisible = true,
    mode,
  } = props;

  return (
    <Select
      style={selectStyle}
      dropdownStyle={{ zIndex: 3000 }}
      placeholder="Select Sub-Segment"
      dropdownMenuStyle={{ padding: '4px 10px' }}
      onChange={handleSubSegmentOption}
      onPopupScroll={onPopupScroll}
      dropdownRender={dropDownRender}
      value={selectedSubsegmentId != null ? selectedSubsegmentId : undefined}
      disabled={mode === 'update'}
      getPopupContainer={(subSegmentSelectBoxTrigger) => subSegmentSelectBoxTrigger.parentElement}
    >
      {subSegmentList.map(item => (
        <Option className={styles.subsegmentOption} value={item.Id} key={item.Id}>
          <Tooltip
            overlay={item.Name}
            placement="right"
            overlayStyle={{ whiteSpace: 'break-spaces', zIndex: 3100 }}
            align={{ offset: [5, 0] }}
          >
            <div>
              <span>{item.Name.length > 20 ? item.Name.slice(0, 20).concat('...') : item.Name}</span>
            </div>
          </Tooltip>
          {isCandidateAssociationVisible ? (
            <div
              className={styles.subsegmentCandidateCount}
              style={{
                width: `${subsegmentCandidateCount}`.length * 10 + extraWidth,
              }}
            >
              <Icon type="usergroup-add" /> {item.AssociatedCandidateCount}
            </div>
          ) : null}
        </Option>
      ))}
    </Select>
  );
};

export default SubSegmentSelectBox;
