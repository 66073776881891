import _ from 'lodash';

export const handleOperatorsOptions = options => {
  return options?.map((value, index) => ({
    value,
    key: index,
  }));
};
export const handleCampaignSearchOptions = options => {
  return options?.map(({ Id, CampaignName }) => ({
    value: CampaignName,
    id: Id,
    text: CampaignName,
  }));
};
export const handleGlobalOptions = options => {
  return options?.map(({ Id, Name }) => ({
    value: Name,
    id: Id,
    text: Name,
  }));
};
export const handleTemplateSearchOptions = options => {
  return options?.map(option => ({
    value: option?.Name,
    id: option?.Id,
    text: option?.Name,
    emailTemplateDetails: option,
  }));
};
export const handleLocationOptions = options => {
  const loactionOptions = options
    ?.map(option => {
      const zipcodeCityState = [];
      if (option?.city) {
        zipcodeCityState.push(option.city);
      }
      if (option?.state?.name) {
        zipcodeCityState.push(option?.state?.name);
      }
      if (option?.zipcode) {
        zipcodeCityState.push(option?.zipcode);
      }
      return {
        value: zipcodeCityState.join(', '),
        id: option?.id,
        text: zipcodeCityState.join(', '),
        data: option,
      };
    })
    ?.filter(item => item?.value !== undefined);

  return _.uniqBy(loactionOptions, 'value');
};

export const handleIndustryList = industries => {
  if (Array.isArray(industries)) {
    return industries?.map(industry => ({
      value: industry,
      text: industry,
    }));
  }
  return [];
};

const modifyFilterListObject = obj => {
  return {
    AttributeName: obj.AttributeName,
    AttributeId: obj.AttributeId,
    Operator: obj.Operator,
    AttributeValue:
      obj.Type === 'Multi' && Array.isArray(obj.AttributeValue) ? obj.AttributeValue.join(',') : obj.AttributeValue,
  };
};

const LimitSeperatorFromFilterList = filterList => {
  const setsArray = [];
  for (let i = 1; i <= filterList?.length; i += 1) {
    setsArray?.push(modifyFilterListObject(filterList[i - 1]));
  }
  return setsArray;
};

export const AdvanceFiltersJsonGenerator = (filterGroupList, component) => {
  const jsonArray = [];
  for (let i = 1; i <= filterGroupList?.length; i += 1) {
    const setObject = {};
    const setsArray = LimitSeperatorFromFilterList(filterGroupList[i - 1]?.FilterItems);
    setObject.FilterItems = setsArray;
    setObject.IsInclude = component === 'include' ? 1 : 0;
    jsonArray.push(setObject);
  }
  return jsonArray;
};

export const generateUpdatedFilterPayload = (candidatesFetchPayload, activeTab, filterContextId) => {
  const updatedCandidateFetchPayload = _.cloneDeep(candidatesFetchPayload);
  const statusValue = activeTab === 'rejected' ? '3' : '2';
  updatedCandidateFetchPayload?.AdvanceFilterQuery?.FilterGroups?.forEach((group, groupIndex) => {
    const hasCandidateStatus = group?.FilterItems?.some(item => item?.AttributeId === 30);
    const hasFilter = group?.FilterItems?.filter(item => item?.AttributeId === 29);
    if (!hasCandidateStatus) {
      group.FilterItems.push({
        AttributeName: 'CandidateStatus',
        AttributeId: 30,
        Operator: '=',
        AttributeValue: statusValue,
      });
    }
    if (hasFilter?.length > 0) {
      const newFilterItems = group.FilterItems.filter(item => item.AttributeId !== 29);
      updatedCandidateFetchPayload.AdvanceFilterQuery.FilterGroups[groupIndex].FilterItems = newFilterItems;
      updatedCandidateFetchPayload.AdvanceFilterQuery.FilterGroups[groupIndex].LimitType = 'Count';
      updatedCandidateFetchPayload.AdvanceFilterQuery.FilterGroups[groupIndex].LimitValue = parseInt(
        hasFilter[0]?.AttributeValue,
        10
      );
    }
  });
  const isInclude = updatedCandidateFetchPayload?.AdvanceFilterQuery?.FilterGroups?.some(
    group => group?.IsInclude === 1
  );
  if (!isInclude) {
    updatedCandidateFetchPayload.AdvanceFilterQuery?.FilterGroups?.unshift({
      FilterItems: [
        {
          AttributeName: 'CandidateStatus',
          AttributeId: 30,
          Operator: '=',
          AttributeValue: statusValue,
        },
      ],
      IsInclude: 1,
    });
  }
  if (updatedCandidateFetchPayload?.AdvanceFilterQuery && filterContextId) {
    updatedCandidateFetchPayload.AdvanceFilterQuery.FilterContextId = filterContextId;
  }
  return updatedCandidateFetchPayload;
};

export const generateDefaultSegmentFilter = activeTab => {
  const statusValue = activeTab === 'rejected' ? '3' : '2';
  return {
    FilterGroups: [
      {
        FilterItems: [
          {
            AttributeName: 'CandidateStatus',
            AttributeId: 30,
            Operator: '=',
            AttributeValue: statusValue,
          },
        ],
        IsInclude: 1,
      },
    ],
  };
};

export const getErrorMessage = errorType => {
  switch (errorType) {
    case 'NegativeInput':
      return 'Value should be greater than 0';
    case 'OverLimit':
      return 'Value should be less than overall candidate count';
    default:
      return '';
  }
};
