import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'antd';
import moment from 'moment';
import { getRelativeTime } from '../../Utils/RelativeTimeCalculator';
import { EllipsedText } from '../../Utils/TextUtils';
import CandidateViewIconV2 from '../../Icons/CandidateViewIconV2';
import styles from './StackedBar.module.scss';

const StackedBar = props => {
  const {
    WorkflowName: workflowName,
    InstanceId: instanceId,
    StatusUpdateReason: statusUpdateReason,
    StartedBy: startedBy,
    StartTime: startTime,
    Status,
    ToEmails,
    FromEmail,
    date,
    stopWorkflowApiStatus,
    stopCandidateWorkflow,
    DefinitionId,
    jobGuid,
    jobId,
    jobTitle,
    candidateId,
    candidateName,
    setCandidateWorkflowHistoryAction,
  } = props;

  const handleStopWorkflow = async e => {
    e.preventDefault();
    e.stopPropagation();
    await stopCandidateWorkflow(jobGuid, candidateId);
    setCandidateWorkflowHistoryAction(jobGuid, candidateId);
  };

  let displayStatus = '';
  let iconColorStyle = '';
  let iconType = '';
  let showStopWorkflowButton = false;
  switch (Status) {
    case 'Running':
    case 'Idle':
    case 'Suspended':
      displayStatus = 'Active';
      iconColorStyle = '#13C26B';
      showStopWorkflowButton = true;
      break;
    case 'Faulted':
      displayStatus = 'Failed';
      iconColorStyle = '#F5222D';
      iconType = 'exclamation-circle';
      break;
    case 'Cancelled':
      displayStatus = 'Stopped';
      iconColorStyle = '#F5222D';
      iconType = 'close-circle';
      break;
    default:
      displayStatus = Status;
      iconColorStyle = '#13C26B';
      iconType = 'check-circle';
  }

  const setCandidateContext = () => {
    const workflowCandidateContext = JSON.parse(localStorage.getItem('WorkflowCandidateContext')) || {};
    const candidateContextId = `${jobId}_${candidateId}`;
    workflowCandidateContext[candidateContextId] = {
      jobGuid,
      jobId,
      jobTitle,
      candidateId,
      candidateName,
    };
    localStorage.setItem('WorkflowCandidateContext', JSON.stringify(workflowCandidateContext));
  };

  const workflowStartedTime = getRelativeTime(startTime);
  const startedAtDiv = (
    <div className={styles.startTime}>
      {startTime && (
        <>
          <b>Initiated At: </b>
          <EllipsedText text={workflowStartedTime} maxTextLength={20} />
        </>
      )}
    </div>
  );

  return (
    <div className={styles.stackedBarContainer}>
      <div className={styles.divOne}>
        <div className={styles.subjectContainer}>
          <div>
            <span>
              <h3>
                <EllipsedText text={workflowName} maxTextLength={55} />
              </h3>
            </span>
          </div>
          <div className={styles.startedBy}>
            <b>Initiated By: </b>
            <EllipsedText text={startedBy} maxTextLength={20} />
          </div>
        </div>
        <div className={styles.toEmailContainer}>
          {ToEmails?.length > 0 && (
            <>
              <div>
                <p>
                  <b>To: </b>
                  <EllipsedText text={ToEmails.join(', ')} maxTextLength={60} />
                </p>
              </div>
              {startedAtDiv}
            </>
          )}
        </div>
        <div className={styles.fromEmailContainer}>
          <div>
            <p>
              <b>From: </b>
              <EllipsedText text={FromEmail} maxTextLength={55} />
            </p>
          </div>
          {ToEmails?.length === 0 || (ToEmails === undefined && startedAtDiv)}
        </div>
        <div className={styles.footerContent}>
          <div className={styles.status} style={{ color: iconColorStyle }}>
            {iconType ? <Icon type={iconType} /> : null}
            <span>{displayStatus}</span>
            {statusUpdateReason ? <span>{`(${statusUpdateReason})`}</span> : null}
          </div>
          <div className={styles.footerButtons}>
            <Link
              to={{
                pathname: `/connect`, // !! Handle for ATS
                search: `?tab=workflows&workflowId=${DefinitionId}&type=preview&instanceId=${instanceId}&jobGuid=${jobGuid}&candidateId=${candidateId}&candidateContextId=${jobId}_${candidateId}&isReadOnly=true`,
              }}
              style={{ display: 'flex', alignItems: 'center' }}
              target="_blank"
            >
              <Button shape="round" type="primary" onClick={setCandidateContext}>
                View Workflow Progress
                <CandidateViewIconV2 style={{ color: 'white' }} />
              </Button>
            </Link>
            {showStopWorkflowButton ? (
              <Button
                shape="round"
                loading={stopWorkflowApiStatus === 'INPROGRESS'}
                onClick={handleStopWorkflow}
                type="secondary"
              >
                Stop Workflow
              </Button>
            ) : null}
          </div>

          {date && (
            <div className={styles.dateContainer}>{moment.utc(date).local().format('DD MMM YYYY, hh:mm A')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StackedBar;
