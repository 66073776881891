import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { getPortalName } from '../../../Utils/SourceUtils';
import EmailIconV2 from '../../../Icons/EmailIconV2';
import EmailSuccessIconV2 from '../../../Icons/EmailSuccessIconV2';
import EmailConsentPendingIconV2 from '../../../Icons/EmailConsentPendingIconV2';
import EmailFailIconV2 from '../../../Icons/EmailFailIconV2';
import EmailUnsubscribedIconV2 from '../../../Icons/EmailUnsubscribedIconV2';
import CallIconV2 from '../../../Icons/CallIconV2';
import CallFailedIconV2 from '../../../Icons/CallFailedIconV2';
import CallSuccessIconV2 from '../../../Icons/CallSuccessIconV2';
import CallDisabledIconV2 from '../../../Icons/CallDisabledIconV2';
import TextIconV2 from '../../../Icons/TextIconV2';
import TextConsentIconV2 from '../../../Icons/TextConsentIconV2';
import TextFailedIconV2 from '../../../Icons/TextFailedIconV2';
import TextSuccessIconV2 from '../../../Icons/TextSuccessIconV2';
import TextSuccessIconV3 from '../../../Icons/TextSuccessIconV3';
import TextOptedOutIconV2 from '../../../Icons/TextOptedOutIconV2';
import * as ConnectUtils from '../../../Utils/ConnectUtils';
import eventTypes from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import EnhancedFetchContactWidget from '../EnhancedFetchContactWidget/EnhancedFetchContactWidget';
import styles from './EnhancedConnectMediaWidget.module.scss';
import { getContactPullStatus, getConsentStatus } from '../ConnectMediaWidget/ConnectMediaWidget';
import { getIsUnsubscribedContactExists, getLastRefundTime } from '../../../Utils/ContactUtils';
import ChatGptIcon from '../../../Icons/ChatGptIocon';
import ComposeEmailDropdown from './ComposeEmailDropdown';
import { getEmailValidationStatus } from '../../../Utils/EmailUtils/EmailUtils';

function getCallIcon(Contact, AudioConnectStatus = {}, skEventName = null, isPhoneTootlipVisible) {
  const { Phones = [], Subscription: subscription = {} } = Contact;
  const isPhoneCommunicationAllowed =
    subscription?.Status?.toLowerCase() !== 'unsubscribed' && !Phones.some(phone => !phone.IsCommunicationAllowed);
  const { Status: AudioConversationStatus } = AudioConnectStatus;
  if (Phones && Phones.length > 0) {
    const notRefundedAndValidPhones = Phones.filter(
      phone => !phone.IsRefunded && (phone.ValidityStatus === 'Valid' || phone.ValidityStatus === 'Unknown')
    );
    if (!isPhoneCommunicationAllowed)
      return (
        <Tooltip title="Communication disabled" visible={isPhoneTootlipVisible} mouseEnterDelay={0.5}>
          <CallDisabledIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
          />
        </Tooltip>
      );
    if (getIsUnsubscribedContactExists(Phones)) {
      return (
        <Tooltip title="Phone Unsubscribed" visible={isPhoneTootlipVisible} mouseEnterDelay={0.5}>
          <CallDisabledIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
          />
        </Tooltip>
      );
    }
    if (notRefundedAndValidPhones.length === 0) {
      return <CallIconV2 className={styles.disabledMediaIcon} sk-event-name={skEventName} />;
    }
    if (AudioConversationStatus === 'InProgress' || AudioConversationStatus === 'Completed') {
      const callStatus = AudioConversationStatus === 'InProgress' ? 'Inprogress' : 'Completed';
      return (
        <Tooltip title={`Call ${callStatus}`} visible={isPhoneTootlipVisible} mouseEnterDelay={0.5}>
          <CallSuccessIconV2 className={styles.largeMediaIcon} sk-event-name={skEventName} />
        </Tooltip>
      );
    }
    if (
      AudioConversationStatus === 'Unanswered' ||
      AudioConversationStatus === 'Canceled' ||
      AudioConversationStatus === 'Failed'
    ) {
      return (
        <Tooltip title={`Call ${AudioConversationStatus}`} visible={isPhoneTootlipVisible} mouseEnterDelay={0.5}>
          <CallFailedIconV2 className={styles.largeMediaIcon} sk-event-name={skEventName} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Phone number available" visible={isPhoneTootlipVisible} mouseEnterDelay={0.5}>
        <CallIconV2 className={styles.mediaIcon} sk-event-name={skEventName} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="No phone number found" visible={isPhoneTootlipVisible} mouseEnterDelay={0.5}>
      <CallIconV2 className={styles.disabledMediaIcon} sk-event-name={skEventName} />
    </Tooltip>
  );
}

export function getMessageIcon(
  isTextTootlipVisible,
  portal,
  contact,
  SmsConnectStatus = {},
  skEventName = null,
  appliedTime = ''
) {
  const { RequestStatus: MessageRequestStatus, ConsentStatus: MessageConsentStatus } = SmsConnectStatus;
  const { Phones = [], Subscription: subscription = {} } = contact;
  const isPhoneCommunicationAllowed =
    subscription?.Status?.toLowerCase() !== 'unsubscribed' && !Phones.some(phone => !phone.IsCommunicationAllowed);
  if (appliedTime && portal.toLowerCase() !== 'social') {
    if (MessageConsentStatus === 'Denied') {
      return (
        <Tooltip title="Consent rejected" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextOptedOutIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Consent accepted via Apply" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
        <TextSuccessIconV3 className={styles.largeMediaIcon} sk-event-name={skEventName} />
      </Tooltip>
    );
  }
  if (Phones && Phones.length > 0) {
    const notRefundedAndValidPhones = Phones.filter(
      phone =>
        !phone.IsRefunded &&
        (phone.MessageConsentStatus === 'Approved' ||
          phone.ValidityStatus === 'Valid' ||
          phone.ValidityStatus === 'Unknown')
    );
    if (!isPhoneCommunicationAllowed)
      return (
        <Tooltip title="Communication disabled" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextOptedOutIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
          />
        </Tooltip>
      );

    if (notRefundedAndValidPhones.length === 0) {
      return <TextIconV2 className={styles.disabledMediaIcon} sk-event-name={skEventName} />;
    }

    if (getIsUnsubscribedContactExists(Phones)) {
      return (
        <Tooltip title="Phone Unsubscribed" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextOptedOutIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
          />
        </Tooltip>
      );
    }
    if (MessageConsentStatus === 'Pending') {
      return (
        <Tooltip title="Consent pending" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextConsentIconV2 className={styles.largeMediaIcon} sk-event-name={skEventName} />
        </Tooltip>
      );
    }
    if (MessageConsentStatus === 'Denied') {
      return (
        <Tooltip title="Consent rejected" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextOptedOutIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
          />
        </Tooltip>
      );
    }
    if (MessageConsentStatus === 'Approved') {
      return (
        <Tooltip title="Consent accepted" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextSuccessIconV2 className={styles.largeMediaIcon} sk-event-name={skEventName} />
        </Tooltip>
      );
    }
    if (!MessageConsentStatus && (MessageRequestStatus === 'Pending' || MessageRequestStatus === 'Success')) {
      const textStatus = MessageRequestStatus === 'Pending' ? 'Pending' : 'Delivered';
      return (
        <Tooltip title={`Text ${textStatus}`} visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextConsentIconV2 className={styles.largeMediaIcon} sk-event-name={skEventName} />
        </Tooltip>
      );
    }
    if (MessageRequestStatus === 'Failed') {
      return (
        <Tooltip title="Text Failed" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
          <TextFailedIconV2 className={styles.largeMediaIcon} sk-event-name={skEventName} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Phone number available" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
        <TextIconV2 className={styles.mediaIcon} sk-event-name={skEventName} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="No phone number found" visible={isTextTootlipVisible} mouseEnterDelay={0.5}>
      <TextIconV2 className={styles.disabledMediaIcon} sk-event-name={skEventName} />
    </Tooltip>
  );
}

function getMailIcon(
  Contact,
  EmailConnectStatus = {},
  consentStatus = null,
  skEventName = null,
  isEmailTootlipVisible,
  onClickToComposeEmailManually,
  handleIconClick
) {
  const handleManualEmailComposeButtonClick = () => {
    onClickToComposeEmailManually(true);
  };
  const { Emails = [], Subscription: subscription = {} } = Contact;
  const { RequestStatus: EmailRequestStatus, SubscriptionStatus } = EmailConnectStatus;
  const isEmailCommunicationAllowed =
    subscription?.Status?.toLowerCase() !== 'unsubscribed' && !Emails.some(email => !email.IsCommunicationAllowed);
  if (Emails && Emails.length > 0) {
    const notRefundedAndValidEmails = Emails.filter(
      email =>
        !email.IsRefunded &&
        !email.IsUnsubscribeByUser &&
        (email.EmailSubscriptionStatus === 'Subscribed' ||
          email.ValidityStatus === 'Valid' ||
          email.ValidityStatus === 'Unknown')
    );

    const inValidEmails = Emails.filter(
      email => !email.IsRefunded && (email.ValidityStatus === 'Spam' || email.ValidityStatus === 'Invalid')
    );

    if (!isEmailCommunicationAllowed) {
      return (
        <Tooltip title="Communication disabled" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailUnsubscribedIconV2
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }

    if (notRefundedAndValidEmails.length === 0 && inValidEmails.length > 0) {
      return (
        <Tooltip title="Invalid Email address" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailFailIconV2
            style={{ marginTop: '3.4px' }}
            className={styles.largeMediaIcon}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }

    if (notRefundedAndValidEmails.length === 0) {
      return <EmailIconV2 className={styles.disabledMediaIcon} sk-event-name={skEventName} />;
    }
    if (consentStatus === 'Pending') {
      return (
        <Tooltip title="Consent Pending" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailConsentPendingIconV2
            style={{ marginTop: '3.4px' }}
            className={styles.largeMediaIcon}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }
    if (SubscriptionStatus === 'Unsubscribed' || getIsUnsubscribedContactExists(Emails)) {
      return (
        <Tooltip title="Email Unsubscribed" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailUnsubscribedIconV2
            style={{ marginTop: '3.4px' }}
            className={classNames(styles.largeMediaIcon, styles.unsubscribedIcon)}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }
    if (EmailRequestStatus === 'Failed') {
      return (
        <Tooltip title="Email Failed" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailFailIconV2
            style={{ marginTop: '3.4px' }}
            className={styles.largeMediaIcon}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }
    if (EmailRequestStatus === 'Success') {
      return (
        <Tooltip title="Email Success" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailSuccessIconV2
            style={{ marginTop: '3.4px' }}
            className={styles.largeMediaIcon}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }
    if (EmailRequestStatus === 'Pending') {
      return (
        <Tooltip title="Email Pending" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
          <EmailConsentPendingIconV2
            style={{ marginTop: '3.4px' }}
            className={styles.largeMediaIcon}
            sk-event-name={skEventName}
            onClick={handleManualEmailComposeButtonClick}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Email address available" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
        <EmailIconV2
          className={styles.mediaIcon}
          sk-event-name={skEventName}
          onClick={() => {
            handleIconClick('email');
          }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="No email addresss found" visible={isEmailTootlipVisible} mouseEnterDelay={0.5}>
      <EmailIconV2 className={styles.disabledMediaIcon} sk-event-name={skEventName} />
    </Tooltip>
  );
}

function EnhancedConnectMediaWidget({
  candidate,
  onIconClick,
  setComposeEmailType,
  fetchContactStatus,
  ConnectStatus = {},
  featureToggleList,
  indexKey,
  currentJobDetails,
  isEmailReadVisible,
  isMessageReadVisible,
  isAudioReadVisible,
  candidateContext,
  isCandidateViewIconVisible,
}) {
  const [isEmailTootlipVisible, setEmailTooltipVisiblity] = React.useState(false);
  const [isPhoneTootlipVisible, setPhoneTooltipVisiblity] = React.useState(false);
  const [isTextTootlipVisible, setTextTooltipVisiblity] = React.useState(false);
  const [dropdownVisibility, setDropdownVisibility] = React.useState(false);
  const emailGenerationWithChatGptEnabled = featureToggleList.GenerativeAIEmailGeneration.IsEnabled;
  const composeEmailDropdownRef = React.useRef(null);

  const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup?.IsEnabled;
  const candidatePortalName = getPortalName(candidate?.Sources, revealActiveChannelSourceName);

  React.useEffect(() => {
    if (!isCandidateViewIconVisible) setDropdownVisibility(isCandidateViewIconVisible);
  }, [isCandidateViewIconVisible]);

  let { Contact } = ConnectStatus;
  Contact = Contact || {};
  let contactRequestStatus = Contact.RequestStatus;
  if ((Contact.Emails && Contact.Emails.length > 0) || (Contact.Phones && Contact.Phones.length > 0)) {
    contactRequestStatus = 'Success';
  }
  if (!contactRequestStatus) {
    return featureToggleList.ContactFetch.IsEnabled ? (
      <EnhancedFetchContactWidget
        fetchContactStatus={fetchContactStatus}
        candidate={candidate}
        currentJobDetails={currentJobDetails}
        contactPullColor="#13c26b"
      />
    ) : null;
  }
  const { ConsentStatus: consentStatus } = Contact;
  if (consentStatus && consentStatus !== 'Approved' && !ConnectUtils.isValidContact(Contact)) {
    return getConsentStatus(Contact);
  }

  const handleIconClick = key => {
    if (emailGenerationWithChatGptEnabled && key === 'email' && candidateContext !== 'segment') {
      setDropdownVisibility(true);
    } else {
      setDropdownVisibility(false);
      onIconClick(candidate, key, indexKey, 'contact');
    }
  };

  const handleEmailIconClick = e => {
    e.stopPropagation();
    setEmailTooltipVisiblity(false);
    if (!getEmailValidationStatus(Contact)) onIconClick(candidate, 'email', indexKey, 'contact');
  };

  const toggleEmailTooltipVisiblity = value => {
    setEmailTooltipVisiblity(value);
  };

  const onClickToComposeEmailThroughChatGpt = () => {
    onIconClick(candidate, 'email', indexKey, 'contact');
    setComposeEmailType('chatGPT');
    setDropdownVisibility(false);
  };

  const onClickToComposeEmailManually = shouldShowComposeSection => {
    onIconClick(candidate, 'email', indexKey, 'contact');
    if (!shouldShowComposeSection) setComposeEmailType('manual');
    setDropdownVisibility(false);
  };

  const contactPullStatus = getContactPullStatus(Contact, contactRequestStatus);
  const lastRefundTime = getLastRefundTime(ConnectStatus);

  const contactPullText = ConnectUtils.contactPullStatusText[contactPullStatus];

  let contactsText =
    consentStatus && ConnectUtils.consentStatusText[consentStatus]
      ? `${ConnectUtils.consentStatusText[consentStatus]} | ${contactPullText}`
      : contactPullText;
  if (contactPullStatus.toLowerCase() === 'none' && lastRefundTime)
    contactsText = `${contactPullText} | Credit refunded`;
  const isEmail = Contact.Emails && Contact.Emails.length > 0;
  const chatGptIcon = emailGenerationWithChatGptEnabled && candidateContext !== 'segment' && (
    <ChatGptIcon
      className={styles.chatGptIcon}
      style={!isEmail ? { cursor: 'not-allowed' } : {}}
      onClick={() => {
        handleIconClick('email');
      }}
    />
  );
  return (
    <div className={styles.enhancedConnectMediaWidget}>
      <div className={styles.connectMediaIconGroup}>
        {isEmailReadVisible ? (
          <div>
            <div
              onClick={handleEmailIconClick}
              onKeyPress={handleEmailIconClick}
              role="presentation"
              className={styles.connectMedia}
              sk-event-name={getEventNameByCandidateStatus(
                eventTypes.candidate.candidateConnect.goToMailWindow,
                candidate
              )}
            >
              <div className={styles.emailIcon} ref={composeEmailDropdownRef}>
                <div>{chatGptIcon}</div>
                <div
                  onMouseMove={() => toggleEmailTooltipVisiblity(true)}
                  onMouseLeave={() => toggleEmailTooltipVisiblity(false)}
                >
                  {getMailIcon(
                    Contact,
                    ConnectStatus.EmailStatus,
                    consentStatus,
                    getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToMailWindow, candidate),
                    isEmailTootlipVisible,
                    onClickToComposeEmailManually,
                    handleIconClick
                  )}
                </div>
              </div>
            </div>
            <ComposeEmailDropdown
              onClickToComposeEmailThroughChatGpt={onClickToComposeEmailThroughChatGpt}
              dropdownVisibility={dropdownVisibility}
              onClickToComposeEmailManually={onClickToComposeEmailManually}
              Contact={Contact}
              setDropdownVisibility={setDropdownVisibility}
              isCandidateViewIconVisible={isCandidateViewIconVisible}
              composeEmailDropdownRef={composeEmailDropdownRef}
              candidate={candidate}
            />
          </div>
        ) : null}
        {isMessageReadVisible ? (
          <div
            onClick={e => {
              e.stopPropagation();
              setTextTooltipVisiblity(false);
              handleIconClick('message');
            }}
            onKeyPress={() => {
              setTextTooltipVisiblity(false);
              handleIconClick('message');
            }}
            role="presentation"
            className={styles.connectMedia}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToSmsWindow,
              candidate
            )}
            onMouseMove={() => setTextTooltipVisiblity(true)}
            onMouseLeave={() => setTextTooltipVisiblity(false)}
          >
            {getMessageIcon(
              isTextTootlipVisible,
              candidatePortalName,
              Contact,
              ConnectStatus?.SmsStatus,
              getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToSmsWindow, candidate),
              candidate?.AppliedTime
            )}
          </div>
        ) : null}

        {isAudioReadVisible ? (
          <div
            onClick={e => {
              e.stopPropagation();
              setPhoneTooltipVisiblity(false);
              handleIconClick('call');
            }}
            onKeyPress={() => {
              setPhoneTooltipVisiblity(false);
              handleIconClick('call');
            }}
            role="presentation"
            className={styles.connectMedia}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToCallWindow,
              candidate
            )}
            onMouseMove={() => setPhoneTooltipVisiblity(true)}
            onMouseLeave={() => setPhoneTooltipVisiblity(false)}
          >
            {getCallIcon(
              Contact,
              ConnectStatus.AudioStatus,
              getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToCallWindow, candidate),
              isPhoneTootlipVisible
            )}
          </div>
        ) : null}
      </div>
      {!isAudioReadVisible && !isEmailReadVisible && !isMessageReadVisible ? (
        <div className={styles.contactText} role="presentation" onClick={e => e.stopPropagation()}>
          {contactsText}
        </div>
      ) : null}
    </div>
  );
}

export default EnhancedConnectMediaWidget;
