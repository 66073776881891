import React from 'react';
import { Button, Icon, Input, Affix } from 'antd';
import _ from 'lodash';
import ChatPane from '../ChatPane/ChatPane';
import './ConnectBotWindow.scss';
import getGroupMessages from '../../../Utils/GroupMessages';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import { formatTextMessage } from '../../../Utils/TextUtils';

const { TextArea } = Input;
const { MessageBubble } = ChatPane;

class ConnectBotWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBotInProgress: false,
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onClickSendMessage = this.onClickSendMessage.bind(this);
    this.inputFieldRef = React.createRef();
  }

  onMessageChange(e) {
    this.setState({
      messageValue: e.target.value,
    });
    setTimeout(() => this.inputFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }), 0);
  }

  componentDidMount() {
    const { messages, isCandidate360View, setCandidateViewHeaderVisibility } = this.props;
    if (messages?.length > 0 && isCandidate360View && setCandidateViewHeaderVisibility && this.inputFieldRef?.current) {
      setCandidateViewHeaderVisibility(false);
      setTimeout(() => this.inputFieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 0);
    }
  }

  onClickSendMessage(e) {
    e.preventDefault();
    const { messageValue } = this.state;
    const { onSendMessage } = this.props;
    onSendMessage(messageValue);
    this.setState({
      messageValue: null,
    });
  }

  render() {
    const { messageValue, isBotInProgress, candidate } = this.state;
    // ! TODO: conversationId shouldn't be passed to this component, this is for demo link generation purpose
    const {
      messages,
      featureToggleList,
      isCandidate360View,
      isOrgLevelCandidate,
      conversationPersonMapping,
      personsById,
      conversationId,
      personId,
    } = this.props;
    const isBotWriteEnabled = featureToggleList.BotWrite.IsEnabled;
    let groupMessages = _.cloneDeep(messages);
    if (groupMessages && groupMessages.length) {
      groupMessages = getGroupMessages(groupMessages);
      groupMessages.reverse();
    }
    if (!groupMessages) groupMessages = [];
    const isEnable = messageValue && messageValue.trim().length;
    const personDetails = conversationId
      ? _.get(personsById, personId || [conversationPersonMapping[conversationId]], {})
      : {};

    return (
      <div className="chat-window-container" ref={this.inputFieldRef}>
        <div className="question-progress">
          <div>Questions answered</div>
          <div>10/10</div>
        </div>
        {/* <a
          href={`${config.urls.aryaConnectCandidatePage}?personId=${personId}&conversationId=${conversationId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div style={{ height: '30px' }} />
        </a> */}
        <ChatPane
          className="connect-chat-window"
          isCandidate360View={isCandidate360View}
          inputFieldRef={this.inputFieldRef}
        >
          {groupMessages.map(message => (
            <MessageBubble
              key={message.Id}
              message={formatTextMessage(message.Body)}
              timestamp={message.CreatedTime}
              align={message.IsByPerson ? 'left' : 'right'}
              className={message.IsByBot ? 'bot-message' : ''}
              type={message.type}
              messageType={message.MessageType}
              DeliveryStatus={message.DeliveryStatus}
              readOnly={!isBotWriteEnabled}
              RequestStatus={message.RequestStatus}
              CreatedByName={message.CreatedByName || personDetails.Name}
              personDetails={personDetails}
              isByBot={message?.IsByBot}
            />
          ))}
        </ChatPane>

        {(() => {
          if (!isBotWriteEnabled || isOrgLevelCandidate) {
            return null;
          }
          return !isBotInProgress ? (
            <div>
              <Affix offsetBottom={0} className="chat-input-box">
                <div id="chat-input-box-wrap" className="connect-chat-window-flex-items">
                  <div className="chat-textarea">
                    <TextArea
                      placeholder="Type a message"
                      style={{ height: '40px', border: '0px', resize: 'none' }}
                      autosize={{ maxRows: 3 }}
                      value={messageValue}
                      onPressEnter={this.onClickSendMessage}
                      onChange={this.onMessageChange}
                    />
                  </div>
                  <Button
                    disabled={!isEnable}
                    type="primary"
                    id="connect-chat-send-button"
                    onClick={this.onClickSendMessage}
                    sk-event-name={getEventNameByCandidateStatus(
                      eventTypes.candidate.candidateConnectBot.sendBotMessage,
                      candidate
                    )}
                  >
                    <Icon type="arrow-right" />
                  </Button>
                </div>
              </Affix>
            </div>
          ) : (
            <div className="interrupt-chat">
              <span>To interrupt the conversation click</span>
              <Button
                className="interrupt-button"
                sk-event-name={getEventNameByCandidateStatus(
                  eventTypes.candidate.candidateConnectBot.interruptBotMessage,
                  candidate
                )}
              >
                Join
              </Button>
            </div>
          );
        })()}
      </div>
    );
  }
}

export default ConnectBotWindow;
