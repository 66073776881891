import React from 'react';
import { Select, Input, DatePicker } from 'antd';
import styles from './CandidateAdvanceFilters.module.scss';
import { EllipsedText } from '../../../../Utils/TextUtils';
import { getEmailTemplateIdentifier } from '../../../../Components/Common/EmailTemplateIdentifier/EmailTemplateIdentifier';
import { getErrorMessage } from '../../../../Utils/AdvanceFiltersJsonGenerationUtils';

const { Option } = Select;

const CandidateAdvanceFilterValues = props => {
  const {
    filterId,
    addFilterValues,
    handleSearch,
    notFoundContent,
    options,
    inputList,
    feildType,
    mode,
    attributeId,
    isLocationContentVisible,
    updateApplyButtonState,
    shortlistedCount,
  } = props;
  const [showError, setShowError] = React.useState(false);
  const [inputNumber, setInputNumber] = React.useState(undefined);
  const [errorType, setErrorType] = React.useState('');
  const dateFormat = 'MM/DD/YYYY';

  const handleChange = (value, option) => {
    addFilterValues(filterId, value, option);
  };

  const handleTextChange = e => {
    addFilterValues(filterId, e.target.value);
  };

  const handleNumberChange = e => {
    const inputValue = e.target.value?.trim();
    const convertedNumber = Number(inputValue);
    setInputNumber(convertedNumber);
    if (convertedNumber < 0) {
      setShowError(true);
      addFilterValues(filterId, '');
      updateApplyButtonState(true);
      setErrorType('NegativeInput');
    } else if (attributeId === 29 && convertedNumber > shortlistedCount) {
      setShowError(true);
      updateApplyButtonState(true);
      setErrorType('OverLimit');
    } else {
      setShowError(false);
      const filteredValue = attributeId === 8 ? inputValue.slice(0, 3) : inputValue;
      addFilterValues(filterId, String(filteredValue));
      updateApplyButtonState(false);
    }
  };

  const handleDateChange = (value, date) => {
    handleChange(date);
  };

  const handleComponentSet = type => {
    if (type === 'text') {
      return <Input placeholder="Values" onChange={handleTextChange} value={inputList[filterId]?.AttributeValue} />;
    }
    if (type === 'Number') {
      return (
        <div>
          <Input
            placeholder="Values"
            onChange={handleNumberChange}
            value={inputNumber || inputList[filterId]?.AttributeValue}
            data-testid="input-number"
          />
          {showError ? (
            <div className={styles.inputNumberErrortext} data-testid="Input-validation-error">
              {getErrorMessage(errorType)}
            </div>
          ) : null}
        </div>
      );
    }
    if (type === 'Date') {
      return (
        <DatePicker
          className={styles.datePicker}
          dropdownClassName={styles.datePickerDropdown}
          format={dateFormat}
          placeholder="MM/DD/YYYY"
          onChange={handleDateChange}
        />
      );
    }
    return (
      <div>
        <Select
          placeholder="Values"
          style={{ width: '100%' }}
          onChange={handleChange}
          onSearch={handleSearch}
          notFoundContent={notFoundContent}
          mode={mode}
          value={inputList[filterId]?.DisplayedValue}
          showSearch
          dropdownStyle={{ zIndex: 2100, minWidth: '200px', maxWidth: '500px' }}
          showArrow={false}
          data-testid="default-select"
          filterOption={false}
        >
          {options?.map(option => (
            <Option key={option?.id} value={option?.value}>
              {attributeId === 13 && option?.emailTemplateDetails?.IsDrip ? (
                <div className={styles.emailDripStyle}>
                  <EllipsedText text={option?.text} maxTextLength={20} />
                  <span>{getEmailTemplateIdentifier(option?.emailTemplateDetails?.IsDrip)}</span>
                </div>
              ) : (
                <EllipsedText text={option?.text} maxTextLength={30} />
              )}
            </Option>
          ))}
        </Select>
        {attributeId === 5 && isLocationContentVisible ? (
          <div className={styles.locationText} data-testid="Location-error">
            Minimum 5 miles
          </div>
        ) : null}
      </div>
    );
  };

  return <div>{handleComponentSet(feildType)}</div>;
};

export default CandidateAdvanceFilterValues;
