import { Button, Tooltip, Icon, Dropdown, Menu } from 'antd';
import React from 'react';
import styles from './WorkflowDetailsFooter.module.scss';

const create = 'Create';
const saveAs = 'Save As';
const save = 'Save';

const updateAndStartWorkflow = 'Update And Start Workflow';
const saveAsAndStartWorkflow = 'Save As And Start Workflow';

export default function WorkflowDetailsFooter(props) {
  const {
    onSaveWorkflow,
    loadingFlag,
    isSaveDisabled,
    onSaveDraftWorkflow,
    isSaveDraftDisabled,
    isCreate,
    isPreview,
    saveButtonTooltipMessage,
    isDraft,
    isWorkflowLive,
    initiateWorkflowFlag,
    workflowInitiated,
    startWorkflowWindowApiStatus,
    initiateWorkflow,
  } = props;
  let saveButtonText = '';
  if (isCreate) saveButtonText = create;
  else if (!isDraft && isWorkflowLive) saveButtonText = saveAs;
  else saveButtonText = save;

  const handleDropDown = e => {
    const { key, item } = e;
    e.domEvent.stopPropagation();
    if (item.props.disabled) return;
    if (key === '1') initiateWorkflow({ actionType: 'UpdateAndInitiate' });
    else if (key === '2') initiateWorkflow({ actionType: 'SaveCopyAndStart' });
  };

  const menu = (
    <Menu onClick={handleDropDown}>
      {!isWorkflowLive ? (
        <Menu.Item key="1" disabled={isWorkflowLive}>
          {updateAndStartWorkflow}
        </Menu.Item>
      ) : null}
      <Menu.Item key="2">{saveAsAndStartWorkflow}</Menu.Item>
    </Menu>
  );

  const getFooterButtonForWorkflowInitation = () => {
    if (isPreview) {
      return (
        <Tooltip title={saveButtonTooltipMessage}>
          <Button
            type="primary"
            shape="round"
            disabled={workflowInitiated === 'true' || isSaveDisabled}
            loading={startWorkflowWindowApiStatus === 'INPROGRESS'}
            onClick={() => {
              initiateWorkflow({ actionType: 'StartExisting' });
            }}
          >
            Start Workflow
          </Button>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={saveButtonTooltipMessage}>
        <Button
          type="primary"
          shape="round"
          disabled={workflowInitiated === 'true' || isSaveDisabled}
          loading={startWorkflowWindowApiStatus === 'INPROGRESS'}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            paddingRight: '0px',
          }}
          onClick={e => {
            e.stopPropagation();
            initiateWorkflow({ actionType: 'CloneAndStart' });
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            Start Workflow
            <Dropdown
              overlay={menu}
              onClick={e => e.stopPropagation()}
              trigger={['hover']}
              overlayClassName={styles.saveDropDown}
              overlayStyle={{ minWidth: '250px' }}
              placement="topRight"
            >
              <div className={styles.iconStyle} role="presentation" onClick={e => e.stopPropagation()}>
                <Icon type="down" />
              </div>
            </Dropdown>
          </div>
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className={styles.workflowDetailsFooter}>
      {initiateWorkflowFlag ? (
        <div className={styles.mailWindowFooterbuttonContainer}>{getFooterButtonForWorkflowInitation()}</div>
      ) : (
        <>
          <Button shape="round" onClick={onSaveDraftWorkflow} loading={loadingFlag} disabled={isSaveDraftDisabled}>
            Save Draft
          </Button>
          {saveButtonText ? (
            <Tooltip title={saveButtonTooltipMessage}>
              <Button
                type="primary"
                shape="round"
                onClick={() => onSaveWorkflow({ createNew: [create, saveAs].includes(saveButtonText), redirect: true })}
                loading={loadingFlag}
                disabled={isSaveDisabled}
              >
                {saveButtonText}
              </Button>
            </Tooltip>
          ) : null}
        </>
      )}
    </div>
  );
}
