import React from 'react';
import { Form, Switch } from 'antd';
import styles from './ExcludeCandidateSection.module.scss';
import TextWithInfoIcon from '../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';

const SwitchButton = props => {
  const { value, onButtonChange } = props;
  return <Switch checked={value} size="small" onChange={onButtonChange} data-testid="switch-button" />;
};

function ExcludeCandidateSection(props) {
  const { form, defaultFormValues, jobId } = props;
  const { PortalQueries } = defaultFormValues;
  const isExcludeCandidate = PortalQueries?.Internal?.ExcludeSegments ?? undefined;

  const onButtonChange = (value, _form, fieldValue) => {
    _form.setFieldsValue({
      [fieldValue]: !value ? undefined : jobId,
    });
  };

  return (
    <div className={styles.excludeCandidatesWrapper}>
      <Form.Item>
        {form.getFieldDecorator('ExcludeSegments', {
          initialValue: isExcludeCandidate,
        })(<SwitchButton form={form} onButtonChange={value => onButtonChange(value, form, 'ExcludeSegments')} />)}
      </Form.Item>
      <div className={styles.excludeCandidatesText}>
        <TextWithInfoIcon
          text={<div className={styles.excludeCandidatesLabel}>Hide included candidates</div>}
          tooltipTitle="Supported only for internal"
        />
      </div>
    </div>
  );
}

export default ExcludeCandidateSection;
