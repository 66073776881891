import React from 'react';
import { Avatar, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import PlusIcon from '../../Icons/PlusIcon';
import './Collaborators.scss';

const colors = [
  '#DD5F32',
  '#FF8B8B',
  '#FF8AAF',
  '#D88900',
  '#C86DB6',
  '#94A894',
  '#3B9A9C',
  '#005794',
  '#5AB0E3',
  '#FF8636',
  '#393E46',
  '#6ABE83',
  '#6B78B4',
  '#F1B854',
  '#29335C',
  '#8FCB8F',
  '#BF72D5',
  '#978E43',
  '#C3A150',
  '#2B9464',
  '#E3B469',
  '#DD5F32',
  '#3BD7C8',
];

function getConfiguration(text) {
  if (!text || !text.trim()) {
    return {};
  }
  const words = text.trim().split(' ');
  if (!words.length) {
    return {};
  }
  // get thumbnail text
  const startChar = words[0][0].toUpperCase();
  let thumbnailText = startChar;
  if (words.length > 1) {
    const endChar = words[words.length - 1][0].toUpperCase();
    thumbnailText += endChar;
  }
  // get thumnail color
  let sumOfCharCodes = 0;
  for (let index = 0; index < text.length;) {
    if (!Number.isNaN(text.charCodeAt(index))) {
      sumOfCharCodes += text.charCodeAt(index);
    }
    index += 1;
  }
  const thumbnailColor = colors[sumOfCharCodes % colors.length];
  return {
    color: thumbnailColor,
    text: thumbnailText,
  };
}

function getBubbles(bubbles, currentUserEmailId, jobOwner) {
  if (!bubbles || bubbles.length === 0) {
    return null;
  }
  const avatars = bubbles.map(bubble => {
    const config = getConfiguration(bubble.name);

    const { name, Email = "" } = bubble;
    let tooltipText = `${name} (${Email})`;
    if (tooltipText && bubble.Id === jobOwner) {
      tooltipText = tooltipText.concat(' (Owner)');
    }
    return (
      // TODO!: Remove uuid and use actual user ids
      <div className="shared-user-avatar" key={uuid()}>
        <Tooltip title={tooltipText}>
          <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
        </Tooltip>
      </div>
    );
  });
  if (avatars.length > 4) {
    avatars[3] = (
      <div className="shared-user-avatar" key="new">
        <Avatar style={{ color: '#ffffff', backgroundColor: '#647182' }}>{`+${avatars.length - 3}`}</Avatar>
      </div>
    );
    avatars.length = 4;
  }
  return avatars;
}

function getFullShareButton(onAddNew, skEventName) {
  return (
    <div
      className="share-button"
      role="button"
      onKeyPress={onAddNew}
      tabIndex={0}
      onClick={onAddNew}
      sk-event-name={skEventName}
    >
      <PlusIcon />
    </div>
  );
}

function Collaborators({ collaborators, onAddNew, currentUserEmailId, jobOwner, skEventName }) {
  if (!collaborators) {
    return null;
  }
  return (
    <div className="user-bubbles">
      <div className="shared-avatars">{getBubbles(collaborators, currentUserEmailId, jobOwner)}</div>
      <div className="add-new-bubble">
        <div className="shared-user-avatar">{getFullShareButton(onAddNew, skEventName)}</div>
      </div>
    </div>
  );
}

Collaborators.propTypes = {
  collaborators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddNew: PropTypes.func.isRequired,
};

export default Collaborators;

export { getConfiguration };
