import React from 'react';
import { Modal, Button, Checkbox, Alert } from 'antd';
import styles from './SegmentBulkShortlistConfirmationModal.module.scss';

export default function SegmentBulkShortlistConfirmationModal(props) {
  const { visible, includeAlreadyAssociatedCandidates, isBulkIconActivated, eventSegmentModalCallbacks } = props;

  return (
    <div className={styles.modalContainer}>
      <Modal
        visible={visible}
        onCancel={eventSegmentModalCallbacks.onModalCancel}
        maskClosable={false}
        footer={[
          <div className={styles.actionButton}>
            <Button
              type="default"
              shape="round"
              style={{ marginRight: '8px' }}
              onClick={eventSegmentModalCallbacks.onCancel}
              key="exclude-button"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={e => eventSegmentModalCallbacks.onSubmit(e, isBulkIconActivated)}
              key="association-button"
            >
              Add
            </Button>
          </div>,
        ]}
        width={500}
        zIndex={2050}
        closable={false}
      >
        <div className={styles.bulkConfirmation}>
          <div className={styles.bulkConfirmationTitle}>Are you sure you want to add ?</div>
          <Checkbox checked={includeAlreadyAssociatedCandidates} onChange={eventSegmentModalCallbacks.onCheckboxChange}>
            Also add matching rejected candidates
          </Checkbox>
        </div>
        <Alert message="This might take long time depending on the overall requests." type="info" showIcon />
      </Modal>
    </div>
  );
}
