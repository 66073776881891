import React from 'react';
import _ from 'lodash';
import { getInternalSourceWithCandidateId } from './SourceUtils';
import CandidateCardAssignedStatus from '../Components/CandidateCard/CandidateCardAssignedStatus';
import CandidateCardAppliedSourceTag from '../Components/CandidateCard/CandidateCardAppliedSourceTag';

export const getMetadataLabel = metadataItem => {
  switch (metadataItem) {
    case 'Industry':
      return 'Industry: ';
    case 'MoversPrediction':
      return 'Move Prediction: ';
    default:
      return null;
  }
};

const getDisplayedSeparator = (index, array, separator) => {
  return array[index] && index !== 0 ? <span>{separator}</span> : null;
};

export const getJoinedDisplayedInfo = (inputArray = [], separator = '|') => {
  const filteredArray = inputArray.filter(value => value !== null);
  return filteredArray.map((item, index) => {
    return (
      <>
        {getDisplayedSeparator(index, filteredArray, separator)} {item}
      </>
    );
  });
};

export const setElementMaxHeightByLines = (ref = {}, maxLinesToBeDisplayed, secondaryRef = {}) => {
  if (!maxLinesToBeDisplayed) return;
  const textRef = ref;
  const labelRef = secondaryRef;
  const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10);
  const refHeight = textRef.current?.clientHeight;
  const maxHeight = lineHeight * maxLinesToBeDisplayed;
  textRef.current.style.maxHeight = `${maxHeight}px`;
  if (labelRef.current && textRef.current) {
    if (lineHeight === refHeight) {
      labelRef.current.style.height = `${lineHeight}px`;
    } else {
      labelRef.current.style.height = `${maxHeight}px`;
    }
  }
};

export function getCandidateCardCandidateStatus(candidate, featureToggleList, atsConfig = {}) {
  const atsSource = getInternalSourceWithCandidateId(candidate.Sources);
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const candidateOrigin = candidate?.CandidateOrigin ?? '';
  if (isPaidJobServiceEnabled)
    return { displayedCandidateStatusMessage: null };
  let candidateStatus = _.get(atsSource, 'Status');
  if (candidateStatus) {
    if (candidateStatus.toLowerCase() === 'applied' && candidateOrigin)
      candidateStatus = candidateStatus?.concat(' through ', candidateOrigin);
    return { displayedCandidateStatusMessage: <div>{candidateStatus}</div>, showTrophyIcon: true };
  }
  if (candidate.AssignedStatus) {
    return {
      displayedCandidateStatusMessage: (
        <CandidateCardAssignedStatus assignedStatus={candidate.AssignedStatus} candidateOrigin={candidateOrigin} atsConfig={atsConfig} />
      ),
      statusTag: !_.isEmpty(atsConfig) ? candidate.AssignedStatus : null
    };
  }
  if (candidate.AppliedTime) {
    return { displayedCandidateStatusMessage: <CandidateCardAppliedSourceTag sourceName={candidateOrigin} atsConfig={atsConfig} />, statusTag: !_.isEmpty(atsConfig) ? "Applied" : null };
  }
  return { displayedCandidateStatusMessage: null };
}
export const getDisplayedMetadataContent = metadataContent => {
  switch (metadataContent) {
    case 'HighMover':
      return 'High';
    case 'LowMover':
      return 'Low';
    case 'MediumMover':
      return 'Medium';
    default:
      return metadataContent;
  }
};

export const getRequiredCandidateFilterInfo = (candidate, currentUserDetails) => {
  const shortlisted = candidate.Status === 'Shortlisted';
  const lastViewedByMe = currentUserDetails.Id === candidate.LastViewedBy;
  const lastViewedByOthers = candidate.LastViewedBy && !lastViewedByMe;
  const rejected = candidate.Status === 'Rejected';
  const allowedContactedStatuses = ['Contacted', 'Connected'];
  const contacted = allowedContactedStatuses.includes(candidate.ConnectionStatus);
  return [
    { viewedByMe: lastViewedByMe },
    { shortlisted },
    { viewedByOthers: lastViewedByOthers },
    { rejected },
    { contacted },
  ];
};

export const getDefaultCandidateCardView = (
  candidateCollapsedFilters,
  candidateInfo,
  currentUserDetails,
  featureToggleList,
  showDuplicateExpandedCandidateCard
) => {
  if (!candidateCollapsedFilters.length || !featureToggleList?.AdvanceSearchV2?.IsEnabled) return true;
  if (!showDuplicateExpandedCandidateCard) return false;
  const requiredCandidateInfo = getRequiredCandidateFilterInfo(candidateInfo, currentUserDetails);
  return !requiredCandidateInfo.some(item => candidateCollapsedFilters.some(filter => item[filter]));
};

export const getJobCandidateStatus = (candidate, featureToggleList) => {
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const candidateOrigin = candidate?.CandidateOrigin ?? '';
  let jobCandidateStatus = null

  if (candidate?.AssignedStatus) {
    jobCandidateStatus = candidate.AssignedStatus.toLowerCase() === 'applied' && candidateOrigin ? `${candidate.AssignedStatus} through ${candidateOrigin}` : candidate.AssignedStatus
  }

  else if (candidate?.AppliedTime && !isPaidJobServiceEnabled) {
    const candidateOriginText = candidateOrigin ? ` via ${candidateOrigin}` : ''
    jobCandidateStatus = `Applied${candidateOriginText}`
  }

  return jobCandidateStatus;
};

