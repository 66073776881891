import React from 'react';
import styles from './WorkflowCandidateContext.module.scss';

function WorkflowCandidateContext({ candidateContextId, workflowHistory }) {
  const workflowCandidateContext = JSON.parse(localStorage.getItem('WorkflowCandidateContext'));
  const currentCandidateContext = workflowCandidateContext[candidateContextId];
  const { jobId, jobTitle, candidateName } = currentCandidateContext || {};
  let displayStatus = '';
  const disclaimerMessage = "The green highlighted path represents the candidate's journey through the workflow.";
  const showDisclaimerMessage =
    workflowHistory?.[0]?.ActivityStatuses?.some(activity => activity.State === 'Completed') ?? false;
  if (workflowHistory !== undefined)
    switch (workflowHistory[0]?.Status) {
      case 'Running':
      case 'Idle':
      case 'Suspended':
        displayStatus = 'Active';
        break;
      case 'Faulted':
        displayStatus = 'Failed';
        break;
      case 'Cancelled':
        displayStatus = 'Stopped';
        break;
      default:
        displayStatus = workflowHistory[0]?.Status;
    }
  return (
    <>
      {showDisclaimerMessage && <div className={styles.workflowReportNote}>{disclaimerMessage}</div>}
      <div className={styles.workflowContextWrapper}>
        <div>
          <b>Job Id : </b>
          {jobId}
        </div>
        <div>
          <b>Job Title : </b>
          {jobTitle}
        </div>
        <div>
          <b>Candidate Name : </b>
          {candidateName}
        </div>
        {displayStatus && (
          <div>
            <b>Status : </b>
            {displayStatus}
          </div>
        )}
      </div>
    </>
  );
}

const MemoizedWorkflowCandidateContextComponent = React.memo(WorkflowCandidateContext);
export default MemoizedWorkflowCandidateContextComponent;
