import React from 'react';
import { Form, Select, Switch, Radio, Input, Dropdown, Menu, Checkbox, Tag, Tooltip } from 'antd';
import { validateBooleanString } from './FormValidators';

const { Item } = Form;
const { Option } = Select;
const { CheckableTag } = Tag;

const FormSelectComponent = props => {
  const {
    form,
    fieldDecoratorValue,
    label,
    initialValue,
    mode,
    placeholder,
    className,
    dropdownClassName,
    showArrow,
    inputValues,
    onChange,
    onSelect,
    onSearch,
    showSearch,
    allowClear,
    notFoundContent,
    rules,
    formItemClassName,
    renderToParentNode = true,
    isDisabled,
    formItemStyle,
  } = props;
  return (
    <Item className={formItemClassName} label={label} colon={false} style={formItemStyle}>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
        rules,
      })(
        <Select
          key={fieldDecoratorValue}
          mode={mode}
          placeholder={placeholder}
          className={className}
          dropdownClassName={dropdownClassName}
          getPopupContainer={trigger => (renderToParentNode ? trigger.parentNode : document.body)}
          showArrow={showArrow}
          showSearch={showSearch}
          onChange={value => {
            if (onChange) onChange(value);
          }}
          onSelect={value => {
            if (onSelect) onSelect(value);
          }}
          onSearch={value => {
            if (onSearch) onSearch(value);
          }}
          allowClear={allowClear}
          notFoundContent={notFoundContent}
          disabled={isDisabled}
        >
          {inputValues?.map(inputValue => {
            return (
              <Option value={inputValue.value || inputValue} key={inputValue.value || inputValue}>
                {inputValue.label || inputValue}
              </Option>
            );
          })}
        </Select>
      )}
    </Item>
  );
};

const FormCheckboxGroupComponent = props => {
  const { form, fieldDecoratorValue, label, initialValue, className, inputValues } = props;
  return (
    <Item label={label} colon={false}>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
      })(<Checkbox.Group className={className} key={fieldDecoratorValue} options={inputValues}></Checkbox.Group>)}
    </Item>
  );
};

const FormSwitchComponent = props => {
  const { form, fieldDecoratorValue, label, initialValue, className } = props;
  return (
    <Item label={label} colon={false} className={className}>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
        valuePropName: 'checked',
      })(<Switch key={fieldDecoratorValue} />)}
    </Item>
  );
};

const FormRadioGroupComponent = props => {
  const { form, fieldDecoratorValue, label, initialValue, className, inputValues } = props;
  return (
    <Item label={label} colon={false}>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
      })(<Radio.Group key={fieldDecoratorValue} className={className} options={inputValues}></Radio.Group>)}
    </Item>
  );
};

const FormInputComponent = props => {
  const { form, fieldDecoratorValue, label, initialValue, placeholder, className, rules, tooltipText, isNameMasked } =
    props;
  return (
    <Item
      label={
        <Tooltip title={tooltipText}>
          <span style={{}}>{label}</span>
        </Tooltip>
      }
      colon={false}
    >
      {form.getFieldDecorator(fieldDecoratorValue, {
        rules,
        initialValue,
      })(<Input key={fieldDecoratorValue} placeholder={placeholder} className={className} disabled={isNameMasked} />)}
    </Item>
  );
};

const FormDropdownInputComponent = props => {
  const {
    form,
    fieldDecoratorValue,
    label,
    initialValue,
    placeholder,
    className,
    dropdownOverlayClassName,
    isDisabled,
    inputValues,
    onChange,
    allowClear,
    autoComplete,
    validityStatus,
    errorMessage,
    onClick,
    onKeyUp,
    formItemClassName,
  } = props;
  const dropdownMenu = inputValues?.length ? (
    <Menu>
      {inputValues.map(dropdownItem => (
        <Menu.Item key={dropdownItem} onClick={() => onClick(dropdownItem)}>
          {dropdownItem}
        </Menu.Item>
      ))}
    </Menu>
  ) : (
    <div />
  );
  return (
    <Item
      label={label}
      colon={false}
      validateStatus={!validityStatus ? 'error' : ''}
      help={!validityStatus ? errorMessage : ''}
      className={formItemClassName}
    >
      <Dropdown
        overlay={dropdownMenu}
        trigger={['click']}
        overlayClassName={dropdownOverlayClassName}
        disabled={isDisabled}
      >
        {form.getFieldDecorator(fieldDecoratorValue, {
          initialValue,
        })(
          <Input
            key={fieldDecoratorValue}
            placeholder={placeholder}
            className={className}
            onChange={event => {
              if (onChange) onChange(event.target.value);
            }}
            allowClear={allowClear}
            autoComplete={autoComplete}
            onKeyUp={event => {
              if (onKeyUp) onKeyUp(event.target.value);
            }}
          />
        )}
      </Dropdown>
    </Item>
  );
};

const FormCheckableTagComponent = props => {
  const { form, fieldDecoratorValue, displayText, className, initialValue } = props;
  return (
    <Item>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
        valuePropName: 'checked',
      })(<CheckableTag className={className}>{displayText}</CheckableTag>)}
    </Item>
  );
};

const FormBooleanQueryTextArea = props => {
  const { form, fieldDecoratorValue, label, initialValue, placeholder, className } = props;
  return (
    <Item label={label} colon={false}>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
        rules: [{ validator: (rule, value, callback) => validateBooleanString(rule, value, callback, form) }],
      })(<Input.TextArea className={className} style={{ overflow: 'hidden' }} placeholder={placeholder} />)}
    </Item>
  );
};

export {
  FormSelectComponent,
  FormSwitchComponent,
  FormRadioGroupComponent,
  FormInputComponent,
  FormCheckboxGroupComponent,
  FormDropdownInputComponent,
  FormCheckableTagComponent,
  FormBooleanQueryTextArea,
};
