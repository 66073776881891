import { getCandidateSource } from '../Utils/SourceUtils';
import { unlockCandidateResume, fetchCandidateDetails } from './CandidateActions';
import { fetchContact, pushCandidateToConnectAndFetchContact } from './ConnectActions';

function onFetchContact({ currentJobDetails, candidate }) {
  return dispatch => {
    const jobId = currentJobDetails.JobId;
    const jobGuid = currentJobDetails.JobGuid;
    const candidateInfo = { ...candidate, jobId, candidateId: candidate.Id };
    const pushCandidateInfo = { refId: jobGuid, Country: currentJobDetails.CountryCode };
    const candidateSource = getCandidateSource(candidate);
    const isPortalCandidate = Boolean(candidateSource?.Portal);
    const isPassiveCandidate = Boolean(candidateSource?.Group);
    const unlockCandidatefilter = {
      candidateId: candidate.Id,
      jobId,
      source: candidateSource,
    };
    let isContactFetched = false;
    if (isPortalCandidate || isPassiveCandidate) {
      const portal = isPortalCandidate ? candidateSource.Portal.toLowerCase() : 'social';
      if (portal !== 'social' && portal !== 'indeed') {
        if (portal === 'resumelibrary' || portal === 'cvlibrary') {
          if (!candidateInfo.IsUnlocked || !candidateInfo.PersonId) {
            dispatch(unlockCandidateResume(unlockCandidatefilter, pushCandidateInfo));
            isContactFetched = true;
          }
        } else if (!candidateInfo.IsDownloaded || !candidateInfo.PersonId) {
          dispatch(fetchCandidateDetails(candidateInfo, pushCandidateInfo, true, true, false));
          isContactFetched = true;
        }
      }
      if (!isContactFetched) {
        if (!candidateInfo.PersonId || !candidateInfo.ConversationId) {
          dispatch(pushCandidateToConnectAndFetchContact(jobId, candidateInfo, jobGuid, true));
        } else {
          dispatch(fetchContact(candidateInfo.PersonId, candidateInfo.ConversationId, candidateInfo.Id, jobId));
        }
      }
    } else if (!candidateInfo.IsUnlocked || !candidateInfo.PersonId || !candidateInfo.ConversationId) {
      dispatch(unlockCandidateResume(unlockCandidatefilter, pushCandidateInfo));
    } else if (!isContactFetched) {
      dispatch(fetchContact(candidateInfo.PersonId, candidateInfo.ConversationId, candidateInfo.Id, jobId));
    }
  };
}

export { onFetchContact };
