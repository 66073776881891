import React from 'react';
import { Tooltip } from 'antd';
import styles from './PersonalInformationSection.module.scss';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import PersonalInformationWrapper from '../../../../CompoundComponents/PersonalInformationWrapper/PersonalInformationWrapper';
import Name from '../../../../CompoundComponents/PersonalInformationWrapper/Name';
import Phone from '../../../../CompoundComponents/PersonalInformationWrapper/Phone';
import Email from '../../../../CompoundComponents/PersonalInformationWrapper/Email';
import TextWithInfoIcon from '../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';

function PersonalInformationSection(props) {
  const { form, defaultFormValues, sourceName, maskingConfig, diversityForNameText } = props;
  const onClear = () => {
    form.setFieldsValue({
      Name: undefined,
      Email: undefined,
      Mobile: undefined,
    });
  };
  const { IsNameMasked: isNameMasked } = maskingConfig;
  const tooltipText = isNameMasked
    ? diversityForNameText('Personal Information', 'Name / Email Address / Phone')
    : `Personal details are supported by Internal & ${sourceName} (${sourceName} supports candidate name only)`;
  return (
    <div className={`${styles.personalInfoSection} ${isNameMasked ? styles.isDiversityActive : ''}`}>
      <div className={styles.personalInfoHeader} style={{ cursor: 'auto' }}>
        <TextWithInfoIcon
          text={<div className={styles.personalInfoLabel}>Personal Details</div>}
          tooltipTitle={tooltipText}
        />
        <div className={styles.personalInfoClear}>
          <ClearAllButton onClear={onClear} />
        </div>
      </div>
      <div className={`${styles.personalInfoForm} ${isNameMasked ? styles.diversityForPersonalInformation : ''}`}>
        <PersonalInformationWrapper form={form}>
          <div className={styles.personalInformationSectionRow}>
            <div className={styles.formItem}>
              <Tooltip title={tooltipText} placement="topLeft">
                <div className={`${styles.formLabel}`}>Name</div>
              </Tooltip>
              <Name initialValue={defaultFormValues?.Name} isNameMasked={isNameMasked} />
            </div>
            <div className={styles.formItem}>
              <div className={styles.formLabel}>Email Address</div>
              <Email initialValue={defaultFormValues?.Email} isNameMasked={isNameMasked} />
            </div>
          </div>
          <div className={styles.personalInformationSectionRow}>
            <div className={styles.formItem}>
              <div className={styles.formLabel}>Phone</div>
              <Phone initialValue={defaultFormValues?.Mobile} isNameMasked={isNameMasked} />
            </div>
          </div>
        </PersonalInformationWrapper>
      </div>
    </div>
  );
}

export default PersonalInformationSection;
