import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import styles from './Candidate360MessageCard.module.scss';

import { getMessageDeliveryStatusIcon, getMessageDeliveryStatusInformation } from '../Utils/SmsUtils';

const Candidate360MessageCard = ({ data, openComposeTextModal, setPhoneNumber }) => {
  const {
    to,
    sentTime,
    message,
    status,
    unreadSmsCount,
    IsByPerson: isByPerson,
    createdByName,
    from,
    sendMessageApiStatus,
    pendingState,
    messageDeliveryStatusInformation,
  } = data;
  const isMessageUnRead = unreadSmsCount > 0;
  const infoText = isByPerson ? 'Received From:' : 'Sent To:';
  const displayMessage = message.length > 80 ? `${message.substring(0, 80)}...` : message;
  const messageCardStyle = isMessageUnRead ? { background: '#F5F5F5' } : {};
  const messageDisplayStyle = { fontWeight: 'bold', color: '#1F2730' };
  const candidatePhoneNumber = isByPerson ? from : to;

  const onClickMessageCard = () => {
    if (Array.isArray(candidatePhoneNumber)) {
      setPhoneNumber(candidatePhoneNumber[0]);
    } else {
      setPhoneNumber(candidatePhoneNumber);
    }
    openComposeTextModal(true);
  };

  const getMessageStatusIconWithToolTip = () => {
    const iconForStatus = getMessageDeliveryStatusIcon(status, messageDeliveryStatusInformation);
    const tooltipMessage =
      status.toLowerCase() === 'delivered'
        ? ''
        : getMessageDeliveryStatusInformation(messageDeliveryStatusInformation, status);
    return (
      <Tooltip key="message-status-icon" title={tooltipMessage} zIndex={2223} placement="left">
        {iconForStatus}
      </Tooltip>
    );
  };

  const statusColor = status === 'Failed' ? { color: 'rgba(245, 34, 45, 1)' } : { color: '#1f2730' };
  const showSkeleton = pendingState && sendMessageApiStatus === 'INPROGRESS';
  if (sendMessageApiStatus === 'FAILED') return '';

  const messageStatus = isByPerson ? status : getMessageStatusIconWithToolTip();

  return (
    <Skeleton active loading={showSkeleton}>
      <div
        className={styles.candidate360Message}
        style={messageCardStyle}
        onClick={onClickMessageCard}
        role="presentation"
      >
        <div className={styles.candidateJobInfo}></div>
        <div className={styles.contentContainer}>
          <p className={styles.phoneNumber}>
            {infoText} {candidatePhoneNumber}
          </p>
          <small>{sentTime}</small>
        </div>
        <div className={styles.contentContainer}>
          <p className={styles.message} style={isMessageUnRead ? messageDisplayStyle : {}}>
            {createdByName && <span style={{ fontWeight: 500, color: '#1F2730' }}>{createdByName}: </span>}
            {displayMessage}
          </p>
          <p className={styles.status} style={isMessageUnRead ? messageDisplayStyle : statusColor}>
            {isMessageUnRead ? `${unreadSmsCount} unread messages` : messageStatus}
          </p>
        </div>
      </div>
    </Skeleton>
  );
};

export default Candidate360MessageCard;
