import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { JobReducer } from './Reducers/JobReducer';
import { ManualSearchReducer } from './Reducers/ManualSearchReducer';
import { ManualSearchCandidateReducer } from './Reducers/ManualSearchCandidateReducer';
import { CandidateExtraInfoReducer } from './Reducers/CandidateExtraInfoReducer';
import { JobStatusReducer } from './Reducers/JobStatusReducer';
import { AppNotificationReducer } from './Reducers/AppNotificationReducer';
import { ConfigReducer } from './Reducers/ConfigReducer';
import { ClientReducer } from './Reducers/ClientReducer';
import { CandidateReducer } from './Reducers/CandidateReducer';
import { ConnectReducer } from './Reducers/ConnectReducer';
import { ConnectSettingsReducer } from './Reducers/ConnectSettingsReducer';
import { AryaPayReducer } from './Reducers/AryaPayReducer';
import { AtsReducer } from './Reducers/AtsReducer';
import { NotificationReducer } from './Reducers/NotificationReducer';
import { MessageConversationsReducer } from './Reducers/MessageConversationsReducer';
import { MailConversationsReducer } from './Reducers/MailConversationsReducer';
import { CallConversationsReducer } from './Reducers/CallConversationsReducer';
import { ChatConversationsReducer } from './Reducers/ChatConversationsReducer';
import { UserReducer } from './Reducers/UserReducer';
import { ApiStatusReducer } from './Reducers/ApiStatusReducer';
import { MessagePopupReducer } from './Reducers/MessagePopupReducer';
import { DripTemplates } from './Reducers/DripTemplates';
import { EmailTemplates } from './Reducers/EmailTemplates';
import { TextTemplates } from './Reducers/TextTemplates';
import { AryabotTemplates } from './Reducers/AryabotTemplates';
import { AryabotContextReducer } from './Reducers/AryabotContextReducer';
import MergeTagsReducer from './Reducers/MergeTagsReducer';
import { AryaNotifyReducer } from './Reducers/AryaNotifyReducer';
import { CallDialogReducer } from './Reducers/CallDialogReducer';
import { UserSessionReducer } from './Reducers/UserSessionReducer';
import { OrganizationsReducer } from './Reducers/OrganizationsReducer';
import { PortalReducer } from './Reducers/PortalReducer';
import { FeatureToggleReducer } from './Reducers/FeatureToggleReducer.ts';
import { OrderReducer } from './Reducers/OrderReducer';
import { PulseSignupReducer } from './Reducers/PulseSignupReducer';
import { SubscriptionPlansReducer } from './Reducers/SubscriptionPlansReducer';
import { AryaPayOrderReducer } from './Reducers/AryaPayOrderReducer';
import { SuggestionsReducer } from './Reducers/SuggestionsReducer';
import { CognitoReducer } from './Reducers/CognitoReducer';
import { ChatGptReducer } from './Reducers/ChatGptReducer';
import { SalesDashBoardReducer } from './Reducers/SalesDashBoardReducer';
import { ReportPortalReducer } from './Reducers/ReportPortalReducer';
import { LocationSearchReducer } from './Reducers/LocationSearchReducer';
import { CareerProjectionReducer } from './Reducers/CareerProjectionReducer';
import { JobDistributionReducer } from './Reducers/JobDistributionReducer';
import { RegionReducer } from './Reducers/RegionReducer';
import { JobCandidatesTabReducer } from './Reducers/JobCandidatesTabReducer';
import { JobActivityReducer } from './Reducers/JobActivityReducer';
import { AryaJobDescriptionReducer } from './Reducers/AryaJobDescriptionReducer';
import { AnnouncementReducer } from './Reducers/AnnouncementReducer';
import { WorkflowReducer } from './Reducers/WorkflowReducer';
import { WorkflowReportReducer } from './Reducers/WorkflowReportReducer';
import { WorkflowWindowReducer } from './Reducers/WorkflowWindowReducer';
import { ScoutingAgentReducer } from './Reducers/ScoutingAgentReducer';
import { SubsegmentReducer } from './Reducers/SubsegmentReducer';
import { AoDReducer } from './Reducers/AoDReducer';
import { CandidateDeDuplicationReducer } from './Reducers/CandidateDeDuplicationReducer';
import { CandidateAdvanceFiltersReducer } from './Reducers/CandidateAdvanceFiltersReducer';

const reducers = {
  JobReducer,
  JobStatusReducer,
  AppNotificationReducer,
  CallDialogReducer,
  ConfigReducer,
  CandidateReducer,
  ClientReducer,
  NotificationReducer,
  ConnectReducer,
  ConnectSettingsReducer,
  MessageConversationsReducer,
  MailConversationsReducer,
  CallConversationsReducer,
  UserReducer,
  ApiStatusReducer,
  MessagePopupReducer,
  DripTemplates,
  EmailTemplates,
  TextTemplates,
  AryabotTemplates,
  AryabotContextReducer,
  ChatConversationsReducer,
  AryaPayReducer,
  MergeTagsReducer,
  UserSessionReducer,
  AryaNotifyReducer,
  OrganizationsReducer,
  AtsReducer,
  FeatureToggleReducer,
  PortalReducer,
  OrderReducer,
  PulseSignupReducer,
  SubscriptionPlansReducer,
  AryaPayOrderReducer,
  SuggestionsReducer,
  CognitoReducer,
  ChatGptReducer,
  SalesDashBoardReducer,
  ReportPortalReducer,
  LocationSearchReducer,
  CareerProjectionReducer,
  JobDistributionReducer,
  RegionReducer,
  ManualSearchReducer,
  ManualSearchCandidateReducer,
  CandidateExtraInfoReducer,
  JobCandidatesTabReducer,
  JobActivityReducer,
  AryaJobDescriptionReducer,
  AnnouncementReducer,
  WorkflowReducer,
  WorkflowReportReducer,
  WorkflowWindowReducer,
  ScoutingAgentReducer,
  SubsegmentReducer,
  AoDReducer,
  CandidateDeDuplicationReducer,
  CandidateAdvanceFiltersReducer,
};

const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    const jobCandidateListPageSize = localStorage.getItem('CandidateListPageSize:job');
    const segmentCandidateListPageSize = localStorage.getItem('CandidateListPageSize:segment');
    const creditInfoShowAgainStatuses = localStorage.getItem('CreditInfoShowAgainStatuses');
    const creditInfoAlertStatuses = localStorage.getItem('CreditInfoAlertStatuses');
    const bulkShortlistCreditInfoAlertStatus = localStorage.getItem('BulkShortlistCreditInfoAlertStatus');
    const candidateViewStats = localStorage.getItem('candidateViewStats');
    const userSkillSearchPreference = localStorage.getItem('userSkillSearchPreference');
    localStorage.clear();
    if (jobCandidateListPageSize) localStorage.setItem('CandidateListPageSize:job', jobCandidateListPageSize);
    if (segmentCandidateListPageSize)
      localStorage.setItem('CandidateListPageSize:segment', segmentCandidateListPageSize);
    if (creditInfoShowAgainStatuses) localStorage.setItem('CreditInfoShowAgainStatuses', creditInfoShowAgainStatuses);
    if (creditInfoAlertStatuses) localStorage.setItem('CreditInfoAlertStatuses', creditInfoAlertStatuses);
    if (bulkShortlistCreditInfoAlertStatus)
      localStorage.setItem('BulkShortlistCreditInfoAlertStatus', bulkShortlistCreditInfoAlertStatus);
    if (candidateViewStats) localStorage.setItem('candidateViewStats', candidateViewStats);
    if (userSkillSearchPreference) localStorage.setItem('userSkillSearchPreference', userSkillSearchPreference);
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

let composeEnhancers = compose;
let middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    duration: true,
  });
  middlewares = [...middlewares, logger];
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
