import React from 'react';
import { Radio } from 'antd';
import './CandidateSortingFilter.scss';

const RadioGroup = Radio.Group;

export default class CandidateSortingFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: 'Ranking',
    };
  }

  setSelectedFilter = filter => {
    this.setState({
      selectedFilter: filter,
    });
  };

  onChange = event => {
    const { applySortingFilter } = this.props;
    this.setSelectedFilter(event.target.value);
    applySortingFilter(event.target.value);
  };

  render() {
    const { selectedFilter } = this.state;
    const sortingTypeToText = {
      Ranking: 'Star ranking on top',
      Relevance: 'Relevant on top',
      Latest: 'Latest on top',
    };

    return (
      <div className="sorting-filter">
        <div className="sortingfilter-sortby-clear">
          <div className="sortingfilter-sortby">Sort by</div>
        </div>
        <div>
          <RadioGroup onChange={this.onChange} value={selectedFilter}>
            {Object.keys(sortingTypeToText).map(sortingType => (
              <div className="candidate-sorting-radio-div" key={sortingType}>
                <Radio className="candidate-sorting-radio" value={sortingType}>
                  {sortingTypeToText[sortingType]}
                </Radio>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
    );
  }
}
