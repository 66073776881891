import React from 'react';
import { Icon } from 'antd';
import classNames from 'classnames';
import styles from './Candidate360Info.module.scss';
import ComposeEmailAndMessageButton from '../ComposeEmailAndMessageButton/ComposeEmailAndMessageButton';
import { isPulseUser } from '../../../Utils/ConfigUtils';

function Candidate360EmailAndMessageEmptyScreen(props) {
  const {
    setComposeModalVisibility,
    activeTab,
    infoMessage,
    getFetchContactButton,
    isCandidateViewHeaderVisible,
    isDisabled,
    iconStyle,
    workflowheaderMessage,
    infoMessageStyle,
    contactInfoStyle,
    composeEmailType,
    hideButton,
  } = props;

  const isPulse = isPulseUser();
  const mediaType = activeTab === 'message' ? 'a text' : 'an email';
  const defaultMessage = `Send ${mediaType} to start connecting`;
  const message = infoMessage || defaultMessage;
  const workflowheaderText = workflowheaderMessage || 'You are starting a new workflow';
  const button = getFetchContactButton ? (
    getFetchContactButton()
  ) : (
    <ComposeEmailAndMessageButton
      setComposeModalVisibility={setComposeModalVisibility}
      activeTab={activeTab}
      isDisabled={isDisabled}
      composeEmailType={composeEmailType}
    />
  );
  const shouldHideInfoMessage = isPulse && activeTab === 'message';
  return (
    <div
      className={classNames(styles.contactInfoContainer, {
        [styles.extendedContactInfoContainer]: !isCandidateViewHeaderVisible,
      })}
      style={contactInfoStyle}
    >
      <div className={styles.chatIconAndMessage}>
        <Icon type="wechat" className={styles.weChatIcon} style={iconStyle} />
        {infoMessage || shouldHideInfoMessage ? null : (
          <div className={styles.contactInfoMessage} style={{ infoMessageStyle }}>
            You are starting a new conversation
          </div>
        )}
        {activeTab === 'workflow' && <div className={styles.contactInfoMessage}>{workflowheaderText}</div>}
      </div>
      {shouldHideInfoMessage ? null : <div className={styles.contactInfoText}>{message}</div>}
      {!hideButton && button}
    </div>
  );
}

export default Candidate360EmailAndMessageEmptyScreen;
