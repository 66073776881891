import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Drawer, Icon, Table, Menu, Dropdown, Modal, Button, Radio, Checkbox, Spin, Empty, message } from 'antd';
import styles from './ScoutingAgentDrawer.module.scss';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { setSelectedCriteriaId, setManualSearchCriteriaType } from '../../../Actions/ManualSearchActions';
import * as JobActions from '../../../Actions/JobActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  getScoutingAgentofJob,
  getScoutingAgentDrawerStatus,
  getScoutingAgentConfig,
  getScoutingAgentsofEnabledJobs,
} from '../../../Reducers/ScoutingAgentReducer';
import { getManualSearchFormMinimizedStatus } from '../../../Reducers/JobReducer';
import { getIsReactivationAllowed } from '../../../Reducers/ManualSearchReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getLocalDateFormat } from '../../../Utils/RelativeTimeCalculator';
import { EllipsedText } from '../../../Utils/TextUtils';
import { getActiveTab } from '../../../Reducers/JobCandidatesTabReducer';

const drawerStyle = {};

const mapDispatchToProps = {
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
  setScoutingAgentModalVisibilityStatus: ScoutingAgentActions.setScoutingAgentModalVisibility,
  deleteScoutingAgentAction: ScoutingAgentActions.deleteScoutingAgentAction,
  startScoutingAgentAction: ScoutingAgentActions.startScoutingAgentAction,
  stopScoutingAgentAction: ScoutingAgentActions.stopScoutingAgentAction,
  setSelectedScoutingAgentId: ScoutingAgentActions.setSelectedScoutingAgentIdAction,
  setLastScoutingAgentDeletedId: ScoutingAgentActions.setLastScoutingAgentDeletedId,
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
  setAgentIdViewCriteria: setSelectedCriteriaId,
  fetchScoutingAgentsByJobIds: ScoutingAgentActions.fetchScoutingAgentsByJobIds,
  setScoutingAgentSearchCriteriaModalVisibility: ScoutingAgentActions.setScoutingAgentSearchCriteriaModalVisibility,
  updateMinimizeManualSearchStatus: JobActions.setMinimizeManualSearchStatus,
  updateManualSearchCriteriaType: setManualSearchCriteriaType,
};

const mapStateToProps = (state, props) => ({
  scoutingAgents: getScoutingAgentofJob(state, props.jobId),
  activeScoutingAgentCount: getScoutingAgentsofEnabledJobs(state, props.jobId),
  scoutingAgentDrawerStatus: getScoutingAgentDrawerStatus(state),
  jobScoutingAgentJobApiStatus: getApiStatus(state, 'scoutingAgentApiStatus'),
  deleteScoutingAgentApiStatus: getApiStatus(state, 'deleteScoutingAgentApiStatus'),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  getManualSearchFormMinimizedStatus: getManualSearchFormMinimizedStatus(state, { jobId: props.jobId }),
  isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
  activeTab: getActiveTab(state),
});

const generateMenu = (setVisible, data, menuItems) => {
  return (
    <Menu>
      {menuItems.map(({ title, onClick }) => (
        <Menu.Item
          key={title}
          onClick={() => {
            if (title === 'Delete' || title === 'Stop') setVisible(true);
            if (typeof onClick === 'function') onClick(data.Id, data.Name, title);
          }}
        >
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const DeleteModal = props => {
  const {
    visible,
    setVisible,
    agentData,
    deleteScoutingAgentAction,
    setScoutingAgentDrawerVisibility,
    deleteScoutingAgentApiStatus,
    jobScoutingAgentJobApiStatus,
    modalData,
    fetchDrawerCandidates,
    fetchScoutingAgentsByJobIds,
    setLastScoutingAgentDeletedId,
    jobId,
    candidateContext,
  } = props;
  const [deleteData, setDeleteData] = React.useState({});
  const { title, buttonText, content, onClickAction, context } = modalData;
  const handleDelete = async () => {
    const queryString = `Retain=${deleteData.retain}&DeleteRejected=${deleteData.deleteRejected}`;
    await deleteScoutingAgentAction(agentData.id, queryString);
    await fetchScoutingAgentsByJobIds([jobId]);
    setVisible(false);
    fetchDrawerCandidates();
    setScoutingAgentDrawerVisibility(false);
    setLastScoutingAgentDeletedId(agentData.id);
  };
  const handleChange = (e, id) => {
    setDeleteData({
      ...deleteData,
      [id]: id === 'retain' ? e.target.value : e.target.checked,
    });
  };

  React.useEffect(() => {
    setDeleteData({ retain: 'KeepAll', deleteRejected: false });
  }, [agentData.id]);

  const handleStop = async () => {
    await onClickAction(agentData.id, agentData.name);
    await fetchScoutingAgentsByJobIds([jobId]);
    setVisible(false);
    fetchDrawerCandidates();
  };
  const onActionButtonClick = context === 'Delete' ? handleDelete : handleStop;

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={() => setVisible(!visible)}
      onCancel={() => setVisible(!visible)}
      zIndex={2048}
      width="fit-content"
      bodyStyle={{ width: '533px' }}
      footer={[
        <Button key="back" shape="round" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          loading={deleteScoutingAgentApiStatus === 'INPROGRESS' || jobScoutingAgentJobApiStatus === 'INPROGRESS'}
          key="submit"
          shape="round"
          type="danger"
          className={styles.deleteButton}
          onClick={onActionButtonClick}
        >
          {buttonText}
        </Button>,
      ]}
    >
      <p>
        {content} <b>‘{agentData.name}’</b>?
      </p>
      {context === 'Delete' && (
        <Radio.Group id="retain" onChange={e => handleChange(e, 'retain')} value={deleteData.retain}>
          <Radio value="KeepAll">Keep all</Radio>
          <Radio value="KeepShortlisted">{`Keep ${
            candidateContext === 'segments' ? 'included' : 'shortlisted'
          }`}</Radio>
          <Radio value="DeleteAll">Delete all</Radio>
          {deleteData.retain === 'DeleteAll' && (
            <Checkbox
              id="deleteRejected"
              onChange={e => handleChange(e, 'deleteRejected')}
              value={deleteData.deleteRejected}
            >
              {`Delete ${candidateContext === 'segments' ? 'removed' : 'rejected'} also`}
            </Checkbox>
          )}
        </Radio.Group>
      )}
    </Modal>
  );
};

const ScoutingAgentDrawer = props => {
  const [visible, setVisible] = React.useState(false);
  const [agentData, setAgentData] = React.useState({});
  const {
    scoutingAgentDrawerStatus,
    setScoutingAgentDrawerVisibility,
    setScoutingAgentModalVisibilityStatus,
    jobScoutingAgentJobApiStatus,
    scoutingAgents,
    startScoutingAgentAction,
    stopScoutingAgentAction,
    setSelectedScoutingAgentId,
    deleteScoutingAgentAction,
    deleteScoutingAgentApiStatus,
    fetchJobScoutingAgent,
    fetchScoutingAgentsByJobIds,
    jobId,
    featureToggleList,
    scoutingAgentConfig,
    history,
    setLastScoutingAgentDeletedId,
    activeScoutingAgentCount,
    setAgentIdViewCriteria,
    candidateContext,
    setScoutingAgentSearchCriteriaModalVisibility,
  } = props;
  const [scoutingAgentsData, setScoutingAgentsData] = React.useState(scoutingAgents);
  const [modalData, setModalData] = React.useState({
    title: '',
    buttonText: '',
    apiStatus: '',
    onClickAction: '',
    content: '',
  });

  React.useEffect(() => {
    if (featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && jobId) {
      fetchJobScoutingAgent(jobId);
    }
  }, [jobId, featureToggleList.ScoutingAgent.IsEnabled, fetchJobScoutingAgent, scoutingAgentConfig]);

  React.useEffect(() => {
    setScoutingAgentsData(scoutingAgents.filter(agent => !agent.IsDeleted));
  }, [scoutingAgents]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(history?.location?.search || null);
    const params = Object.fromEntries(urlSearchParams.entries());
    const scoutingAgentId = params.scoutingAgent;
    if (scoutingAgentId && jobScoutingAgentJobApiStatus === 'COMPLETED') {
      openScoutingAgentModal(scoutingAgentId);
      removeQueryParam('scoutingAgent');
    }
  }, [jobId, jobScoutingAgentJobApiStatus]);

  const handleViewEditAgentCriteria = agentId => {
    const { activeTab } = props;
    const { pathname } = history.location;
    const lowercasePathname = pathname.toLowerCase();
    if (!pathname.includes(jobId) && !lowercasePathname.includes('leo')) {
      history.push(`/${candidateContext}/${jobId}`);
    }
    if (activeTab !== 'sourced') {
      history.push(`/${candidateContext}/${jobId}/candidates?status=sourced`);
    }
    setAgentIdViewCriteria({ jobId, criteriaId: agentId });
    setSelectedScoutingAgentId(agentId);
    setScoutingAgentDrawerVisibility(false);
    setScoutingAgentSearchCriteriaModalVisibility(true);
  };
  const handleViewEditAgent = agentId => {
    setSelectedScoutingAgentId(agentId);
    setScoutingAgentModalVisibilityStatus(true, 'update');
  };

  function removeQueryParam(queryParam) {
    const urlObj = new URL(window.location.href);
    const { searchParams } = urlObj;
    searchParams.delete(queryParam);
    const newUrl = `${urlObj.pathname}?${searchParams.toString()}${urlObj.hash}`;
    history?.replace(newUrl);
  }

  function onCreateScoutingAgentClick() {
    const { updateMinimizeManualSearchStatus, updateManualSearchCriteriaType, isReactivationAllowed } = props;
    if (activeScoutingAgentCount >= 5) {
      message.error('More than 5 scouting agents are not allowed');
      return;
    }
    const { pathname } = history.location;
    const lowercasePathname = pathname.toLowerCase();
    const allowedPaths = ['candidates', 'intel', 'notes'];
    const isAllowedPath =
      allowedPaths.some(path => lowercasePathname.includes(path)) && !lowercasePathname.includes('leo');
    if (!pathname.includes(jobId) && !lowercasePathname.includes('leo')) {
      history.push(`/${candidateContext}/${jobId}`);
    } else if (isAllowedPath) {
      history.push(`/${candidateContext}/${jobId}/candidates?status=sourced`);
    }
    setScoutingAgentDrawerVisibility(false);
    updateMinimizeManualSearchStatus(jobId, false);
    updateManualSearchCriteriaType('CreateScoutingAgent');
    pathname.includes(jobId) && !isReactivationAllowed && message.info('Perform search and create scouting agent');
  }

  const openScoutingAgentModal = scoutingAgentId => {
    setScoutingAgentDrawerVisibility(true);
    handleViewEditAgent(scoutingAgentId);
  };

  const fetchDrawerCandidates = () => fetchJobScoutingAgent(jobId);

  const setWarningModal = type => {
    const tempModalData = {};
    if (type === 'Delete') {
      tempModalData.title = 'Delete scouting agent';
      tempModalData.buttonText = 'Delete Agent';
      tempModalData.content = 'What do you want to do with the candidate(s) sourced by';
      tempModalData.context = 'Delete';
    } else {
      tempModalData.title = 'Stop scouting agent';
      tempModalData.buttonText = 'Stop Agent';
      tempModalData.content = 'Are you sure to Stop';
      tempModalData.onClickAction = stopScoutingAgentAction;
      tempModalData.context = 'Stop';
    }
    setModalData(tempModalData);
  };

  const saveAgentData = (id, name, context) => {
    setAgentData({ id, name });
    setWarningModal(context);
  };
  const handleStart = async (id, name) => {
    if (activeScoutingAgentCount >= 5) {
      message.error('More than 5 scouting agents are not allowed');
      return;
    }
    const agentToStart = scoutingAgents.find(agent => agent.Id === id);
    const endDate = moment.utc(agentToStart.EndDate);
    const currentDate = moment.utc();
    if (endDate.isSameOrBefore(currentDate)) {
      message.error('Unable to start the Scouting Agent, Please modify the End Date');
      return;
    }
    await startScoutingAgentAction(id, name);
    await fetchJobScoutingAgent(jobId);
    fetchDrawerCandidates();
  };

  const activeAgentMenu = [
    { title: 'View / Edit Agent Criteria', onClick: handleViewEditAgentCriteria },
    { title: 'View / Edit Agent', onClick: handleViewEditAgent },
    { title: 'Stop', onClick: saveAgentData },
    { title: 'Delete', onClick: saveAgentData },
  ];
  const inActiveAgentMenu = [
    { title: 'View / Edit Agent Criteria', onClick: handleViewEditAgentCriteria },
    { title: 'View / Edit Agent', onClick: handleViewEditAgent },
    { title: 'Start', onClick: handleStart },
    { title: 'Delete', onClick: saveAgentData },
  ];
  function formateDate(date) {
    const format = getLocalDateFormat();
    const endDate = new Date(date);
    let day = endDate.getDate();
    let month = endDate.getMonth() + 1;
    const year = endDate.getFullYear();

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;

    return format.replace('DD', day).replace('MM', month).replace('YYYY', year);
  }

  const columns = [
    {
      title: 'AGENT TITLE',
      dataIndex: 'Name',
      key: 'Name',
      className: styles.header,
      render: text => (
        <p style={{ fontWeight: 500 }}>
          <EllipsedText text={text} maxTextLength={25} />
        </p>
      ),
    },
    {
      title: 'CREATED BY',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
      className: styles.header,
      render: text => (
        <p>
          <EllipsedText text={text} maxTextLength={18} />
        </p>
      ),
    },
    {
      title: 'END DATE',
      dataIndex: 'EndDate',
      key: 'EndDate',
      className: styles.header,
      render: date => <p>{formateDate(date)}</p>,
    },
    {
      title: 'CANDIDATES',
      dataIndex: 'CandidateFetchedCount',
      key: 'CandidateFetchedCount',
      className: styles.header,
      render: (fetchCount, data) => (
        <p>
          {fetchCount}/{data.MaxCandidateCount}
        </p>
      ),
    },
    {
      title: 'STATUS',
      key: 'Status',
      dataIndex: 'status',
      className: styles.header,
      render: (_, data) => {
        let statusText;
        let colorStyle;
        if (data.IsActive) {
          colorStyle = { color: data.DeletionStatus ? '#FA541C' : '#389E0D' };
          statusText = data.DeletionStatus ? 'Deletion in Progress' : 'Active';
        } else {
          colorStyle = { color: '#FA541C' };
          statusText = data.DeletionStatus ? 'Deletion in Progress' : 'Stopped';
        }

        return (
          <p style={colorStyle}>
            <EllipsedText text={statusText} maxTextLength={data.DeletionStatus ? 20 : 10} />
          </p>
        );
      },
    },
    {
      key: 'Options',
      dataIndex: 'IsActive',
      render: (isActive, data) => {
        const menuItems = isActive ? activeAgentMenu : inActiveAgentMenu;
        return (
          <Dropdown overlay={generateMenu(setVisible, data, menuItems)} overlayStyle={{ zIndex: 2004 }}>
            <Icon type="more" style={{ cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  const customLocale = {
    emptyText: (
      <Empty
        image={
          <svg width="100" height="100" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g fillRule="nonzero" stroke="#D9D9D9">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#FAFAFA"
                ></path>
              </g>
            </g>
          </svg>
        }
        imageStyle={{ height: 100 }}
        description={
          <p className={styles.emptyText}>
            {`List of ‘scouting agents’ for this ${
              candidateContext === 'segments' ? 'segment' : 'job'
            } will appear here. You haven’t created any scouting agent.`}
          </p>
        }
      />
    ),
  };
  return (
    <Drawer
      title={
        <div className={styles.titleContainer}>
          <div className={styles.scoutingAgentTitle}>Scouting Agents</div>
          <div className={styles.createScoutingAgentWrapper}>
            <Button shape="round" onClick={onCreateScoutingAgentClick} className={styles.createScoutingAgentButton}>
              Create Scouting Agent
            </Button>
            <Icon
              type="close"
              onClick={() => setScoutingAgentDrawerVisibility(false)}
              style={{ color: '#B4B7BA', cursor: 'pointer' }}
            />
          </div>
        </div>
      }
      zIndex={2003}
      placement="right"
      closable={false}
      onClose={() => setScoutingAgentDrawerVisibility(false)}
      visible={scoutingAgentDrawerStatus}
      drawerStyle={drawerStyle}
      width={900}
    >
      <Spin spinning={jobScoutingAgentJobApiStatus === 'INPROGRESS'}>
        <div className={styles.scoutingAgentTableDisplay}>
          <Table columns={columns} dataSource={scoutingAgentsData} pagination={false} locale={customLocale} />
        </div>
        <DeleteModal
          visible={visible}
          setVisible={setVisible}
          agentData={agentData}
          setScoutingAgentDrawerVisibility={setScoutingAgentDrawerVisibility}
          deleteScoutingAgentAction={deleteScoutingAgentAction}
          modalData={modalData}
          deleteScoutingAgentApiStatus={deleteScoutingAgentApiStatus}
          jobScoutingAgentJobApiStatus={jobScoutingAgentJobApiStatus}
          fetchDrawerCandidates={fetchDrawerCandidates}
          fetchScoutingAgentsByJobIds={fetchScoutingAgentsByJobIds}
          setLastScoutingAgentDeletedId={setLastScoutingAgentDeletedId}
          jobId={jobId}
          candidateContext={candidateContext}
        />
      </Spin>
    </Drawer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoutingAgentDrawer);

export { ScoutingAgentDrawer as ScoutingAgentDrawerWithoutStore };
